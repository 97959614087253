import LoginActionType from "./LoginActionType";


export const setAuthenticatedUser = (e) => {
    return (dispatch) =>
      dispatch({
        type: LoginActionType.SET_AUTHENTICATED_USER,
        data: e,
      });
  };

  export const resetAuthenticatedUser = () => {
    return (dispatch) =>
      dispatch({
        type: LoginActionType.RESET_AUTHENTICATED_USER,
      });
  };
  