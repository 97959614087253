import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import raasiNames from "../KattamConstants";
import FilterListIcon from "@material-ui/icons/FilterList";
import SpecialHouse from "../Raasi/SpecialHouse";
import Tooltip from '@material-ui/core/Tooltip';
import { injectIntl } from 'react-intl';
import { bondSymbol } from "../../common/Planets";


class PaavaVivaram extends Component {

  isSelectedHouse = () => this.props.data.houseNo === this.props.selectedHouseNo;

  state = {
    name: this.props.name,
    planets: ["p1", "p2", "p3"],
    isLagnam: "false",
  };
  styles = {
    name: {
      margin: "4px",
      color: "darkgrey",
    },
    raasi: {
      border: "1px solid green",
      margin: "0.5px",
      width: this.props.width,
      height: this.props.height,
      display: "flex",
      flexDirection: "column",
      fontSize: "small",
      justifyContent: "space-around",
      
    },
    middleRowBelow: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      backgroundColor: "#FFFACD",
    },
    planets: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-end",
      fontSize: "medium",
      margin: "4px",
      flexFlow: "wrap",
      cursor: "pointer",
      // fontWeight: this.isSelectedHouse()?"bold": "",
      flexShrink: 1
    },
  };
  getPaavaVivaram = (data) =>
    data.houseNo;

  getShortName = (name) => name + "_SHORT";

  getHouseNumber = (houseNumber) =>
    houseNumber === 1 && houseNumber !== 0 ? 12 : houseNumber - 1;

  getStyles = (name) => {
    if (
      name === raasiNames.MESAM ||
      name === raasiNames.RISABAM ||
      name === raasiNames.VIRUCHAGAM ||
      name === raasiNames.THULAM
    ) {
    }
  };

  getPaavaVivaramMap = houseNo => {

    const map = {
      "1": {
        "short": "தான், நிறம், கண், தலைமுடி, வயிறு",
        "detail": "தேகத்தின் நிறம், உருவ அமைப்பு, மிகுந்த செல்வம், வயிறு, தலை, அழகிய உடற்பகுதிகள், சிந்தனையின் போக்கு, கவலை, தோற்றக் கவர்ச்சி, புகழ், செல்வாக்கு, சுபசந்தோஷங்கள், ஆயுள் ஆகியவையாகும்., தேக ஆரோக்கியம், ஆத்மா, சுபாவங்கள், கண், நினைவாற்றல், பயம், இந்த ஜென்மம், வசிக்குமிடம் உள்ளூரா வெளியூரா என்று அறிதல், ஆசாரம், தலைமுடி, ஜாதி, சரீர புஷ்டி, கனவு, பெருந்தன்மை, ஜீவனம்"
      },
      "2": {
        short: "தனம்,வாக்கு, குடும்பம், மூக்கு, நாக்கு, பல், நகம், தாடை, கன்னம்",
        detail: "நல்வாழ்வு, செல்வம், குடும்பம், ஒளிபொருந்திய கண், வித்தை (கல்வி), பூமியிலிருந்து கிடைக்கக்கூடிய தனதானியச் சம்பத்துகள், எடுத்துரைக்கின்ற பேச்சுத் திறன், சாஸ்திர கேள்வி ஞானம், உணவு (போஜனம்), துரிதமான நடை, மனம், ஒன்றை அடைய இலக்கு நிர்ணயித்தல் (உத்தேசம்), எல்லோராலும் விரும்பப்படும் பொன், நவரத் தினங்கள்., வலது கண், புதையல், மனைவியின் ஆயுள், விதண்டாவாதம், தன சம்பாத்ய முயற்சிகள், நண்பர்களால் வருமானம், அந்நியர்களால் ஜீவனம், வஸ்திரம் (ஆடை), முகம், நாக்கு, பற்கள், நகம், ஸ்திர புத்தி, யாசகம், கபடம் - சூது, தாடை, கன்னம், ஆரம்பக் கல்வி"
      },
      "3": {
        short: "தைரியம், வீரியம், தம்பி,பொறுமைக் குணம்,கழுத்து, யுத்தம், சங்கீதம், சிறிய பயணம்",
        detail: "காரிய வெற்றி, சிற்றின்ப நுகர்வு (காம போகம்), வேலைக்காரர்கள், அடைக்கலமான பொருள், ஆற்றல் (போக சக்தி), உடன்பிறப்புகள், தீராத காதுநோய், காதில் அணியும் ஆபரணங்கள், சாப்பாட்டுப் பாத்திரம், வீரதீரச் செயல்கள், அடிமைப் பணியில் மேன்மை, துணிச்சல், பொறுமைக் குணம், செயல் திட்டம், பூமி விருத்தி, பூமி லாபம், கழுத்து, பிதுர்மாரகம் (தந்தை மரணம்), கடன், தேக ஆரோக்கியம், யுத்தம், எஜமான னாயிருத்தல், நண்பர்கள், குறுகிய தூரப் பயணம், தொலைபேசி, வானொலி, தொலைக்காட்சி, கடிதப் போக்குவரத்து (அஞ்சல் நிலையம்), பிரசாரம் செய்பவர், பத்திரிகையாளர், அறிவிப்பாளர், தூதுவர், விமானம், பாதையோரம், ஆயுள், சங்கீதம்"
      },
      "4": {
        short: "தாயார், சகோதரம், சொந்தபந்தம், நண்பர்கள், சுகங்கள், வீடு,வர்த்தகம்",
        detail: "வித்தை (சிறப்புக் கல்வித் தகுதி), வாகனம், சுப க்ஷேமங்கள், வர்த்தகம், வீடு, வெற்றியால் உண்டாகும் உயர்நிலை, சுகங்கள், தாயார், விரும்பப்படும் நல்ல உற்றார் உறவினர், புதையல், தாயின் தவறான நடத்தை, வாசனைத் திரவியங்கள், பால், கால்நடைச் செல்வம், தேவாலய கைங்கரியங்கள், சுபகாரியங்கள், நீர் வளம், பாசன வசதிகள், நிலம், மருந்து, புத்தக ஞானம், மந்திரம், தர்க்க விவாதங்கள், சாட்சி, புராண இதிகாச ஈடுபாடு, கனவு, பிதுர் (தந்தை, கவலை, யாத்திரை, இருத, நண்பர்கள், ஆலோசனை, விரதங்கள்,உபவாசங்கள், தேசாதிகாரம் (பெரும் பூமிக்கு உரிமையாளனாயிருத், நீர் வழி லாபம், நடுத்தரக் கல்வி, மூலிை, குகைகள், குளம் முதலிய நீர்நிலைகள்"
      },
      "5": {
        short: "பூர்வ புண்ணியம், பிள்ளைகள், பாட்டன், கருத்தரித்தல் , கலைகள்",
        detail: "பக்தி, தகப்பனின் தகப்பன் (பாட்டன்), பிள்ளைகள், பூர்வ புண்ணியம் (முற்பிறவி நற்பலன்), தமிழ்ப் புலவர்களால் இயற்றப்படும் நூல்களால் பாராட்டப்படும் அளவிற்குப் புகழ் பெறுதல்., கருத் தரித்தல்., மந்திர உபதேசம் பெறுதல்., கலை சார்ந்த அறிவு., கொடைத் தன்மை, புத்திக் கூர்மை, மறைசக்திகளை அறிதல், சக்தி வழிபாடு, தமிழறிவு, யோகாப்பியாசம், உபதேசம், மடம், ஆலயம், புராண உபந்நியாசம், வித்தியா பிரசங்கம், தூர தேசத்தில் ஏற்படும் துன்பம், வேதப் பயிற்சி, நிர்வாக ஆற்றல், சாதுர்யமும் சாமர்த்தியமும், ராஜப் பிரதிநிதிகள், சமய சிந்தனை நால்களின்"
      },
      "6": {
        short: "தாய் மாமன்,ரணம், ரோகம், எதிரிகள்,வெற்றி தோல்விகள்",
        detail: "அம்மான் (தாய் மாமன்), உடற்பிணிகள், விரோதிகள், துன்ப துயரங்கள், ஆயுதங்களால் காயம், தாயாதிகளால் (பங்காளிகளால்) வரும் இன்னல், போர், சரீர உபாதைகள், பொன் போன்ற உயர் பொருள்களைத் தண்டமாகக் கட்டுதல், திருடர்களால் தொந்தரவு, நீரில் உண்டாகும் கண்டம், பெண்களால் ஏற்படும் ஆபத்து, பாம்புகளால் பயம் (விஷ ஜந்துகளால் உண்டாகும் வியாதி), கடன் தொல்லைகள், நியாயத் தீர்ப்பு வழங்குபவர்களின் சட்ட வரம்பிற்கு உட்பட்டுச் சிறைவாசம் மேற்கொள்ளல், கலகம், காரியத் தடங்கல், தன நஷ்டம், மந்த குணம், சந்தேகம், தீயோர் சகவாசம், வைசூரி, குரூர காரியம், போட்டி பந்தயங்களில் வெற்றி தோல்விகள், நாற்கால் ஜீவனால் ஆபத்து, தூஷணத்திற்கு (அவச் சொல்லுக்கு) ஆளாதல்"
      },
      "7": {
        short: "திருமணம்,மனைவியின் ஆயுள், உடலுறவு, தொழில் கூட்டாளிகள்",
        detail: "உடலுறவு, திருமணம், ஆணும் பெண்ணும் அனுபவிக்கின்ற சிற்றின்ப அளவு, வழக்கு, சொந்த பந்தங் களின் சூழுகை, அரசர்களது சிநேகிதம், அரசர்களிடமிருந்து பெறப்படும் சன்மானம், வர்த்தகம், கலியாண நாள், மனைவியின் வாழ்நாள் முடிவு - ஆகிய இவற்றை ஜோதிட சாஸ்திர வல்லுநர்கள் ஏழாமிடத்தைக் கொண்டு கண்டறிந்து கூறுவார்கள்., காமவுணர்வு, மனைவியின் உருவத் தோற்றம், கூட்டாளிகள், தொழில் கூட்டாளிகள், சர்வதேச வாணிபம், மனைவியின் இன்ப துன்பங்கள், திருமண ஸ்தானம், இழந்த பொருளை மீட்டல், ஜாதகனது மாரகம், அந்நிய தேச வாசம், யாத்திரை, விவாதங்கள், பரிசுகள்"
      },
      "8": {
        short: "வாழ்நாள் முடிவு, தீரா வியாதி, ஆயுதங்களால் துன்பம், அவமானம்",
        detail: "ஆயுதங்களால் துன்பம், போர், மலையின் மீதிருந்து விழுதல் (உயரமான இடத்திலிருந்து விழுதல்), தீரா வியாதி, தடை தாமதங்கள், கவலைகள், நீங்காத இன்னல்கள், காயங்கள், புண்கள், விரையச் செலவுகளால் வரும், பொருள் நஷ்டத்தின் விகிதம், மிக்க விரோதம், வாழ்நாள் முடிவு, மாங்கல்ய பாக்கியம் (பெண்ணின் ஜாதகத்தில்), அவமானம், வீண் அலைச்சல், பயம், நஷ்டம், தோல்வி, தீவினைகள் (பாவக் காரியங்கள்), கடன் காரர்களால் தொல்லை, மோட்சம், போஜன பாக்கியம், அஞ்ஞானம், பழி, வாகனங்களால் கண்டங்கள், பல விதமான கண்டங்கள், களத்திர விரோதம், தற்கொலை, தூக்குத் தண்டனை, திடீர் விபத்துகள், அறுவைச் சிகிச்சை, திருடு போதல், தொற்று நோய், சீதனம், வெள்ளம், பஞ்சம், நில நடுக்கம், இன்சூரன்ஸ், உபகாரத் தொகை, கொலைக் களம்"
      },
      "9": {
        short: "யோகம், பாக்கியம், தர்மம், ஈன்ற தகப்பன், அறச்செயல்கள்,ஆன்மிகம்,தகப்பனார் சொத்து",
        detail: "ஈன்ற தகப்பன், சந்ததி விருத்தி, அறச்செயல்கள், மேன்மையுடைய நற்பேறுகள், மடாலயங்கள், உன்னதபுண்ணியங்கள், குளம் வெட்டுதல், இறை கைங்கரியங்கள், ஞான போதனை, தண்ணீர் பந்தல் வைத்தல், பொது நலத்திற்காகக் கிணறு வெட்டுதல், இஷ்டத்திற்குரிய செல்வங்களை அடைதல், கடவுள் நம்பிக்கை, தகப்பனார் சொத்து, வித்தை, சற்குரு (சற்குருவைப் பெறுதல், சற்குருவாய் ஆதல்), நிர்வாகம், தர்ம ஸ்தாபனம், ஒழுக்கம், வேதாத்ய யனம் (மறை ஓதுதல்), விசுவாசம், தீட்சை, தீர்த்த யாத்திரை, தியானம், ஆன்மீக வாழ்க்கை, ஞானத் தேடல், ஆராய்ச்சி, புதியவற்றைக் கண்டறிதல், கடல் மார்க்க,, ஆகாய மார்க்கப் பயணம், ஏற்றுமதி, இறக்குமதி, தேவதை அருள், நீதி நேர்மை நியாயம், சித்த சுத்தி, பட்டாபிஷேகம், கம்பியில்லாத் தந்தி, இறைக்காட்சி தெரிதல்"
      },
      "10": { short: "தொழில், ஜீவனம், கர்மம், மாயமந்திரம்,புகழ், மகனின் மனைவி", detail: "பல்வேறு அறப்பணிகள் செய்தல், அரசாட்சி புரிதல், புதிய நகரங்களை (இடங்களை) உண்டாக்குதல்., ஜீவனர் திற்காகச் செய்யப்படும் தொழில், காருண்ய உணர்வு, மெய்யறிவு பெறுதல், பூஜை புனஸ்காரங்கள், மனம், தந்தையினுடைய பாவங்களைத் தீர்த்தல் (அதாவது, சிரார்த்தம் செய்தல்), இஷ்ட தெய்வ பக்தி, மேலான பதவிகள், உயர் செயல்கள் செய்து புகழ் பெறுதல், யாசிக்கும் நிலை, காயகல்பம் முதலியவற்றை உண்ணும் வாய்ப்பு, உடுத்தும் வஸ்திரம், உலகியால் நடாவதிக்காய், ஆகயம், அராசங்க அனுகூலம், சொத்து சேர்த்தல், குதிரசவாரி, மல்யுத்தம், மந்திர உச்சாடனம், பாண்டங்களில் இருந்து விடுதலை பெறுதல், சொந்த நாட்டு வணிகம், வேளாண்மை, தியாகம், செவாய், மந்திரா கவாசம்" },
      "11": { short: "லாபம், ஆகாயம், மூத்த சகோதரன்,வாகன வசதி,பலதார மணம் ", detail: "மூத்த சகோதர சகோதரிகள், ஜாதகனைப் போற்றி வணங்குபவர்கள் (சேவை செய்வோர்), இளைய தாரம், பொன் பொருளைச் சேர்ப்பதற்கான கலையறிவு, லாப வரவு, வேளாண்மை, குதிரை, யானை, சிறந்த உத்தமமான அறிவு, பல்லக்கு ஏறுதல், மனக் கவலை தீரும் வழிகள், மேலாடை வகைகள்., வாகன வசதி, கௌரவம், செய் தொழிலின் மேன்மை, பலதார மணம், பூமி விருத்தி, சங்கீதத் தொழில், நாடகத் தொழில், அயல்நாட்டுப் பயணங் களால் மேன்மை, மின்சாரம், நண்பரின் வீடு, சபை, சமூகத்தில் தனக்கு ஆதரவாயிருப்பவர்கள், இடக் காது, வலப் பாதம், இடக் கை, இரு கால்கள், அமைச்சர் பொறுப்பு, பொன் பொருள் சேர்க்கை, கிணறு" },
      "12": { short: "வெளிநாட்டில் பணி, அயனம், சயனம், விரயம்,பிறருக்காக வாழ்தல், இடது கண், அலைச்சல்", detail: "அறநெறிக்குப் புறம்பான விஷயங்களைச் செய்தல், வெளிநாட்டில் பணி, பணச் செலவு, சிற்றின்ப (உடலுறவுச்) சுகம், படுக்கை வசதிகளால் பெறப்படும் சுகம், வழக்காடுதல், தர்க்கம் செய்தல் ஆகியவற்றைக் கொண்ட தொழில் செய்தல், மறுபிறப்பு, கொடை, நற்செயல்கள், பிறருக்காக வாழ்தல், அருள் தரும் வேள்விகள் செய்தல், உலக வாழ்க்கைச் சார்பான கசப்புணர்ச்சி, நஷ்டங்கள், வாய்ச் சண்டை, மோட்சம், மலை நாடுகளில் பிரயாணம், அரசாங்கத் தண்டனை, மோசடி, நன்கொடைகள், குடும்பத்தை விட்டுப் பிரிந்திருத்தல், பாதம், இடக் கண், துப்பறிதல் (ஒற்றறிதல்), இரகசியத் திட்டங்கள், வஞ்சனையால் கொலை புரிதல், இராஜ துரோகம், சிறைப்படுதல், கடத்தல், மிருகங் களால் இன்னல், வறுமை, பூமிக்குக் கீழ் ஆராய்தல், அலைச்சல்" },


    }
    return map[houseNo] ? map[houseNo] : "";
  }

  getSpecialHouseText = () => {
    let info = [];
    let data = this.props.data;
    if (!data.specials) {
      return;
    }
    if (data.specials.includes(SpecialHouse.MAARAKAM)) {
      info.push("M");
    }
    if (data.specials.includes(SpecialHouse.PAATHAKAM)) {
      info.push("P");
    }
    if (data.specials.includes(SpecialHouse.KENDIRAM)) {
      info.push("K");
    }
    if (data.specials.includes(SpecialHouse.TRIKONAM)) {
      info.push("T");
    }
    return info.join("/");
  };

  

  getTooltip = p => `${this.props.intl.formatMessage({ id: p.planet })}/${this.props.intl.formatMessage({ id: p.natchathiram })}-${p.paadham} ${this.props.intl.formatMessage({ id: p.natchathiraAthipathi })}`
  // setDescription = houseNo => this.props.setDescription(houseNo);

  isLagnam = (raasi) =>
    raasi.specials && raasi.specials.includes(SpecialHouse.LAGNAM);

  render() {
    if (!this.props.data) {
      return;
    }
    let name =
      this.props.data && this.props.data.raasi ? this.props.data.raasi : "NA";
    let planetsBond = this.getPaavaVivaram(this.props.data);
    let lagnam = this.isLagnam(this.props.data);
    let specialHouseText = this.getSpecialHouseText();
    let data = this.getPaavaVivaramMap(this.props.data.houseNo);
    
    return (
      <div name={name} style={this.styles.raasi}>

        {/* {isLagnam && <Lagnam size={this.styles.raasi.width} />} */}
        {lagnam && (
          <FilterListIcon
            style={{ transform: "rotate(135deg)", margin: "-8px" }}
          />
        )}
        <span style={this.styles.name}>
          <span>{this.props.data.houseNo} </span>
          <FormattedMessage id={name} />
        </span>

        {data && this.isSelectedHouse() && <div style={{...this.styles.planets, ...{"fontWeight": "bold"}}} onClick={() => this.props.onClick(data, this.props.data.houseNo)}>
          {data.short}
        </div>}

        {data && !this.isSelectedHouse() && <div style={{...this.styles.planets, ...{"fontWeight": "normal"}}} onClick={() => this.props.onClick(data, this.props.data.houseNo)}>
          {data.short}
        </div>}

      </div>
    );
  }
}

export default injectIntl(PaavaVivaram);