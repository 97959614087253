import React from "react";
import PropTypes from "prop-types";
import RaasiKattam from "./RaasiKattam";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as raasiAction from "../KattamAction";
import * as _ from "lodash";

import { withRouter } from "react-router-dom";

export class RaasiKattamContainer extends React.Component {
  propTypes = {
    data: PropTypes.data,
  };

  render() {
    if (!this.props.user || (!this.props.raasiBand && this.props.raasiBand.length < 1)) {
      return <div></div>
    }
    return (
      <RaasiKattam
        data={this.props.data}
        profile={this.props.user}
        raasiBand={this.props.raasiBand}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: _.get(state.raasi, "sample.data", {}),
    user: _.get(state.raasi, "currentUser"),
    raasiBand: _.get(state.raasi, "sample.data.raasiBand", {}),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(raasiAction, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RaasiKattamContainer));
