import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton, Typography } from "@material-ui/core";
import _ from 'lodash';
import * as LocalStore from '../../LocalStoreService';
import KotcharamPredictionContainer from "./KotcharamPredictionContainer";



class PredictionContainer extends Component {//Never Use this: in favor of KotcharamPredictionContainer

  getUser = () => {
    return this.props.user.name;
  };

  isAdmin = () => {
    let user = LocalStore.getGoogleUser();
    return user.role === "ADMIN";
  }

  goBack = () => this.props.history.goBack();
  
  render() {
    if (!this.props.predictions || this.props.predictions.length < 1) {
      return <div></div>;
    }
    return (
      <div style={{backgroundColor:"#f4f4f4"}}>

        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          about="Go Back"
          onClick={this.goBack}
        >
          <ArrowBackIcon/>
        </IconButton>
        <h3 style={{ display: "flex", justifyContent: "center" ,marginTop:"-15px"}}>{this.getUser()}</h3>
        {/* <LifetimePredictionContainer /> */}
        <div style={{backgroundColor:"#f4f4f4"}}><KotcharamPredictionContainer /></div>
        

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    predictions: _.get(state.raasi, "predictions", []),
    predictionspp: _.get(state.raasi, "predictionsOfPP", []),
    kotcharamPredictionsOfPP: _.get(state.raasi, "kotcharamPredictionsOfPP", []),
    user: _.get(state.raasi, "currentUser", {})
  };
};

//   const mapDispatchToProps = (dispatch) => {
//     return {
//       actions: bindActionCreators(raasiAction, dispatch),
//     };
//   };

export default connect(mapStateToProps, null)(withRouter(PredictionContainer));
