import ActionType from "./OAuthActionType";

export const loginWithGoogle = (codes, oidcParams) => {
  return (dispatch) =>
    dispatch({
      type: ActionType.LOGIN_WITH_GOOGLE,
      data: { "codes": codes, "oidcParams": oidcParams }
    });
};
export const getToken = (params, oidcParams) => {
  return (dispatch) =>
    dispatch({
      type: ActionType.TOKEN_FOR_GOOGLE,
      data: { "params": params, "oidcParams": oidcParams }
    });
};
