import { Accordion, AccordionDetails, TextField, AccordionSummary, Card, CardContent, Typography, Button, IconButton, withStyles, makeStyles, Tooltip, ClickAwayListener } from '@material-ui/core';
import React, { Component } from 'react';
import * as _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import Chip from '@material-ui/core/Chip';
import { planetColors, planetForeColors, kaaragans } from '../common/Planets'
import moment from 'moment';
import { formatDate, formatDateSimple, getBond, getBondToLagnam, getBondFromLagnam, getAge, getColor, getForeColor, getPlanetShortName } from '../DasaBhukthi/DasaUtil';
import OraiClock from './OraiClock';
import { get, chunk } from 'lodash';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DatePicker from '../common/DatePicker';
import { FastForward, FastRewind, Info, NavigateBefore, NavigateNext, SkipNext, SkipPrevious } from '@material-ui/icons';
import * as LocalStore from '../../LocalStoreService';
import { doBToMoment, formatDatePickerToDate, formatToKotcharam } from '../common/DateUtil';
import { Autocomplete } from '@material-ui/lab';

class JaadhagamInfo extends Component {

    state = { tip1: false, tip2: false, tip3: false, tip4: false };
    componentDidMount() {
        this.setState({ tip1: false, tip2: false, tip3: false, tip4: false });
    }

    getRaasi = () => {
        if (this.props.raasiBand) {
            let raasi = this.props.raasiBand.filter(b => b.planets && b.planets.length > 0)
                .filter(p => p.planets.find(f => f.planet === "CHANDRAN")).filter(f => f !== undefined);

            let lagna = this.props.raasiBand.filter(b => b.houseNo === 1);

            let jenmaRaasi = this.props.raasiBand.filter(b => b.planets && b.planets.length > 0)
                .filter(p => p.planets.find(f => f.planet === "CHANDRAN"))
                .map(p => p.planets).shift()
                .filter(f => f.planet === 'CHANDRAN')[0];

			let bhathakathipathy = this.props.raasiBand.filter(b => b.specials && b.specials.length > 0)
                .filter(p => p.specials.find(f => f === "PAATHAKAM"))
				.map(f => f.raasiDetail.athipathi)[0]
				;
			console.log("bhathakathipathy :", bhathakathipathy);
			let marakathipathy = this.props.raasiBand.filter(b => b.specials && b.specials.length > 0)
                .filter(p => p.specials.find(f => f === "MAARAKAM"))
				.map(f => f.raasiDetail.athipathi)[0]
				;

            let result = {
                raasiName: raasi[0].raasi,
                star: jenmaRaasi.natchathiram,
                paadham: jenmaRaasi.paadham,
                lagnam: lagna[0].raasi,
                lagnaAthipathi: lagna[0].raasiDetail.athipathi,
				bhathakathipathy: bhathakathipathy,
				marakathipathy: marakathipathy
            };
            return result;
        }
    }


    getHeader = t => <div style={{ fontWeight: 'bold', marginRight: '5px' }}>{t}</div>;
    getTooltip = p => {
        let kaaragan = kaaragans[p.planet];
        return kaaragan ? <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}><div style={{ color: "red" }}>{this.getHeader(`வேதகன்: `)}</div>{this.props.intl.formatMessage({ id: kaaragan.vedhagan })}</div>
            <div style={{ display: 'flex' }}><div style={{ color: "#c908c2" }}>{this.getHeader(`பாசகன்: `)}</div><div>{this.props.intl.formatMessage({ id: kaaragan.paasagan })}</div></div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}><div style={{ color: "#5329bb" }}>{this.getHeader(`காரகன்: `)}</div><div>{this.props.intl.formatMessage({ id: kaaragan.podhagan })}</div></div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}><div style={{ color: "#048191" }}>{this.getHeader(`போதகன்: `)}</div><div>{this.props.intl.formatMessage({ id: kaaragan.kaaragan })}</div></div>
        </div> : "n/a";
    }

    openTooltip = (p) => {
        let o = {};
        o['tip' + p] = !this.state['tip' + p];
        this.setState(o);
    }
    handleTooltipClose = (p) => {
        let o = {};
        o['tip' + p] = false;
        this.setState(o);
    }

    formatDt = dt => {
        // console.log("dt :", dt); // 2022-01-27 05:30 AM
        try {
            let formatted = moment(dt, "YYYY-MM-DD hh:mm a").format("dddd, MMM Do YYYY");
            // console.log("formatted :", formatted);
            return formatted === "Invalid date" ? this.props.data.dobStr : formatted;
        }
        catch (err) {
            return get(this.props, "data.dobStr", moment().format("dddd, MMM Do YYYY"));
        }
    }


    getDasa = (d, i = 1) => {
        return <div style={{ display: "flex", fontSize: 'small', flexDirection: "row", flexWrap: "nowrap", alignItems: "center", width: "100%", marginLeft: '5px' }}>
            <div style={{ fontSize: 'x-small', textAlign: "left", minWidth: '40px' }}>{getAge(this.props.jaadhagam.dob, formatDate(d.startDate))}-{getAge(this.props.jaadhagam.dob, formatDate(d.endDate))}</div>
            <div style={{ fontSize: 'small', minWidth: '72px' }}>{formatDateSimple(d.startDate)}</div>
            <div>
                <InputWrapper>
                    <HtmlTooltip title={this.getTooltip(d)} PopperProps={{
                        disablePortal: true,
                        placement: "top",
                        color: "blue"
                    }}>
                        <span>{getBondFromLagnam(d)}</span>
                    </HtmlTooltip>
                    <HtmlTooltip title={this.getTooltip(d)} PopperProps={{
                        disablePortal: true,
                        placement: "top",
                        color: "blue"
                    }}
                        open={this.state['tip' + i]}>

                        <Chip label={<div><FormattedMessage id={getPlanetShortName(d.planet)} /><span>{getBond(d)}</span></div>}
                            onClick={() => this.openTooltip(i)}
                            style={{ margin: '4px', width: '50px', backgroundColor: getColor(d.planet), color: getForeColor(d.planet), border: '1px solid grey' }} />
                    </HtmlTooltip>
                    <HtmlTooltip title={this.getTooltip(d)} PopperProps={{
                        disablePortal: true,
                        placement: "top",
                        color: "blue"
                    }}>
                        <span>{getBondToLagnam(d)}</span>
                    </HtmlTooltip>
                </InputWrapper>
            </div>
            <div style={{ fontSize: 'small', fontWeight: "bold", minWidth: '50px', marginLeft: '2px' }}>
                <FormattedMessage id={d.dasaType} />
            </div>
            <div style={{ fontSize: 'small', minWidth: '72px' }}>{formatDateSimple(d.endDate)}</div>
        </div>
    }


    handleDateChange = (dt) => {
        let reqDate = formatDatePickerToDate(dt);
        this.props.actions.retrieveKotcharam(reqDate);
        this.props.actions.retrieveOrai(reqDate);
        if (this.props.profile) this.props.actions.getDasaByDate(this.props.profile.id, reqDate);
    }

    handlePreviousDay = () => {
        let date = doBToMoment(this.props.kotcharam.dob).subtract(1, "days");
        let reqDate = formatToKotcharam(date);
        console.log("handlePreviousDay : this.props.kotcharam.dob ;" + this.props.kotcharam.dob + " Formatted :" + reqDate);
        this.props.actions.retrieveKotcharam(reqDate);
        this.props.actions.retrieveOrai(reqDate);
        if (this.props.profile) this.props.actions.getDasaByDate(this.props.profile.id, reqDate);
    }

    handleNextDay = () => {
        let date = doBToMoment(this.props.kotcharam.dob).add(1, "days")
        let reqDate = formatToKotcharam(date);
        this.props.actions.retrieveKotcharam(reqDate);
        this.props.actions.retrieveOrai(reqDate);
        if (this.props.profile) this.props.actions.getDasaByDate(this.props.profile.id, reqDate);

    }

    handleNextMonth = () => {
        let date = doBToMoment(this.props.kotcharam.dob).add(1, "months")
        let reqDate = formatToKotcharam(date);
        this.props.actions.retrieveKotcharam(reqDate);
        this.props.actions.retrieveOrai(reqDate);
        if (this.props.profile) this.props.actions.getDasaByDate(this.props.profile.id, reqDate);
    }
    handlePreviousMonth = () => {
        let date = doBToMoment(this.props.kotcharam.dob).subtract(1, "months")
        let reqDate = formatToKotcharam(date);
        this.props.actions.retrieveKotcharam(reqDate);
        this.props.actions.retrieveOrai(reqDate);
        if (this.props.profile) this.props.actions.getDasaByDate(this.props.profile.id, reqDate);
    }

    handleNextYear = () => {
        let date = doBToMoment(this.props.kotcharam.dob).add(1, "years")
        let reqDate = formatToKotcharam(date);
        this.props.actions.retrieveKotcharam(reqDate);
        this.props.actions.retrieveOrai(reqDate);
        if (this.props.profile) this.props.actions.getDasaByDate(this.props.profile.id, reqDate);
    }
    handlePreviousYear = () => {
        let date = doBToMoment(this.props.kotcharam.dob).subtract(1, "years")
        let reqDate = formatToKotcharam(date);
        this.props.actions.retrieveKotcharam(reqDate);
        this.props.actions.retrieveOrai(reqDate);
        if (this.props.profile) this.props.actions.getDasaByDate(this.props.profile.id, reqDate);
    }

    handleSummaryClick = e => e.preventDefault();

    isAdmin = () => {
        let user = LocalStore.getGoogleUser();
        return user.role === "ADMIN";
    }

    getHeader = t => <div style={{ fontWeight: 'bold', marginRight: '5px' }}>{t}</div>;
    getHelp = () => {
        return <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', margin: "5px" }}><div style={{ color: "red" }}>{this.getHeader(`சூரியன்: `)}</div><div>பத்திரம்,உயில் எழுதலாம், ஒருவருக்கு பரிந்துரை செய்யலாம். பயணம்,புதுமனை விழா தவிர்க்கவும்.</div></div>
            <div style={{ display: 'flex', flexWrap: 'wrap', margin: "5px" }}><div style={{ color: "#c908c2" }}>{this.getHeader(`சுக்கிரன்: `)}</div><div> நல்ல காரியங்கள் செய்ய, வாகனம் வாங்க, தொழில் தொடங்க, திருமண வேலைகள், விருந்து உபசரிப்பு</div></div>
            <div style={{ display: 'flex', flexWrap: 'wrap' , margin: "5px"}}><div style={{ color: "#048191" }}>{this.getHeader(`புதன்: `)}</div><div>புது விசயத்தை தொடங்கலாம், வீடு/மனை, வழக்கு விசயம், கதை, கவிதை, கட்டுரை தொடங்கலாம்.</div></div>
            <div style={{ display: 'flex', flexWrap: 'wrap' , margin: "5px"}}><div style={{ color: "#048191" }}>{this.getHeader(`சந்திரன்: `)}</div><div>வளர்பிறையில் சுப காரியங்கள் செய்ய, வெளியில் செல்ல, வியாபாரம் தொடங்க, ஆலய தரிசனம்</div></div>
            <div style={{ display: 'flex', flexWrap: 'wrap', margin: "5px" }}><div style={{ color: "red" }}>{this.getHeader(`சனி: `)}</div><div> பயணம் தவிர்க்கவும், வீடு/மனை வாங்கலாம்/விற்கலாம். இரும்பு சம்பந்த பொருள் நல்ல பலன்தரும். சுப ஓரையில் மரணயோகம், அட்டமி,நவமி, பார்க்க தேவையில்லை</div></div>
            <div style={{ display: 'flex', flexWrap: 'wrap', margin: "5px" }}><div style={{ color: "#5329bb" }}>{this.getHeader(`குரு: `)}</div><div> எடுத்ததெல்லாம் வெற்றி, பொன்-பொருள் வாங்க,தொழில் தொடங்க,சுப முகூர்த்தம் யோகம்</div></div>
            <div style={{ display: 'flex', flexWrap: 'wrap' , margin: "5px"}}><div style={{ color: "red" }}>{this.getHeader(`செவ்வாய்: `)}</div><div>சுப காரியங்களை தவிர்க்கவும், வாக்கு வாதத்தில் ஈடு பட வேண்டாம், உடல் ஆரோக்கியம் சம்பந்த விஷயம் செய்யலாம்.</div></div>
        </div>;
	}
	handleTimezoneChanged = e => this.props.actions.setTimeZone(e.target.innerText);
	handleTimezoneClicked = e => e.stopPropagation();

	getDropDownLabel = (params) => params;

	zones = ["Asia/Kolkata", "America/New_York", "America/Chicago", "America/Denver", "America/Los_Angeles", "America/Phoenix", "America/Halifax", "America/Anchorage", "Asia/Riyadh", "Asia/Dubai", "Asia/Singapore", "Asia/Kuala_Lumpur", "Asia/Bangkok", "Europe/Paris", "Europe/Berlin", "Europe/Amsterdam", "Europe/London"];  //moment.tz.names();


	render() {
        if (!this.props.raasiBand || this.props.raasiBand.length < 1 || !this.props.jaadhagam) {
            return <div></div>
        }
        let r = this.getRaasi();
        let chunks = chunk(this.props.generalOrai, 12);
        return (
            <div style={{ display: "flex", flexWrap: "wrap", flexDirection: 'column' }}>
                <div style={{ display: "flex", flexWrap: "wrap", margin: "10px" }}>
                    <Typography variant="p" component="p"><FormattedMessage id={"RAASI"} />:</Typography><Typography variant="p" component="p" style={{ marginLeft: "5px", fontWeight: "bold" }}><FormattedMessage id={_.get(r, "raasiName", "NA")} /></Typography>
                    <Typography variant="p" component="p" style={{ marginLeft: "10px" }}><FormattedMessage id={"NATCHATHIRAM"} />:</Typography><Typography variant="p" component="p" style={{ marginLeft: "5px", fontWeight: "bold" }}><FormattedMessage id={_.get(r, "star", "NA")} /></Typography>
                    <Typography variant="p" component="p" style={{ marginLeft: "10px" }}><FormattedMessage id={"PAADHAM"} />:</Typography><Typography variant="p" component="p" style={{ marginLeft: "5px", fontWeight: "bold" }}>{_.get(r, "paadham", "")}</Typography>
                    <Typography variant="p" component="p" style={{ marginLeft: "10px" }}><FormattedMessage id={"LAGNAM"} />:</Typography><Typography variant="p" component="p" style={{ marginLeft: "5px", fontWeight: "bold" }}><FormattedMessage id={_.get(r, "lagnam", "NA")} /></Typography>
                    <Typography variant="p" component="p" style={{ marginLeft: "10px" }}><FormattedMessage id={"LAGNAM_ATHIPATH"} />:</Typography><Chip label={<div><FormattedMessage id={getPlanetShortName(r.lagnaAthipathi)} /></div>}
                            style={{ marginTop: '-5px', width: '50px', backgroundColor: getColor(r.lagnaAthipathi), color: getForeColor(r.lagnaAthipathi), border: '1px solid grey' }} />
					<Typography variant="p" component="p" style={{ marginLeft: "10px" }}><FormattedMessage id={"BHATHAKATHIPATHY"} />:</Typography><Chip label={<div><FormattedMessage id={getPlanetShortName(r.bhathakathipathy)} /></div>}
                            style={{ marginTop: '-5px', width: '50px', backgroundColor: getColor(r.bhathakathipathy), color: getForeColor(r.bhathakathipathy), border: '1px solid grey' }} />
					<Typography variant="p" component="p" style={{ marginLeft: "10px" }}><FormattedMessage id={"MARAKATHIPATHY"} />:</Typography><Chip label={<div><FormattedMessage id={getPlanetShortName(r.marakathipathy)} /></div>}
                            style={{ marginTop: '-5px', width: '50px', backgroundColor: getColor(r.marakathipathy), color: getForeColor(r.marakathipathy), border: '1px solid grey' }} />
                </div>

                {!_.isEmpty(this.props.dasa) && <div style={{ display: "flex", flexWrap: "wrap", margin: "10px" }}>
                    <DasaWrapper>
                        {this.getDasa(this.props.dasa, 1)}
                    </DasaWrapper>
                    <DasaWrapper>
                        {this.getDasa(this.props.dasa.putthis[0], 2)}
                    </DasaWrapper>
                    <DasaWrapper>
                        {this.getDasa(this.props.dasa.putthis[0].andharam[0], 3)}
                    </DasaWrapper>
                    <DasaWrapper>
                        {this.getDasa(this.props.dasa.putthis[0].andharam[0].sootchamam[0], 4)}
                    </DasaWrapper>
                </div>}
                {<div style={{ display: "flex", flexWrap: "wrap", margin: "10px" }}>
                    <Accordion style={{ backgroundColor: '#8ad6f32b' }} onClick={this.handleTimezoneClicked}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}><div style={{ fontSize: "medium", alignContent: "center", display: "flex", marginTop: '5px' }}>ஓரை கணிதம் </div>
                            <InfoTooltip title={this.getHelp()} PopperProps={{
                                disablePortal: true,
                                placement: "top",
                                color: "blue"
                            }}>
                                <Info fontSize="small" style={{ color: "teal", flex: 'none' }} />
							</InfoTooltip>

							<div style={{ fontSize: "small", marginLeft: '10px', alignContent: "flex-end", display: "flex", flexDirection: "column", justifyContent: "start" }} onClick={this.handleTimezoneClicked}>
								<Autocomplete
									options={this.zones}
									onChange={this.handleTimezoneChanged}
									size={"medium"}
									clearOnEscape
									getOptionLabel={(option) => option}
									style={{ width: 300, margin: '5px' }}
									renderInput={(params) => <TextField {...params} label="Select Timezone" variant="filled" />}
								/>
								</div>
                            <div style={{ fontSize: "medium", marginLeft: '10px', alignContent: "flex-end", display: "flex", flexDirection: "column", justifyContent: "center" }} onClick={this.handleSummaryClick}>
                                <InputWrapper>
                                    <IconButton onClick={this.handlePreviousYear} style={{ marginLeft: '-2px', marginTop: '-10px' }}>
                                        <SkipPrevious fontSize="small" />
                                    </IconButton>
                                    <IconButton onClick={this.handlePreviousMonth} style={{ marginLeft: '-2px', marginTop: '-10px' }}>
                                        <FastRewind fontSize="small" />
                                    </IconButton>
                                    <IconButton onClick={this.handlePreviousDay} style={{ marginLeft: '-2px', marginTop: '-10px' }}>
                                        <NavigateBefore />
                                    </IconButton>
                                    <DatePicker onChange={this.handleDateChange} />
                                    <IconButton onClick={this.handleNextDay} style={{ marginLeft: '-2px', marginTop: '-10px' }}>
                                        <NavigateNext />
                                    </IconButton>
                                    <IconButton onClick={this.handleNextMonth} style={{ marginLeft: '-2px', marginTop: '-10px' }}>
                                        <FastForward fontSize="small" />
                                    </IconButton>
                                    <IconButton onClick={this.handleNextYear} >
                                        <SkipNext fontSize="small" style={{ marginLeft: '-2px', marginTop: '-10px' }} />
                                    </IconButton>
                                </InputWrapper></div>
                            <div style={{ marginLeft: '10px', fontSize: 'small', color: 'grey' }}></div></AccordionSummary>
                        <AccordionDetails>
                            <div>
							<div style={{ display: 'flex', flexWrap: 'wrap'}}>
                                <div style={{ border: '1px solid #d5cdcd', backgroundColor: 'white' }}>
                                    <div style={{ border: '1px solid #d5cdcd', color: 'black', backgroundColor: '#c9e4ff', margin: '5px', padding: '5px' }}>காலை 6 - மாலை 6 வரை</div>
                                    <OraiClock oraiChunk={0}></OraiClock>
                                </div>
                                <div style={{ border: '1px solid #d5cdcd', backgroundColor: 'white' }}>
                                    <div style={{ border: '1px solid #d5cdcd', color: '#f3f3ed', backgroundColor: '#710cbd', margin: '5px', padding: '5px' }}>மாலை 6 - மறுநாள் காலை 6 வரை </div>
                                    <OraiClock oraiChunk={1}></OraiClock></div>
                            </div>
							<div style={{flexDirection: "row", flexGrow: 1}}>{this.getHelp()}</div>
							</div>
                        </AccordionDetails>
                    </Accordion>
                </div>}

            </div>
        );
    }
}


const stopPropagation = (e) => e.stopPropagation();
const InputWrapper = ({ children }) =>
    <div onClick={stopPropagation}>
        {children}
    </div>


const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: 11,
    },
}));

export function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
}


export const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#ffffff',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: 12,
        border: '1px solid #dadde9',
    },
}))(Tooltip);

export const InfoTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#ffffff',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 330,
        fontSize: 15,
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const DasaWrapper = (props) => <div style={{ marginRight: '10px', border: '1px solid #a9a6a6', display: 'flex', minWidth: '300px' }}>
    {props.children}
</div>

export default injectIntl(JaadhagamInfo);
