import { Button, FormControl, Input, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Planets from "../common/Planets";

class Search extends Component {
  state = {
    planets: [],
    planetCombination: "AND",
    lordHouseNo: "",
    splHouse:null,
    house:""
  };

  handlePlanetsChange = (event) => this.setState({ planets: [...event.target.value] });
  handleHouseChange = (e) => this.setState({ house: e.target.value });
  handlePlanetCombination = (e) => this.setState({ planetCombination: e.target.value });
  handleSplHouseChange = (e) => this.setState({ splHouse: e.target.value });
  reset = () => this.setState({ planets: [], house: "", splHouse: null });
  search = () => {this.props.actions.searchByPlanetsOnHouse(this.createRequest());this.props.kattamActions.resetCurrentUser();}

  createRequest = () => {
    let req = {
      planets: this.state.planets,
      planetCombination: this.state.planetCombination,
      lordHouseNo: this.state.lordHouseNo,
      splHouse: this.state.splHouse,
      house: this.state.house,
    };
    return req;
  };

  render() {
    let { classes } = this.props;
    let planets = Object.values(Planets);
    return (
      <div style={{ padding:'10px',  margin: "10px" ,display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", flexWrap:'wrap', border: "1px solid black" }}>
        <div style={{ padding: "10px" ,display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", flexWrap:'wrap' }}>
          <FormControl className={classes.container}>
            <InputLabel id="planets-simple-select-label">Planets</InputLabel>
            <Select labelId="planets-simple-select-label" id="planets-select" multiple onChange={this.handlePlanetsChange} value={this.state.planets} input={<Input />}>
              {planets.map((p) => (
                <MenuItem value={p}>{p}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{width: "150px"}}>
            <InputLabel id="planet-comb-simple-select-label">Planet Combination</InputLabel>
            <Select labelId="planet-comb-simple-select-label" id="planet-comb-select" onChange={this.handlePlanetCombination} value={this.state.planetCombination} input={<Input />}>
              {["AND", "OR"].map((p) => (
                <MenuItem value={p}>{p}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{width: "160px"}} >
            <InputLabel id="on-house-simple-select-label">On Houses</InputLabel>
            <Select labelId="on-house-simple-select-label" id="on-house-select" onChange={this.handleHouseChange} value={this.state.house} input={<Input />}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((p) => (
                <MenuItem value={p}>{p}</MenuItem>
              ))}
            </Select>
            <FormControl>
              <InputLabel id="demo-spl-house-label">On Spl.Houses</InputLabel>
              <Select labelId="demo-spl-house-label" id="demo-spl-select" onChange={this.handleSplHouseChange} value={this.state.splHouse} input={<Input />}>
                {["LAGNAM", "KENDIRAM", "TRIKONAM", "MAARAKAM", "PAATHAKAM"].map((p) => (
                  <MenuItem value={p}>{p}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormControl>
          <Button color="secondary" style={{ marginLeft: "20px", marginTop: "10px", width: "100px" }} variant="outlined" onClick={this.search}>
            Search
          </Button>
          <Button color="secondary" style={{ marginLeft: "5px", marginTop: "10px", width: "100px" }} variant="outlined" onClick={this.reset}>
            RESET
          </Button>
        </div>
      </div>
    );
  }
}
const styles = () => ({
  root: {
    backgroundColor: "red",
  },
  container: {
    margin: "10px",
    width: "auto",
    minWidth: "300px",
  },
});
export default withStyles(styles, { withTheme: true })(Search);
