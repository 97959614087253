export const set = (key, val) => {
  JSON.stringify(window.localStorage.setItem(key, JSON.stringify(val)));
};

export const get = (key) =>
  window.localStorage.getItem(key)
    ? JSON.parse(window.localStorage.getItem(key))
    : {};

export const setUser = (user) => set("user", user);
export const getUser = () => get("user");

export const setFbUser = (user) => set("fbUser", user);
export const getFbUser = () => get("fbUser");

export const setGoogleUser = (user) => set("googleUser", user);
export const getGoogleUser = () => get("googleUser");


export const deleteAll = () => window.localStorage.clear();

export const isSubuser = () => {
  let user = getUser();
  return (
    user &&
    user.roles &&
    user.roles.length &&
    user.roles[0].role === "SITE_USER"
  );
};
export const isSuperAdmin = () => {
  let user = getUser();
  return (
    user &&
    user.roles &&
    user.roles.length &&
    user.roles[0].role === "SUPER_USER"
  );
};
