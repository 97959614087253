import React, { Component } from "react";
import AddUser from "./AddUser";
import * as addUserAction from "./UserActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import { withRouter } from "react-router";
import * as _ from "lodash";

class UserContainer extends Component {
  goBack = () => this.props.history.goBack();

  getOAuthUser = () => this.props.user? this.props.user : localStorage.getItem('googleUser');
  render() {
    return (
      <React.Fragment>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
            about="Go Back"
            onClick={this.goBack}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div style={{ margin: "10px" }}>Add New User</div>
        <div>
          <AddUser actions={this.props.actions}
            user={this.getOAuthUser}
            history={this.props.history}
            message={this.props.message}
            addJaadhagamLabel={this.props.addJaadhagamLabel}
            />
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    // recentlyAddedJaadhagam: _.get(state.user, "recentlyAddedJaadhagam"),
    message: _.get(state.user, "recentlyAddedJaadhagamMessage"),
    user: _.get(state, "oauth.google"),
    addJaadhagamLabel:  _.get(state.user, "addJaadhagamLabel", "Add User"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(addUserAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddUser));
