import { post } from "../../httpService";

const getSocialUserReq = data => {
    if (data && data._provider == 'facebook') {
        return ({
            provider: data._provider,
            firstName: data._profile.firstName,
            lastName: data._profile.lastName,
            emailId: data._profile.email,
            socialId: data._profile.id,
            socialName: data._profile.name,
            photoUrl: data._profile.profilePicURL,
            accessToken: data._token.accessToken,
            expiresAt: data._token.expiresAt
        });
    }
}
export const setAuthenticatedUser = (data) => {
    let req = getSocialUserReq(data);
    if (req) {
        return post('/social/login', req);
    }
}
