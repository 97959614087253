import { TextField } from '@material-ui/core';
import React from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import moment from "moment";

export default class LocationMapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = { address: '' };
        this.state = {
            googleMapsReady: false,
        };
    }

    componentDidMount() {
        this.loadGoogleMaps(() => {
            this.setState({ googleMapsReady: true });
        });
    }

    componentWillUnmount() {
        this.unloadGoogleMaps();
    }

    loadGoogleMaps = callback => {
        const existingScript = document.getElementById("googlePlacesScript");
        if (!existingScript) {
            const script = document.createElement("script");
            script.src =
                "https://maps.googleapis.com/maps/api/js?key=AIzaSyCYOkCu5PvaSVzT_4xoRbZuB5WO78ke0WY&libraries=places";
            script.id = "googleMaps";
            document.body.appendChild(script);
            //action to do after a script is loaded in our case setState
            script.onload = () => {
                if (callback) callback();
            };
        }
        if (existingScript && callback) callback();
    };

    unloadGoogleMaps = () => {
        let googlePlacesScript = document.getElementById("googlePlacesScript");
        if (googlePlacesScript) {
            googlePlacesScript.remove();
        }
    };

    handleChange = (address) => {
        this.setState({ address });
    };

    handleSelect = async (address) => {
        let results = await geocodeByAddress(address);
        let latLng = await getLatLng(results[0]);
        console.log('Success', latLng);

        

        const { lat, lng } = latLng;
        let timeZoneUrl = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${Date.now() / 1000}&key=AIzaSyCYOkCu5PvaSVzT_4xoRbZuB5WO78ke0WY`

        const timezoneResponse = await (await fetch(timeZoneUrl)).json() ;
        // console.log("timezoneResponse :", timezoneResponse);
        // console.log("address :", address);
        let short = moment.tz(timezoneResponse.timeZoneId).zoneAbbr();

        this.props.onSelect({address: address, latLng: latLng, timezone:{...timezoneResponse, short} });
        this.setState({ address });

    };

    render() {
        if (!this.state.googleMapsReady) {
            return <p>Loading</p>;
        }
        return (
            <div style={{ ...this.props.style }}>
                <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}

                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <TextField
                                label={this.props.label}
                                placeholder="Address"
                                multiline
                                value={this.state.address}
                                onChange={this.setLocation}
                                {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input',
                                })}
                                style={{ ...this.props.style}}
                            />
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                            key={suggestion.description}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
            </div>
        );
    }

}