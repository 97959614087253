import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  typography: {
    fontFamily: '"Signika", serif'
  },
  overrides: {
    MuiButton: {
      root:{
        "&:hover": {
          backgroundColor: "#50c3b2"
        }
      },
      label: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        outline: "none"
      }
    },
    MuiFab: {
      root:{
        "&:hover": {
          backgroundColor: "#28a594"
        },
        backgroundColor: "#50c3b2"
      },
      label: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        outline: "none"
      }
    },
    MuiLink: {
      button:{
        fontFamily: '"Signika", serif'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: "#495056",
        fontFamily: "Signika",
        fontSize: 17,
        whiteSpace: "nowrap",
        overflow: "hidden",
        outline: "none"
      }
    },
    // MuiExpansionPanelSummary: {
    //   content :{
    //     display:"flex",
    //     justifyContent: "center"
    //   }
    // }
  },
  palette: {
    primary: { main: "#112b73" },
    secondary: { main: "#179627" },
    error: { main: "#ff0000" }
  }
});
