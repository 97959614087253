import React from "react";
import PropTypes from "prop-types";
import Kotcharam from "./Kotcharam";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as raasiAction from "../KattamAction";
import * as _ from "lodash";
import { withRouter } from "react-router-dom";
import Raasi from "../../common/Raasi";


export class KotcharamContainer extends React.Component {

	getRaasiOf = (bands, planet) => {

		return bands && bands.filter(b => !_.isEmpty(b.planets) && _.find(b.planets, {planet: planet}))[0];
	}
	getRaasiNumber = () => {
		let raa = this.getRaasiOf(this.props.userRaasi.raasiBand, "CHANDRAN").raasi;
		// let currentVal = this.getRaasiOf(this.props.raasiBand, raa);
		let currentVal =  _.find(this.props.raasiBand, {raasi:raa});
		// console.log("currentVal :"+ currentVal);
		// console.log("res :", res);
		// return res;
		return currentVal;
	}
	// raasi = this.getRaasiOf(this.props.userRaasi.raasiBand, "CHANDRAN")[0].raasi ;


	setHouseNoBasedOnRaasi = (kotcharamBand) => {
		let userRaaNo = this.getRaasiNumber().houseNo;

		return kotcharamBand.map(b => {
			let houseNo = b.houseNo - userRaaNo + 1;
			if (houseNo < 1) {
				houseNo = houseNo + 12;
			}
			// console.log("b.houseNo :"+b.houseNo + " userRaaNo :"+userRaaNo + " final :" + houseNo);
			b.houseNo = houseNo;
			return b;
		});
	}

  render() {
    if (_.isEmpty(this.props.raasiBand) || _.isEmpty(this.props.userRaasi)) {
      return <div>EMPTYKOTC</div>
    }
		// this.props.kotcharam.raasiBand = this.setHouseNoBasedOnRaasi(this.props.kotcharam.raasiBand);
    return (
      <Kotcharam
        data={this.props.kotcharam}
        profile={this.props.user}
        raasiBand={this.setHouseNoBasedOnRaasi(this.props.raasiBand)}
        actions={this.props.actions}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: _.get(state.raasi, "currentUser"),
    kotcharam: _.get(state.raasi, "kotcharam", {}),
    raasiBand: _.get(state.raasi, "kotcharam.raasiBand", []),
		userRaasi: _.get(state.raasi, "sample.data", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(raasiAction, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(KotcharamContainer));
