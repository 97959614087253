import { get, post } from "../../../httpService";
import * as AuthConfig from '../AuthorizeConfig';

export const loginWithGoogle = (data) => {
    window.location = AuthConfig.googleOAuth(data.oidcParams);
    // return get(AuthConfig.googleOAuth(data.oidcParams), true, false);
}

export const getGoogleToken = data => {
    let authCode = data.params.code;
    let clientParams = data.oidcParams;
    // let oauthTokenUrl = `https://oauth2.googleapis.com/token`;
    let oauthTokenUrl = "/social/token";
    let body = {
        code:authCode,
        client_id:clientParams.oidcParams.client_id,
        code_verifier: clientParams.codes.codeVerifier,
        redirect_uri: clientParams.oidcParams.redirect_uri,
        grant_type:"authorization_code",
    }
    return post(oauthTokenUrl, body);

}