import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as raasiAction from "../KattamAction";
import * as _ from "lodash";

import { withRouter } from "react-router-dom";
import PaavaVivaramKattam from "./PaavaVivaramKattam";

export class PaavaVivaramContainer extends React.Component {
  propTypes = {
    data: PropTypes.data,
  };

  componentDidMount() {
    this.props.actions.retrievePaavaVivaram();
  }

  render() {
    return (
      <div>
        {this.props.user && this.props.raasiBand &&
          <PaavaVivaramKattam data={this.props.data} actions={this.props.actions} profile={this.props.user}
            raasiBand={this.props.raasiBand} selectedHouse={this.props.selectedHouse}
            paavaVivarams={this.props.paavaVivarams} 
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let isRaasi = !_.get(state.raasi, "switcher.checked")
  return {
    user: _.get(state.raasi, "currentUser"),
    data: isRaasi ? _.get(state.raasi, "sample.data", {}) : _.get(state.raasi, "kotcharam", {}),
    raasiBand: isRaasi ? _.get(state.raasi, "sample.data.raasiBand", {}) : _.get(state.raasi, "kotcharam.raasiBand", []),
    selectedHouse: _.get(state.raasi, "selectedHouse"),
    paavaVivarams: _.get(state.raasi, "paavaVivarams", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(raasiAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PaavaVivaramContainer));
