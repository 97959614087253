import * as crypto from 'crypto';
import {v1} from 'uuid';
import base64 from 'base64url';

export const getAsParam = oidcParam => `client_id=${oidcParam.client_id}` +
    `&redirect_uri=${encodeURI(oidcParam.redirect_uri)}` +
    `&response_type=${encodeURI(oidcParam.response_type)}` +
    `&code_challenge=${oidcParam.code_challenge}` +
    `&code_challenge_method=${oidcParam.code_challenge_method}` +
    `&scope=${encodeURI(oidcParam.scope)}` +
    `&state=${oidcParam.state}`;


export const generateCode = () => {
    let codeVerifier = v1();
    return {
        codeVerifier: codeVerifier,
        challenge: base64.encode(crypto.createHash("sha256").update(codeVerifier).digest())
    };
};

export const googleOidcParam = (codes) => {
	const domainUrl = new URL(window.location.href).origin;
	let out = {
		client_id: "854012234432-0ldtutjktnlbdoo8bcgi5ncbpjmvs4c5.apps.googleusercontent.com",
		redirect_uri: domainUrl,
		// redirect_uri: "http://localhost:3000",
		response_type: "code",
		code_challenge: codes.challenge,
		code_challenge_method: "S256",
		scope: "openid email profile",
		state: v1()
	}

	return out;
}

export const googleOAuth = (oidcParam) => {
    let authorizeUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    return authorizeUrl + "?" + getAsParam(oidcParam);
}
