import React, { Component } from "react";
import DasaBhukthi from "./DasaBhuthi";
import * as dasaAction from "./DasaBhukthiActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as _ from "lodash";
import { withRouter } from "react-router-dom";
import DasaBhukthiTree from "./DasaBhuthiTree";

class DasaBhukthiContainer extends Component {

  render() {
    return <div style={{ margin:"5px", height: "580px", overflow: "scroll" }}>
      {!this.props.loading && this.props.dasas.length > 0
        && <DasaBhukthiTree dasas={this.props.dasas}
          actions={this.props.actions}
          userId={this.props.userId}
          current={this.props.current}
          jaadhagam={this.props.jaadhagam}
        />}
        {this.props.loading && <div style={{display: 'flex', justifyContent: 'center', alignItems:'center'}} class="animate-charcter">Loading Dasa Putthi..</div>}
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    userId: _.get(state.raasi, "currentUser.id"),
    dasas: _.get(state.dasa, "dasas", []),
    current: _.get(state.dasa, "current", {}),
    loading: _.get(state.dasa, "loading", {}),
    jaadhagam: _.get(state.raasi, "sample.data", {}),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(dasaAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DasaBhukthiContainer));
