import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as _ from "lodash";
import Login from './Login';
import * as LoginAction from './LoginAction';
import { withRouter } from "react-router";
import * as OAuthActions from '../Authorization/OAuth/OAuthAction';
import * as LocalService from '../../LocalStoreService'


class LoginContainer extends React.Component {

  componentDidMount() {
    let params = new URLSearchParams(this.props.location.search);
    let code = params.get("code");
    let state = params.get("state")
    if (code) {
      this.props.oAuthActions.getToken({ code, state }, LocalService.get("google-oauth"));
    }

    if (this.props.isLoggedIn) {
      this.props.history.push("/kattam");
    }
  }

  static getDerivedStateFromProps(prevProps, state) {
    if (prevProps && prevProps.isLoggedIn) {
      prevProps.history.push("/kattam");
    }
  }

  render() {
    return (
      <div>
        {this.props.loginStarted &&
          <div>Loading..</div>}Í
        {!this.props.loginStarted &&
          <Login actions={this.props.actions} history={this.props.history} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: _.get(state.raasi, "currentUser"),
    isLoggedIn: _.get(state.oauth, "isLoggedIn"),
    loginStarted: _.get(state.oauth, "loginStarted")
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(LoginAction, dispatch),
    oAuthActions: bindActionCreators(OAuthActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginContainer));