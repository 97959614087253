import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Collapse, IconButton, List, ListItem, ListItemText, Paper, styled, Tab, Tabs, Typography } from "@material-ui/core";
import _ from 'lodash';
import * as LocalStore from '../../LocalStoreService';
import { TabContext, TabPanel } from "@material-ui/lab";
import { formatDate } from '../DasaBhukthi/DasaUtil';
import Taurus from '../../img/taurus.jpeg';
import MoreVertIcon from '@material-ui/icons/MoreVertOutlined'
import { red } from "@material-ui/core/colors";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreOutlined";
import { planetImage } from "../common/Planets";
import * as PredictionActions from "./PredictionAction";

class KotcharamPredictionContainer extends Component {

  state = {
    tense: 0,
    expanded: false
  }
  getUser = () => {
    return this.props.user.name;
  };

  isAdmin = () => {
    let user = LocalStore.getGoogleUser();
    return user.role === "ADMIN";
  }

  goBack = () => this.props.history.goBack();

  handleChange = (d, newValue) => {
    this.setState({ tense: newValue });
    console.log("newValue :", newValue);
  };

  getValidity = (inf) => {
    if (_.isEmpty(inf)) return;
    let infl = inf && inf[0];
    return infl && this.state.tense != 0 && <div style={{ color: "grey" }}>
        <div><span>Start : </span><span>{formatDate(infl.startDate)}</span></div>
        <div><span>Ends : </span><span>{formatDate(infl.endDate)}</span></div>
        </div>;
  }

  getPlanetImage = inf => {
    if (_.isEmpty(inf)) return planetImage['multi'];
    let infl = inf && inf[0];
    return planetImage[infl.planet];
  }

  handleExpandClick = () => this.setState({ expanded: !this.state.expanded });
  handleCorrect = (p) => () => {
    this.props.actions.saveCorrectPrediction(p._id,this.props.user.id);
  }
  handleInCorrect = (p) => () => {
    this.props.actions.saveInCorrectPrediction(p._id,this.props.user.id);
  }
  handleMayBe = (p) => () => {
    this.props.actions.saveMayBePrediction(p._id,this.props.user.id);
  }
  getPlanet = inf => {
    if (_.isEmpty(inf)) return "Multi";
    let infl = inf && inf[0];
    return infl.planet;
  }


  stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  stringAvatar(name) {
    return {
      style: {
        backgroundColor: this.stringToColor(name),
      },
      children: name && `${name.split('')[0][0]}${name.split('')[1][0]}`,
    };
  }

  handleVote = v => {
    console.log(v.target);
  }

  getKotcharamPrediction = kotcharamPredictions => {
    return <div style={{ display: "flex", flexWrap: 'wrap', flexDirection: 'row', margin: '3px' }}>
                {kotcharamPredictions.map((p, index) => {
                  let rule = { ...p, predictions: undefined, tags: undefined, positivity: undefined, reference: undefined, influencers: undefined , ownerId: undefined};
                  return (

                    <Card style={{ maxWidth: '340px', minWidth: '340px', margin: '3px'  }}>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ backgroundColor: red[500] }} aria-label="recipe" {... this.stringAvatar(this.getPlanet(p.influencers))} />
                        }
                        action={
                          <IconButton aria-label="settings">
                            <MoreVertIcon />
                          </IconButton>
                        }
                        title={_.capitalize(p.reference)}
                        subheader={this.getValidity(p.influencers)}
                      />
                      <CardMedia
                        component="img"
                        height="140"
                        image={this.getPlanetImage(p.influencers)}
                        alt="planet"
                      />
                      <CardContent>

                        <Typography gutterBottom variant="h6" component="div" color={p.positivity ? 'primary' : 'secondary'}>
                          {p.tags}
                        </Typography>
                        {p.predictions && p.predictions.map(pd => <Typography paragraph>
                          {pd}
                        </Typography>)}
                      </CardContent>
                      <CardActions>
                        <Button size="small" onClick={this.handleCorrect(p)}>Agree</Button>
                        <Button size="small" onClick={this.handleMayBe(p)}>MayBe</Button>
                        <Button size="small" onClick={this.handleInCorrect(p)}>Disagree</Button>

                        {this.isAdmin() && <ExpandMore
                          expand={this.state.expanded}
                          onClick={this.handleExpandClick}
                          aria-expanded={this.state.expanded}
                          aria-label="show more"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>}
                      </CardActions>

                      <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                          <Typography paragraph>
                            {this.isAdmin() && <div style={{ color: "grey", fontSize: "x-small" }}>{JSON.stringify(rule)}</div>}
                          </Typography>
                        </CardContent>
                      </Collapse>

                    </Card>

                  );
                })}

              </div>
  }

  render() {
    if (_.isEmpty(this.props.kotcharamPredictionsOfPP) && _.isEmpty(this.props.lifeTimePredictions) && _.isEmpty(this.props.predictions)) {
      return <div></div>;
    }
    return (
      <div>

        {(!_.isEmpty(this.props.kotcharamPredictionsOfPP) || !_.isEmpty(this.props.lifeTimePredictions)) && <div
          style={{
            border: "2px solid gold",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <TabContext>

            <Tabs
              value={this.state.tense}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
              style={{ backgroundColor: "#ffffff" }}
            >
              <Tab label="All Time" />
              <Tab label="Past" hidden disabled={_.isEmpty(this.props.kotcharamPredictionsOfPPForPast)} />
              <Tab label="Present" disabled={_.isEmpty(this.props.kotcharamPredictionsOfPP)}/>
              <Tab label="Future" disabled={_.isEmpty(this.props.kotcharamPredictionsOfPPForFuture)} />
            </Tabs>


            <TabPanel index={0} hidden={this.state.tense !== 0}>
              <div style={{ display: "flex", flexWrap: 'wrap', flexDirection: 'row', margin: '3px' }}>
                {this.props.lifeTimePredictions.map((p, index) => {
                  let rule = { ...p, predictions: undefined, tags: undefined, positivity: undefined, reference: undefined, influencers: undefined, ownerId: undefined };
                  return (

                    <Card style={{ maxWidth: '345px', minWidth: '345px', margin: '3px' }} key ={p.reference}>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ backgroundColor: red[500] }} aria-label="recipe" {... this.stringAvatar(this.getPlanet(p.influencers))} />
                        }
                        action={
                          <IconButton aria-label="settings">
                            <MoreVertIcon />
                          </IconButton>
                        }
                        title={_.capitalize(p.reference)}
                        subheader={this.isAdmin() && this.getValidity(p.influencers)}
                      />
                      {/* <CardMedia
                        component="img"
                        height="140"
                        image={this.getPlanetImage(p.influencers)}
                        alt="planet"
                      /> */}
                      <CardContent>

                        <Typography gutterBottom variant="h6" component="div" color={p.positivity ? 'primary' : 'secondary'}>
                          {p.tags}
                        </Typography>
                        {p.predictions && p.predictions.map(pd => <Typography paragraph>
                          {pd}
                        </Typography>)}
                      </CardContent>
                      <CardActions style={{justifyContent: "space-between"}}>
                        <Button size="small" onClick={this.handleCorrect(p)}>Agree</Button>
                        <Button size="small" onClick={this.handleMayBe(p)}>MayBe</Button>
                        <Button size="small" onClick={this.handleInCorrect(p)}>Disagree</Button>

                        {this.isAdmin() && <ExpandMore
                          expand={this.state.expanded}
                          onClick={this.handleExpandClick}
                          aria-expanded={this.state.expanded}
                          aria-label="show more"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>}
                      </CardActions>

                      <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                          <Typography paragraph>
                            {this.isAdmin() && <div style={{ color: "grey", fontSize: "x-small" }}>{JSON.stringify(rule)}</div>}
                          </Typography>
                        </CardContent>
                      </Collapse>

                    </Card>

                  );
                })}
              </div>
            </TabPanel>


            <TabPanel index={1} hidden={this.state.tense !== 1}>
            {this.getKotcharamPrediction(this.props.kotcharamPredictionsOfPPForPast)}
            </TabPanel>


            <TabPanel index={2} hidden={this.state.tense !== 2}>
              {this.getKotcharamPrediction(this.props.kotcharamPredictionsOfPP)}
            </TabPanel>

            <TabPanel index={3} hidden={this.state.tense !== 3} >
            {this.getKotcharamPrediction(this.props.kotcharamPredictionsOfPPForFuture)}
            </TabPanel>

          </TabContext>

        </div>}

        <div style={{ margin: "10px", display: this.state.tense === 0 ? 'flex' : 'none' }}>Lifetime - General Predictions <span style={{ color: "red" }}></span></div>
        <div
          style={{
            border: "1px solid green",
            flexDirection: "column",
            padding: "10px",
            margin: "10px",
            flexWrap: 'wrap',
            display: this.state.tense === 0 ? 'flex' : 'none'
          }}
        >


          {this.props.predictions && this.props.predictions.map((p, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexWrap: 'wrap'
                }}
              >
                {p.positivity && <div style={{ color: "green", fontSize: "medium" , flexWrap: 'wrap'}}>{p.name}</div>}
                {!p.positivity && <div style={{ color: "red", fontSize: "medium" , flexWrap: 'wrap'}}>{p.name}</div>}
                <Typography style={{ display: 'flex', marginLeft: '10px', marginBottom: '10px' , flexWrap: 'wrap'}} variant="p">{p.prediction}</Typography>
              </div>
            );
          })}
        </div>

      </div>
    );
  }
}

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const mapStateToProps = (state) => {
  return {
    predictions: _.get(state.raasi, "predictions", []),
    predictionspp: _.get(state.raasi, "predictionsOfPP", []),
    lifeTimePredictions: _.get(state.raasi, "predictionsOfPP", []),
    kotcharamPredictionsOfPP: _.get(state.raasi, "kotcharamPredictionsOfPP", []),
    kotcharamPredictionsOfPPForPast: _.get(state.raasi, "kotcharamPredictionsOfPPForPast", []),
    kotcharamPredictionsOfPPForFuture: _.get(state.raasi, "kotcharamPredictionsOfPPForFuture", []),
    user: _.get(state.raasi, "currentUser", {})
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(PredictionActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(KotcharamPredictionContainer));
