
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    MESAM: {name: "MESAM", number: 1},
	RISABAM : {name: "RISABAM", number: 2},
	MITHUNAM : {name: "MITHUNAM", number: 3},
	KADAGAM : {name: "KADAGAM", number: 4},
	SIMMAM : {name: "SIMMAM", number: 5},
	KANNI : {name: "KANNI", number: 6},
	THULAM : {name: "THULAM", number: 7},
	VIRUCHAGAM : {name: "VIRUCHAGAM", number: 8},
	DHANUSU : {name: "DHANUSU", number: 9},
	MAKARAM : {name: "MAKARAM", number: 10},
	KUMBAM : {name: "KUMBAM", number: 11},
	MEENAM : {name: "MEENAM", number: 12},
}