import { Component } from "react"
import KotcharamContainer from "../components/Kattam/Kotcharam/KotcharamContainer";
import Canvas from './Canvas';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as raasiAction from "../components/Kattam/KattamAction";
import * as _ from "lodash";
import { withRouter } from "react-router-dom";
import Planets from "../components/common/Planets";

class CanvasContainer extends Component {

  // degrees =[
  //   {id : Planets.PUDHAN, degree: 280},
  //   {id : Planets.CHANDRAN, degree: 340},
  //   {id : Planets.SUKKIRAN, degree: 90},
  //   {id : Planets.CHANDRAN, degree: 340},
  //   {id : Planets.CHEVVAI, degree: 180},
  //   {id : Planets.GURU, degree: 220},
  //   {id : Planets.SANI, degree: 90},
  //   {id : Planets.SOORIYAN, degree: 0},

  // ]
  degrees = [];
  createDegrees = () => {
    this.props.raasiBand.filter(b => !_.isEmpty(b.planets)).map(b => {
      b.planets.map(p => {
        this.degrees.push({ id: p.planet, degree: p.location.longitude })
        // console.log("Pushing. :" +  p.planet + " p.location.longitude :"+p.location.longitude);
      })
    })
  }

  componentDidMount() {
    this.degrees = [];
  }
  render() {
    if (_.isEmpty(this.degrees)) {
      this.createDegrees();
      // console.log("this.degrees :", this.degrees);
    }
    if (_.isEmpty(this.props.raasiBand)) {
      return <div></div>
    }
    return (
      <div>
        <KotcharamContainer />
        {!_.isEmpty(this.degrees) && <Canvas text='Planets' degrees={this.degrees} date={this.props.kotcharam.dob} planetLngMap={this.props.planetLngMap} />}
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    kotcharam: _.get(state.raasi, "kotcharam", {}),
    user: _.get(state.raasi, "currentUser"),
    raasiBand: _.get(state.raasi, "kotcharam.raasiBand", []),
    planetLngMap: createDegreesToObj(_.get(state.raasi, "kotcharam.raasiBand", {}))
  };
};

const createDegreesToObj = (raasiBand) => {
  if (_.isEmpty(raasiBand)) return {};
  let degrees = [];
  raasiBand.filter(b => !_.isEmpty(b.planets)).map(b => {
    b.planets.map(p => {
      let lng = (p.location.longitude);
      degrees.push({ id: p.planet, degree: lng })
      // console.log("Pushing. :" +  p.planet + " p.location.longitude :"+p.location.longitude);
    })
  });
  // console.log("degrees :", degrees);
  let lngMap = _.chain(degrees)
    .keyBy('id')
    .mapValues('degree')
    .value();
  // console.log("lngMap :", lngMap);
  let x = 60;
  
  // lngMap["CHEVVAI"] = (lngMap["CHEVVAI"] + x + 3) % 360;
  lngMap["SUKKIRAN"] = getDegreeCorrection(lngMap["SUKKIRAN"] + x);
  lngMap["PUDHAN"] = getDegreeCorrection(lngMap["PUDHAN"] + x);
  lngMap["CHANDRAN"] = (lngMap["CHANDRAN"] + 60) % 360;
  lngMap["SOORIYAN"] = getDegreeCorrection(lngMap["SOORIYAN"] + x);
  lngMap["CHEVVAI"] = getDegreeCorrection(lngMap["CHEVVAI"]+x);
  lngMap["SANI"] = getDegreeCorrection(lngMap["SANI"] + x);
  lngMap["GURU"] = getDegreeCorrection(lngMap["GURU"] + x);
  return lngMap;
}

const getDegreeCorrection = d => {
  d = d % 360;
  if (d % 90 === 0) return d;
  if (d > 0 && d < 90) return d + 4;
  if (d > 90 && d < 180) return d - 4;
  if (d > 180 && d < 270) return d + 4;
  if (d > 270 && d < 360) return d - 4;
  return d;
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(raasiAction, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CanvasContainer));


// Sani
// 27-Oct-2017 - To Dhanusu ( 2yrs 3 months)
//25-Jan-2020 - To Makaram ( 2yrs 3 months)
//30-Apr-2022 - To Kumbham ( 3months)
// 13-Jul-2022 - To Makaram  (Backwards) - (1 ys 5 months)
//18-Jan-2023 - To Kumbham  (2 yrs 2mon)
//30-Mar-2025 - To Meenam  (2 yr 3mon)
//3-Jun-2027 - To Mesham ( 4 months)
//21-Oct-2027 - To Meenam (Backwards) - (4 months)
//24-Feb-2028 - To Mesham ( 1 yr, 6 month)
//9-Aug-2029 - To Rishabam ( 2months)
//6-Oct-2029 - To Mesham  (Backwards) -( 6 months)
//18-Apr-2030 - To Rishabam
