import React from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import _ from "lodash";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export default function UserList(props) {
  const classes = useStyles();
  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell >Name</StyledTableCell>
              <StyledTableCell align="left">Date of Birth</StyledTableCell>
              <StyledTableCell align="left">Place of Birth</StyledTableCell>
              <StyledTableCell align="left">Email </StyledTableCell>
              <StyledTableCell align="left">Created Date </StyledTableCell>
              <StyledTableCell align="left">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.isArray(props.users) && props.users.map((user) => (
              <StyledTableRow key={user.id}>
                <StyledTableCell key={'name' + user.id} component="th" scope="row">
                  {user.name}
                </StyledTableCell>
                <StyledTableCell key={'dob' + user.id} align="left">{formatDt(user.dobStr)}</StyledTableCell>
                <StyledTableCell key={'addr' + user.id} align="left">{user.address}</StyledTableCell>
                <StyledTableCell key={'email' + user.id} align="left">{user.emailId}</StyledTableCell>
                <StyledTableCell key={'dt' + user.id} align="left">{dateFromObjectId(user.id)}</StyledTableCell>
                <StyledTableCell key={'delete' + user.id} align="left">
                  <Tooltip title="Delete">
                    <IconButton aria-label="delete" onClick={() => handleDelete(user.id, props)}>
                      <DeleteIcon color="secondary" />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

     {props.isDeleted && <Snackbar open={props.isDeleted.message !== ""} autoHideDuration={5000}>
        <Alert severity={props.isDeleted.type}>
          {props.isDeleted.message}
        </Alert>
      </Snackbar>}
    </div>
  );

}

const handleDelete = (userId, props) => {
  console.log("userId:", userId);
  props.actions.deleteUserJaathagan(userId);
}

const formatDate = (dt, defaultDt) => {
  try {
    let formatted = moment(dt).format('ddd, MMM Do YYYY, h:mm a');
    return formatted === "Invalid date" ? defaultDt : formatted;
  } catch (err) {
    return defaultDt;
  }
};

const formatDt = (dobStr) => {
	let newDate = moment(dobStr); //.format("ddd, MMM Do YYYY, h:mm a");
	return newDate.isValid()? newDate.format("ddd, MMM Do YYYY, h:mm a") : dobStr ;
};

const dateFromObjectId = (objectId) => {
  let objId = new String(objectId);
  let dt = new Date(parseInt(objId.substring(0, 8), 16) * 1000);
  return formatDate(dt);
};
const getISTTime = d => {
  return d.getTime() + (5.5 * 60 * 60 * 1000)
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2096f3",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
