import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, colors, LinearProgress, Typography } from '@material-ui/core';

export function LinearProgressWithLabel(props) {
  
  return (

    // <Box sx={{ minWidth: 35, flexDirection: 'column-reverse' }} >
    //   <LinearProgress variant="determinate" {...props} />
    // </Box>

    
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={props.value} color={props.color }  />
      </Box>
      <Typography variant="body2" color="textSecondary">{`${props.value
        }%`}</Typography>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel() {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <LinearProgressWithLabel value={progress} />
  );
}