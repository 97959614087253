import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import raasiNames from "../KattamConstants";
import FilterListIcon from "@material-ui/icons/FilterList";
import SpecialHouse from "../Raasi/SpecialHouse";
import Tooltip from '@material-ui/core/Tooltip';
import { injectIntl } from 'react-intl';
import { bondSymbol } from "../../common/Planets";


class PlanetsBond extends Component {
  state = {
    name: this.props.name,
    planets: ["p1", "p2", "p3"],
    isLagnam: "false",
  };
  styles = {
    name: {
      margin: "4px",
      color: "darkgrey",
    },
    raasi: {
      border: "1px solid green",
      margin: "0.5px",
      width: this.props.width,
      height: this.props.height,
      display: "flex",
      flexDirection: "column",
      fontSize: "small",
      justifyContent: "space-around",
    },

    planets: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-end",
      fontSize: "x-small",
      margin: "4px",
      fontWeight: "bold",
      flexFlow: "wrap"
    },
  };
  getPlanetsBond = (data) =>
    data.planetsBond && data.planetsBond.length > 0 ? data.planetsBond : [];

  getShortName = (name) => name + "_SHORT";

  getHouseNumber = (houseNumber) =>
    houseNumber === 1 && houseNumber !== 0 ? 12 : houseNumber - 1;

  getStyles = (name) => {
    if (
      name === raasiNames.MESAM ||
      name === raasiNames.RISABAM ||
      name === raasiNames.VIRUCHAGAM ||
      name === raasiNames.THULAM
    ) {
    }
  };

  getSpecialHouseText = () => {
    let info = [];
    let data = this.props.data;
    if (!data.specials) {
      return;
    }
    if (data.specials.includes(SpecialHouse.MAARAKAM)) {
      info.push("M");
    }
    if (data.specials.includes(SpecialHouse.PAATHAKAM)) {
      info.push("P");
    }
    if (data.specials.includes(SpecialHouse.KENDIRAM)) {
      info.push("K");
    }
    if (data.specials.includes(SpecialHouse.TRIKONAM)) {
      info.push("T");
    }
    return info.join("/");
  };

  getTooltip = p => `${this.props.intl.formatMessage({id:p.planet})}/${this.props.intl.formatMessage({id:p.natchathiram})}-${p.paadham} ${this.props.intl.formatMessage({id:p.natchathiraAthipathi})}`

  isLagnam = (raasi) =>
    raasi.specials && raasi.specials.includes(SpecialHouse.LAGNAM);

  render() {
    if (!this.props.data) {
      return;
    }
    let name =
      this.props.data && this.props.data.raasi ? this.props.data.raasi : "NA";
    let planetsBond = this.getPlanetsBond(this.props.data);
    let lagnam = this.isLagnam(this.props.data);
    let specialHouseText = this.getSpecialHouseText();
    return (
      <div name={name} style={this.styles.raasi}>
        
        {/* {isLagnam && <Lagnam size={this.styles.raasi.width} />} */}
        {lagnam && (
          <FilterListIcon
            style={{ transform: "rotate(135deg)", margin: "-8px" }}
          />
        )}
        <span style={this.styles.name}>
          <span>{this.props.data.houseNo} </span>
          <FormattedMessage id={name} />
        </span>

        {/* <span style={{ marginLeft: "3px" }}>{specialHouseText}</span> */}

        
        <div style={this.styles.planets}>
          {planetsBond.map((p) => {
            return (
              <div name={p.fromPlanet}>
                <FormattedMessage id={this.getShortName(p.fromPlanet)} />
                {bondSymbol(p.bondType)}
                <FormattedMessage id={this.getShortName(p.toPlanet)} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default injectIntl(PlanetsBond);