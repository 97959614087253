import "./App.css";
import { Provider } from "react-redux";
import store from "./store.js";
import { theme } from "./theme";
import { ThemeProvider } from "@material-ui/styles";
import { IntlProvider } from "react-intl";
import { ta } from "./Locale";
import AppRouter from "./AppRouter";

function App() {
  return (
    <ThemeProvider>
      <Provider store={store()}>
        <IntlProvider messages={ta} locale={"ta"}>
          <AppRouter>
            
          </AppRouter>
        </IntlProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
