import React, { PureComponent } from 'react'
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Sector
} from 'recharts'
import { chunk, get, isEmpty, reverse } from 'lodash'
import { bondColor } from '../common/Planets'
import moment from 'moment'
import Zone from 'moment-timezone'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

const getDate = (start, end) => `${moment(end).format('DoMMM')}`
const getTime = (start, end) =>
  `${moment(start).format('h')}:${moment(start).format('mm')} to ${moment(
    end
  ).format('h')}:${moment(end).format('mm A')}`

const getZoneAbbrevation = (end, zone) => `${moment(end).tz(zone).zoneAbbr()}`

const renderActiveShapeClosure = stProps => props => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    start,
    end,
    planet,
		bondType
	} = props
	const {zone} = stProps;
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <text x={cx} y={cy} dy={5} textAnchor='middle' fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill='none'
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fontSize={'x-small'}
        fill='#333'
      >
        {getDate(start, end)}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fontSize={'small'}
        fontWeight={'bold'}
        fill='#333'
      >
        {getTime(start, end)}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={33}
        textAnchor={textAnchor}
        fontSize={'small'}
        fontWeight={'bold'}
        fill='#333'
      >
        {getZoneAbbrevation(end, zone)}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={49}
        textAnchor={textAnchor}
        fontSize={'small'}
        fill='#333'
      >
        <FormattedMessage id={planet} />
      </text>
    </g>
  )
}

class OraiClock extends PureComponent {
  state = {
    activeIndex: 0
  }

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index
    })
  }

  render () {
    let oraiChunk = this.props['orai' + this.props.oraiChunk]
    let newOrai = oraiChunk.map((e, i) => {
      let start = moment(e.start, 'YYYY-MM-DDTHH:mm:ssZ')
        .tz(this.props.zone)
        .format('DD-MMM-YYYY hh:mm a')
      let end = moment(e.end, 'YYYY-MM-DDTHH:mm:ssZ')
        .tz(this.props.zone)
        .format('DD-MMM-YYYY hh:mm a')
      return { ...e, start, end }
    })
    if (isEmpty(oraiChunk)) return <div></div>

    return (
      <div>
        {/* <div>{newOrai.map((entry, index) => {return <div style={{flexDirection: "column"}}>{entry.end}</div>})}</div> */}

        <ResponsiveContainer width={'90%'} aspect={1.5} minWidth={'510px'}>
          {newOrai && (
            <PieChart>
              <Pie
                activeIndex={this.state.activeIndex}
                dataKey='duration'
                nameKey='planet'
                valueKey='end'
                startAngle={90}
                endAngle={-290}
                offsetRadius={0}
                activeShape={renderActiveShapeClosure(this.props)}
                isAnimationActive={true}
                data={newOrai}
                cx='50%'
                cy='45%'
                outerRadius={80}
                fill='#8884d8'
                onMouseEnter={this.onPieEnter}
              >
                {newOrai.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={bondColor(entry.bondType)}
                  />
                ))}
              </Pie>
            </PieChart>
          )}
        </ResponsiveContainer>
      </div>
    )
  }
}

const mapStateToProps = state => {
  let chunks = chunk(get(state.raasi, 'generalOrai'), 12)
	let zoneId = get(state.raasi, 'zoneId', "America/Chicago")
  return {
    orai0: !isEmpty(chunks) ? chunks[0] : [],
    orai1: !isEmpty(chunks) ? chunks[1] : [],
    zone: zoneId
  }
}

export default connect(mapStateToProps, null)(injectIntl(withRouter(OraiClock)))
