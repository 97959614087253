import jupiter from '../../img/jupiter.jpg';
import kedhu from '../../img/kedhu.jpg';
import raaghu from '../../img/raaghu.jpg';
import mars from '../../img/mars.jpeg';
import mercury from '../../img/mercury.jpg';
import moon from '../../img/moon.jpg';
import multi from '../../img/multi.jpg';
import saturn from '../../img/saturn.jpg';
import venus from '../../img/venus.png';
import sun from '../../img/sun.jpeg';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    SOORIYAN:"SOORIYAN",
    CHANDRAN:"CHANDRAN",
    PUDHAN: "PUDHAN",
    GURU:"GURU",
    CHEVVAI:"CHEVVAI",
    SUKKIRAN:"SUKKIRAN",
    SANI:"SANI",
    RAAGU:"RAAGU",
    KEDHU:"KEDHU"
}

export const planetImage = {
    SOORIYAN:sun,
    CHANDRAN:moon,
    PUDHAN: mercury,
    GURU:jupiter,
    CHEVVAI:mars,
    SUKKIRAN:venus,
    SANI:saturn,
    RAAGU:raaghu,
    KEDHU:kedhu,
    multi:multi,
}

export const planetColors =  {
    SOORIYAN:"#ffd17d",
    CHANDRAN:"#f1efef",
    PUDHAN: "#79df79",
    GURU:"#f5f584",
    CHEVVAI:"#ff6f6f",
    SUKKIRAN:"#84bcf5",
    SANI:"#4c4b4b",
    RAAGU:"#ffc3ff",
    KEDHU:"#c080fb",
    "OPEN": "green",
    "NORMAL": "white",
    "CLOSED": "#ff6f6f",

    1:"#ffd17d",
    2:"#f1efef",
    3: "#79df79",
    4:"#f5f584",
    5:"#ff6f6f",
    6:"#84bcf5",
    7:"#4c4b4b",
    8:"#ffc3ff",
    9:"#c080fb",
    10: "green",
    11: "white",
    12: "#ff6f6f"
}

export const planetForeColors =  {
    SOORIYAN:"black",
    CHANDRAN:"black",
    PUDHAN: "black",
    GURU:"black",
    CHEVVAI:"black",
    SUKKIRAN:"black",
    SANI:"white",
    RAAGU:"black",
    KEDHU:"black",
    "OPEN": "black",
    "NORMAL": "black",
    "CLOSED": "black"
}

export const bondColor = bondType => {
    if(bondType === "SAMAM") return "#f3ea44";
    if(bondType === "NATPU") return "#3cf53c";
    if(bondType === "ENEMY") return "#f95252";
}


export const beneficWeightage =  {
    SOORIYAN:3,
    CHANDRAN:6,
    PUDHAN: 7,
    GURU:9,
    CHEVVAI:5,
    SUKKIRAN:8,
    SANI:4,
    RAAGU:2,
    KEDHU:1
}

export const bondSymbol = bondType => {
    if(bondType === "SAMAM") return "=";
    if(bondType === "NATPU") return "+";
    if(bondType === "ENEMY") return "x";
}

export const kaaragans =  {
    SOORIYAN:{podhagan: "CHEVVAI",vedhagan: "SUKKIRAN",paasagan: "SANI", kaaragan:"GURU"},
    CHANDRAN:{podhagan: "CHEVVAI",vedhagan: "SOORIYAN",paasagan: "SUKKIRAN", kaaragan:"SANI"},
    CHEVVAI:{podhagan: "CHANDRAN",vedhagan: "PUDHAN",paasagan: "SOORIYAN", kaaragan:"SANI"},
    GURU:{podhagan: "CHEVVAI",vedhagan: "SOORIYAN",paasagan: "SANI", kaaragan:"CHANDRAN"},
    SANI:{podhagan: "CHANDRAN",vedhagan: "CHEVVAI",paasagan: "SUKKIRAN", kaaragan:"GURU"},
    PUDHAN: {podhagan: "GURU",vedhagan: "CHEVVAI",paasagan: "SUKKIRAN", kaaragan:"SUKKIRAN"},
    SUKKIRAN:{podhagan: "GURU",vedhagan: "SANI",paasagan: "PUDHAN", kaaragan:"SOORIYAN"},
}

export const bonds = ["UCCHAM", "MOOLATHIRIKONAM", "AATCHI", "NATPU", "SAMAM", "ENEMY", "NEECHAM"];