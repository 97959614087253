import KattamActionType from "./KattamActionType";

export const getRaasiCalculation = (userId) => {
  return (dispatch) =>
    dispatch({
      type: KattamActionType.GET_RAASI_CALCULATION,
      userId: userId,
    });
};

export const getDasaByDate = (userId, date) => {
  return (dispatch) =>
    dispatch({
      type: KattamActionType.GET_DASA_BY_DATE,
      userId: userId,
      date: date
    });
};

export const retrieveUsers = () => {
  return (dispatch) => dispatch({ type: KattamActionType.GET_USERS });
};

export const setShowOnlyYearlyPlanets = () => {
  return (dispatch) => dispatch({ type: KattamActionType.SET_SHOW_ONLY_YEARLY_PLANETS });
};

export const setHouseDetails = (data, houseNo) => {
  return (dispatch) => dispatch({ type: KattamActionType.SET_HOUSE_DETAIL, data: { data, houseNo }, });
};

export const setTimeZone = (zoneId) => {
  return (dispatch) => dispatch({ type: KattamActionType.SET_TIME_ZONE, zoneId: zoneId });
};


export const retrievePaavaVivaram = () => (dispatch) => dispatch({ type: KattamActionType.RETRIEVE_PAAVA_VIVARAM });

export const switchRaasiVsKotcharam = () => {
  return (dispatch) => dispatch({ type: KattamActionType.SET_RAASI_VS_KATTAM });
};

export const retrievePlanetStrength = (userId) => {
  return (dispatch) => dispatch({ type: KattamActionType.RETRIEVE_PLANET_STRENGTH, userId });
};

export const retrieveHouseStrength = (userId) => {
  return (dispatch) => dispatch({ type: KattamActionType.RETRIEVE_HOUSE_STRENGTH, userId });
};





export const retrieveKotcharam = (date) => {
  return (dispatch) =>
    dispatch({
      type: KattamActionType.GET_KOTCHARAM,
      date: date,
    });
};

export const retrieveKotcharamPlanetStrength = (date, userId) => {
  return (dispatch) =>
    dispatch({
      type: KattamActionType.GET_KOTCHARAM_PLANET_STRENGTH,
      date: date,
      userId: userId
    });
};

export const retrieveKotcharamHouseStrength = (date, userId) => {
  return (dispatch) =>
    dispatch({
      type: KattamActionType.GET_KOTCHARAM_HOUSE_STRENGTH,
      date: date,
      userId: userId
    });
};

export const retrieveOrai = (date, zoneId) => {
	if (!zoneId) zoneId = "America/Chicago";
  return (dispatch) =>
    dispatch({
      type: KattamActionType.GET_ORAI,
			date: date,
			zoneId: zoneId
    });
};

export const getUserPredictions = (userId) => {
  return (dispatch) =>
    dispatch({
      type: KattamActionType.GET_USER_PREDICTIONS,
      userId: userId,
    });
};

export const getUserPredictionsOfPP = (userId) => {
  return (dispatch) =>
    dispatch({
      type: KattamActionType.GET_USER_PREDICTIONS_OF_PP,
      userId: userId,
    });
};
export const getUserPredictionsOfPPReferences = (userId, referenceIds) => {
  return (dispatch) =>
    dispatch({
      type: KattamActionType.GET_USER_PREDICTIONS_OF_PP_REFERENCES,
      userId: userId,
			referenceIds: referenceIds
    });
};


export const getUserKotcharamPredictionsOfPP = (userId) => {
  return (dispatch) =>
    dispatch({
      type: KattamActionType.GET_USER_KOTCHARAM_PREDICTIONS_OF_PP,
      userId: userId,
    });
};

export const getUserKotcharamPredictionsOfPPForPast = (userId) => {
  return (dispatch) =>
    dispatch({
      type: KattamActionType.GET_USER_KOTCHARAM_PREDICTIONS_OF_PP_FOR_PAST,
      userId: userId,
    });
};

export const getUserKotcharamPredictionsOfPPForFuture = (userId) => {
  return (dispatch) =>
    dispatch({
      type: KattamActionType.GET_USER_KOTCHARAM_PREDICTIONS_OF_PP_FOR_FUTURE,
      userId: userId,
    });
};

export const getTransitDates = (planetsAsString) => {
  return (dispatch) =>
    dispatch({
      type: KattamActionType.GET_TRANSIT_DATES,
      planets: planetsAsString
    });
};



export const setCurrentUser = (user) => {
  return (dispatch) =>
    dispatch({
      type: KattamActionType.SET_CURRENT_USER,
      data: user,
    });
};

export const resetCurrentUser = () => {
  return (dispatch) =>
    dispatch({
      type: KattamActionType.RESET_CURRENT_USER
    });
};


export const deleteUserJaathagan = (userId) => {
  return (dispatch) =>
    dispatch({
      type: KattamActionType.DELETE_USER_JAATHAGAN,
      userId: userId,
    });
};

