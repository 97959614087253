import Planets from '../components/common/Planets';
/* eslint-disable no-restricted-globals */
const canvasWidth = 1200;
let topOffset = "150";
let centerCorrection = -60;
let center = { x: canvasWidth / 2 - centerCorrection, y: screen.height / 2 - topOffset - centerCorrection };

let planetSizeFactor = 5;
let speedFactor = 1;
let distanceFromSunFactor = 40;
let eccentricityFactor = 40;
let orbitThickness = 0.5;

let planets = [
  {
    id: Planets.PUDHAN,
    name: "பு",
    // name: "Mercury",
    size: 0.38 * planetSizeFactor,
    color: "green",
    center: center,
    degree: 280,
    orbitHalfHeight: 0.313 * 2 * eccentricityFactor,
    orbitHalfWidth: 0.459 * 2 * distanceFromSunFactor,
    speed: 1.59 * speedFactor,
    hasMagneticField: true,
  },
  {
    id: Planets.SUKKIRAN,
    name: "சு",
    // name: "Venus",
    size: 0.94 * planetSizeFactor,
    color: "blue",
    center: center,
    degree: 90,
    orbitHalfHeight: 0.731 * 2 * eccentricityFactor,
    orbitHalfWidth: 0.716 * 2 * distanceFromSunFactor,
    speed: 1.18 * speedFactor,
    hasMagneticField: false,
  },
  {
    id: Planets.SOORIYAN,
    name: "Earth",
    size: 1 * planetSizeFactor,
    color: "lightblue",
    center: center,
    degree: 340,
    orbitHalfHeight: 1 * 2 * eccentricityFactor,
    orbitHalfWidth: 1 * 2 * distanceFromSunFactor,
    speed: 1 * speedFactor,
    hasMagneticField: true,
  },
  {
    id: Planets.CHANDRAN,
    name: "சந்",
    size: 0.5 * planetSizeFactor,
    color: "grey",
    center: center,
    degree: 0,//340,
    // orbitHalfHeight: 3.29 * 2 * eccentricityFactor,
    // orbitHalfWidth: 0.00257 * 2 * distanceFromSunFactor,
    orbitHalfHeight: 0.20 * 2 * eccentricityFactor,
    orbitHalfWidth: 0.20 * 2 * distanceFromSunFactor,
    speed: 0.241 * speedFactor
  },
  {
    id: Planets.CHEVVAI,
    name: "செ",
    size: 0.53 * planetSizeFactor,
    color: "red",
    center: center,
    degree: 180,
    orbitHalfHeight: 1.41 * 2 * eccentricityFactor,
    orbitHalfWidth: 1.64 * 2 * distanceFromSunFactor,
    speed: 0.88 * speedFactor,
    hasMagneticField: false,
  },
  {
    id: Planets.GURU,
    name: "வி",
    // size: 11 * planetSizeFactor,
    size: 8 * planetSizeFactor,
    color: "yellow",
    center: center,
    degree: 220,
    // orbitHalfHeight: 5.03 * 2 * eccentricityFactor,
    // orbitHalfWidth: 5.37 * 2 * distanceFromSunFactor,
    orbitHalfHeight: 2.03 * 2 * eccentricityFactor,
    orbitHalfWidth: 2.37 * 2 * distanceFromSunFactor,
    speed: 0.439 * speedFactor,
    hasMagneticField: true,
  },
  {
    id: Planets.SANI,
    name: "சனி",
    // size: 9 * planetSizeFactor,
    size: 8 * planetSizeFactor,
    color: "lightblack",
    center: center,
    degree: 90,
    // orbitHalfHeight: 9.20 * 2 * eccentricityFactor,
    // orbitHalfWidth: 9.96 * 2 * distanceFromSunFactor,
    orbitHalfHeight: 3.2 * 2 * eccentricityFactor,
    orbitHalfWidth: 3.96 * 2 * distanceFromSunFactor,
    speed: 0.325 * speedFactor,
    hasMagneticField: true,
  },
  {
    id: Planets.SOORIYAN,
    name: "Sun",
    size: 2 * planetSizeFactor,
    color: "#ecb511",
    center: { x: center.x, y: center.y },
    degree: 0,
    orbitHalfHeight: 0,
    orbitHalfWidth: 0,
    speed: 1 * speedFactor,
    hasMagneticField: true,
  },
];

let raasis = ["மேஷம்", "ரிஷபம்", "மிதுனம்", "கடகம்", "சிம்மம்", "கன்னி", "துலாம்", "விருச்சகம்", "தனுசு", "மகரம்", "கும்பம்", "மீனம்"];

export const loadBase = (canvas, planetLngMap) => {
  if (!canvas) return;
  // const canvas = document.getElementById("raasi");
  canvas.width = canvasWidth;
  canvas.height = screen.height - topOffset;

  let cxt = canvas.getContext("2d");
  // console.log("Clearing Reactangle");
  cxt.clearRect(0, 0, canvas.width, canvas.height);


  let earthCenter = getEarthCenter();
  planets
    .filter(planet => planet.id === "CHANDRAN")
    .forEach(p => p.center = earthCenter);


  planets
    // .filter(planet => planet.id === "CHANDRAN" || planet.name === "Earth" || planet.name === "Sun")
    .forEach((planet, i) => {
      let degree = planetLngMap[planet.id];
      // console.log("degree.degree :", degree);
      planet.degree = degree;
      drawEllipse(canvas, planet);
      drawCircleOnEllipse(canvas, planet);
      if (planet.name === "Earth") {
        drawLine(canvas, 0, planet.planetCenter, {strokeStyle:"#bbf0ff", text: false, lineLength: 40});
      }
      if (planet.name === "Sun") {
        drawLine(canvas, 0, planet.planetCenter,{strokeStyle:"grey", fillStyle: "grey", text: true, lineLength: (400)});
      }
    });


};

function drawEllipse(canvas, planet) {
  let a = planet.orbitHalfWidth;
  let b = planet.orbitHalfHeight;


  

  let center = planet.id === Planets.CHANDRAN ? getEarthCenter() : planet.center;

  let c = canvas;
  let cxt = c.getContext("2d");

  let xCentre = center.x;
  let yCentre = center.y;
  // console.log(`DEBUG Ellipse center : ${planet.id} -  ${xCentre},${yCentre} : degree: ${planet.degree} ${planet.id === Planets.CHANDRAN }` );
  // draw ellipse
  cxt.strokeStyle = "yellow";
  cxt.beginPath();

  for (let i = 0 * Math.PI; i < 2 * Math.PI; i += 0.01) {
    let xPos = xCentre - a * Math.cos(i);
    let yPos = yCentre + b * Math.sin(i);
    if (i === 0) {

      cxt.moveTo(xPos, yPos);
    } else {
      cxt.lineTo(xPos, yPos);
    }
  }
  cxt.lineWidth = orbitThickness;
  cxt.strokeStyle = "#232323";
  cxt.stroke();
  cxt.closePath();
}

function getEarthCenter() {
  let earthCenter =  planets
    .filter(planet => planet.name === "Earth")
    .map((planet, i) => {
      let a = planet.orbitHalfWidth;
      let b = planet.orbitHalfHeight;
      let center = planet.center;
      let xCentre = center.x;
      let yCentre = center.y;

      let thetaInDegree = planet.degree;
      thetaInDegree = -1 * thetaInDegree;
      let xPos = xCentre - a * Math.cos((Math.PI * thetaInDegree) / 180);
      let yPos = yCentre + b * Math.sin((Math.PI * thetaInDegree) / 180);

      planet.planetCenter = { x: xPos, y: yPos };
      return planet.planetCenter;
    });

    // console.log("earthCenter :", earthCenter[0]);
    return earthCenter[0];
}


function drawCircleOnEllipse(canvas, planet) {
  //a = 120, b = 70, center, radius=10, thetaInDegree=0,name) {

  let a = planet.orbitHalfWidth;
  let b = planet.orbitHalfHeight;
  let center = planet.center;
  let thetaInDegree = planet.degree;

  let c = canvas;
  let cxt = c.getContext("2d");

  let xCentre = center.x;
  let yCentre = center.y;

  // draw ellipse
  cxt.strokeStyle = "yellow";
  cxt.beginPath();

  thetaInDegree = -1 * thetaInDegree;
  let xPos = xCentre - a * Math.cos((Math.PI * thetaInDegree) / 180);
  let yPos = yCentre + b * Math.sin((Math.PI * thetaInDegree) / 180);

  planet.planetCenter = { x: xPos, y: yPos };
  // console.log(`DEBUG drawCircleO -------------------------------------------------------------------`);
  // console.log(`DEBUG drawCircleOnEllipse center : ${planet.id} -  ${xCentre},${yCentre} Radian:${xPos},${yPos} : degree: ${planet.degree}` );

  cxt.moveTo(xPos, yPos);

  drawCircle(canvas, { x: xPos, y: yPos }, planet.size, planet.color, planet.name);
  cxt.lineWidth = 0.5;
  cxt.strokeStyle = "#232323";
  cxt.stroke();
  cxt.closePath();
}

function drawCircle(canvas, center, radius, color, name) {
  const context = canvas.getContext("2d");
  let centerCorrection = radius / 2;
  const centerX = center.x;
  const centerY = center.y - centerCorrection;

  // let planet = planets.find((p) => p.name === name);
  // planet.center = { x: centerX, y: centerY + radius / Math.PI };

  // console.log(`DEBUG drawCircle center : ${centerX},${centerY}`);

  context.beginPath();
  context.arc(centerX, centerY + radius / Math.PI, radius, 0, 2 * Math.PI, false);
  context.fillStyle = color;
  context.fill();
  context.font = "20px Arial";
  context.fillStyle = "black";
  context.textAlign = "center";
  if(name !== 'Earth'){
    context.fillText(name, centerX + radius, centerY - radius / 2);
  }
  context.lineWidth = 0.25;
  context.strokeStyle = color;
  context.stroke();


}

function drawLine(canvas, thetaInDegree, center, style={strokeStyle:"black", fillStyle: "black", text:true, lineLength:canvas.width}) {
  let context = canvas.getContext("2d");

  let x1 = center.x;
  let y1 = center.y;

  context.beginPath();
  context.setLineDash([0, 0]);
  context.strokeStyle = style.strokeStyle;

  let raasiIndex = 0;
  for (let i = 0; i < 360; i = i + 30) {
    thetaInDegree = i;
    context.moveTo(x1, y1);
    let centerX = x1 + style.lineLength * Math.cos((Math.PI * thetaInDegree) / 180);
    let centerY = y1 + style.lineLength * Math.sin((Math.PI * thetaInDegree) / 180);
    context.lineTo(centerX, centerY);

    context.font = "20px Arial";
    context.fillStyle = style.fillStyle;
    context.textAlign = "center";

    if(style.text){
      let textDegree = thetaInDegree + 180 + 20 + 60 ;
      let textSpaceFromCenter = 360;
      context.fillText(raasis[raasiIndex], x1 + textSpaceFromCenter * Math.cos((Math.PI * textDegree) / 180), y1 + textSpaceFromCenter * Math.sin((Math.PI * textDegree) / 180));
  
    }
    
    context.stroke();
    raasiIndex++;
  }
}

loadBase();
