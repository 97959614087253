import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@material-ui/core";
import React, { Component } from "react";
import DateTimePicker from "../common/DateTimePicker";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { AddCircleRounded, Loupe } from "@material-ui/icons";
import { red } from "@material-ui/core/colors";
import moment from 'moment';
import * as _ from 'lodash';
import Alert from "@material-ui/lab/Alert";
import { Autocomplete } from "@material-ui/lab";

export default class LifeEvent extends Component {
  state = {
    createTs: new Date()
  };


  handleClose = () => {
    this.props.actions.resetRecentlyAddedMsg();
  };

  handleClick = (e) => {
    console.log("Type :", e);
    this.setState({ category: e});
  };

  setEvent = (e) => {
    console.log("Event :", e.target.value);
    this.setState({ event: e.target.value });
  };

  dateChanged = (e) => {
    console.log("Date :", e);
    this.setState({ createTs: e });
  };

  formatDate = dt => moment(Date.parse(dt)).toISOString();

  addLifeEvent = (e) => {
    console.log("life event :", this.props.user );
    let sample = {
      category: this.state.category,
      event: this.state.event,
      createTs: this.formatDate(this.state.createTs),
    };
    this.props.user &&
      this.props.actions &&
      this.props.actions.addLifeEvent(this.props.user.id, sample);
  };

  getDropDownLabel = (params) => {
    return "Select a Category";
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "baseline",
          fontSize: "x-small",
          flexWrap: "wrap",
          margin: "10px",
          padding: "10px"
        }}
      >
         {/* <FormControl
          style={{ minWidth: "200px",  marginRight: "20px" ,fontSize: "x-small",}}
        >
          <InputLabel style={{ marginLeft: "1px" }}>
            How do you feel?
          </InputLabel>
          <Select onClick={this.handleClick} variant="standard">
            {this.props.eventCategories &&
              this.props.eventCategories.length > 0 &&
              this.props.eventCategories.map((u) => (
                <MenuItem value={u.type} alignItems="center">
                  {u.type}
                </MenuItem>
              ))}
          </Select>
        </FormControl> */}

        <Autocomplete
              options={this.props.eventCategories}
              onChange={(event, newValue) => {
                this.handleClick(newValue);
              }}
              size={20}
              clearOnEscape
              getOptionLabel={(option) => option.type}
              style={{ width: 300, marginBottom: '10px', marginRight: '10px' }}
              renderInput={(params) =>
                <div><TextField {...params} label={"Select a Category"} variant="outlined" />
                </div>}
            />

        {/* <FormControl
          style={{ minWidth: "200px",  marginRight: "20px" ,fontSize: "x-small",}}
        >
          <InputLabel style={{ marginLeft: "1px" }}>
            Deprecated - Dont Use
          </InputLabel>
          <Select onClick={this.handleClick} variant="standard">
            {this.props.emotionTypes &&
              this.props.emotionTypes.length > 0 &&
              this.props.emotionTypes.map((u) => (
                <MenuItem value={u} alignItems="center">
                  {u}
                </MenuItem>
              ))}
          </Select>
        </FormControl> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            minWidth: "200px",
          }}
        >
          <DateTimePicker onChange={this.dateChanged} label={"Event time"} />
        </div>
        <TextField
          label="Event"
          placeholder="Tell me more"
          variant="standard"
          value={this.state.event}
          onChange={this.setEvent}
        />
        <Button variant="outlined" onClick={this.addLifeEvent} style={{marginLeft:"10px"}} color="primary">
          Add 
          {/* <AddCircleRounded fill style={{marginLeft: "5px", borderColor:"red", color:red[800]}} /> */}
        </Button>
        <Snackbar open={this.props.message.type !== ""} autoHideDuration={6000} onClose={this.handleClose}>
            <Alert onClose={this.handleClose} severity={this.props.message.type}>
              {this.props.message.message}
            </Alert>
          </Snackbar>
      </div>
    );
  }
}
