export default {
  GET_RAASI_CALCULATION: "GET_RAASI_CALCULATION",
  GET_USERS: "GET_USERS",
  GET_USER_PREDICTIONS: "GET_USER_PREDICTIONS",
  GET_USER_PREDICTIONS_OF_PP:"GET_USER_PREDICTIONS_OF_PP",
	GET_USER_PREDICTIONS_OF_PP_REFERENCES:"GET_USER_PREDICTIONS_OF_PP_REFERENCES",
  GET_USER_KOTCHARAM_PREDICTIONS_OF_PP: "GET_USER_KOTCHARAM_PREDICTIONS_OF_PP",
  GET_USER_KOTCHARAM_PREDICTIONS_OF_PP_FOR_PAST: "GET_USER_KOTCHARAM_PREDICTIONS_OF_PP_FOR_PAST",
  GET_USER_KOTCHARAM_PREDICTIONS_OF_PP_FOR_FUTURE: "GET_USER_KOTCHARAM_PREDICTIONS_OF_PP_FOR_FUTURE",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  RESET_CURRENT_USER: "RESET_CURRENT_USER",
  DELETE_USER_JAATHAGAN:"DELETE_USER_JAATHAGAN",
  GET_KOTCHARAM:"GET_KOTCHARAM",
  GET_KOTCHARAM_PLANET_STRENGTH:"GET_KOTCHARAM_PLANET_STRENGTH",
  GET_KOTCHARAM_HOUSE_STRENGTH: "GET_KOTCHARAM_HOUSE_STRENGTH",
  GET_ORAI:"GET_ORAI",
  GET_DASA_BY_DATE: "GET_DASA_BY_DATE",
  SET_RAASI_VS_KATTAM: "SET_RAASI_VS_KATTAM",
  GET_TRANSIT_DATES: "GET_TRANSIT_DATES",
  SET_SHOW_ONLY_YEARLY_PLANETS: "SET_SHOW_ONLY_YEARLY_PLANETS",
	SET_HOUSE_DETAIL: "SET_HOUSE_DETAIL",
	SET_TIME_ZONE: "SET_TIME_ZONE",
  GET_PAAVA_VIVARAM: "GET_PAAVA_VIVARAM",
  RETRIEVE_PAAVA_VIVARAM: "RETRIEVE_PAAVA_VIVARAM",
  RETRIEVE_PLANET_STRENGTH : "RETRIEVE_PLANET_STRENGTH",
  RETRIEVE_HOUSE_STRENGTH : "RETRIEVE_HOUSE_STRENGTH"
};
