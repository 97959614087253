import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as raasiAction from "../KattamAction";
import * as _ from "lodash";

import { withRouter } from "react-router-dom";
import PlanetsBondKattam from "./PlanetsBondKattam";

export class PlanetsBondContainer extends React.Component {
  propTypes = {
    data: PropTypes.data,
  };

  render() {
    return (
      <div>
        {this.props.user && this.props.raasiBand && <PlanetsBondKattam data={this.props.data} profile={this.props.user} raasiBand={this.props.raasiBand} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let isRaasi = !_.get(state.raasi, "switcher.checked")
  return {
    user: _.get(state.raasi, "currentUser"),
    data: isRaasi? _.get(state.raasi, "sample.data", {}) : _.get(state.raasi, "kotcharam", {}),
    raasiBand: isRaasi? _.get(state.raasi, "sample.data.raasiBand", {}) : _.get(state.raasi, "kotcharam.raasiBand", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(raasiAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlanetsBondContainer));
