import UserActionConstants from "./UserActionType";

export const postUserJathgan = (user) => {
  return (dispatch) =>
    dispatch({
      type: UserActionConstants.POST_USER_JAATHAGAN,
      user: user,
    });
};
export const setMessage = (msg) => {
  return (dispatch) =>
    dispatch({
      type: UserActionConstants.POST_USER_JAATHAGAN_MESSAGE,
      data: msg,
    });
};


export const resetPostUserJathganMessage = () => {
  return (dispatch) =>
    dispatch({
      type: UserActionConstants.POST_USER_JAATHAGAN_RESET
    });
};

export const contactUs = (info) => {
  return (dispatch) =>
    dispatch({
      type: UserActionConstants.CONTACT_US,
      data: info,
    });
};
