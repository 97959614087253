import { makeStyles, Switch } from '@material-ui/core';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { switchRaasiVsKotcharam } from '../Kattam/KattamAction';
import KattamActionType from '../Kattam/KattamActionType';



const useStyles = makeStyles(theme => ({
    toggle: {
        '& .Mui-checked': {
            color: '#ff6bfa'
        },
        '& .MuiSwitch-switchBase' : {
            color: '#ffffff'
        }
    }
}));

export default function Switcher(props) {

    const dispatch = useDispatch();
    const isChecked = useSelector((state) => get(state, "raasi.switcher.checked"))
    const handleSwitchChange = () => dispatch({ type: KattamActionType.SET_RAASI_VS_KATTAM });
    const classes = useStyles(); 



    return <div>
        ராசி<Switch
            className={classes.toggle}
            size="small" 
            checked={isChecked}
            onChange={handleSwitchChange}
            name="checked"
        />கோ
    </div>;
}
