import { Route, HashRouter, BrowserRouter, Switch, Router } from "react-router-dom";
import PredictionContainer from "./components/Predictions/PredictionContainer";
import PredictionPPContainer from "./components/Predictions/PredictionPPContainer";

import React, { Component } from "react";
import KattamContainer from "./components/Kattam/KattamContainer";
import HomePage from "./HomePage";
import CanvasContainer from "./SolarSystem/CanvasContainer";
import UserContainer from "./components/Users/UserContainer";
import LifeEventContainer from "./components/LifeEvent/LifeEventContainer";
import SearchContainer from "./components/Search/SearchContainer";
import LoginContainer from "./components/Login/LoginContainer";
import ContactUs from "./components/Users/ContactUs";
import UserListContainer from "./components/Users/UserList/UserListContainer";
import GenericAstroRulesContainer from './components/Predictions/GenericAstroRules/GenericAstroRulesContainer'
let KattamContainerWrapper = () => (
  <HomePage>
    <KattamContainer />
  </HomePage>
);
let PredictionContainerWrapper = () => (
  <HomePage>
    <PredictionContainer />
  </HomePage>
);
let PredictionPPContainerWrapper = () => (
  <HomePage>
    <PredictionPPContainer />
  </HomePage>
);
let UserContainerWrapper = () => (
  <HomePage>
    <UserContainer />
  </HomePage>
);

let LifeEventContainerWrapper = () => (
  <HomePage>
    <LifeEventContainer />
  </HomePage>
);

let SearchContainerWrapper = () => (
  <HomePage>
    <SearchContainer />
  </HomePage>
);

let GenericAstroRulesContainerWrapper = () => (
  <HomePage>
    <GenericAstroRulesContainer />
  </HomePage>
)

let ContactUsWrapper = () => <HomePage><ContactUs /></HomePage>

let UserListContainerWrapper = () => (
  <HomePage>
    <UserListContainer />
  </HomePage>
);
export default class AppRouter extends Component {
  render() {
    console.log("this.props.location :", this.props.location);
    return (
      <BrowserRouter>
        <Route path="/" exact component={LoginContainer} />
        <Route path="/kattam" exact component={KattamContainerWrapper} />
        <Route path="/ellipse" exact component={CanvasContainer} />
        <Route path="/raasi" exact component={KattamContainerWrapper} />
        <Route path="/search" exact component={SearchContainerWrapper} />
        <Route path="/generic-astro-rules" exact component={GenericAstroRulesContainerWrapper} />
        
        <Route
          path="/predictions"
          exact
          component={PredictionContainerWrapper}
        />
        <Route
          path="/predictions-pp"
          exact
          component={PredictionPPContainerWrapper}
        />
        <Route path="/add-user" exact component={UserContainerWrapper} />
        <Route path="/users" exact component={UserListContainerWrapper} />
        <Route path="/contactus" exact component={ContactUsWrapper} />
        <Route path="/life-events" exact component={LifeEventContainerWrapper} />
      </BrowserRouter>
    );
  }
}
