import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as _ from "lodash";
import { withRouter } from "react-router-dom";
import TransitDates from "./TransitDates";
import * as  kattamAction from '../KattamAction';

class TransitDatesContainer extends Component {

    render() {
        return <div style={{ margin: "5px", minWidth: '280px', minHeight: "265px",maxHeight: "440px"}}>
            {!this.props.loading && this.props.transitDates.length > 0
                && <TransitDates
                    actions={this.props.actions}
                    transitDates={this.props.transitDates}
                    showOnlyYearlyPlanets={this.props.showOnlyYearlyPlanets}
                    userId={this.props.userId}
                />}
            {this.props.loading && <div style={{ minWidth: '250px', height: "280px"}} class="animate-charcter">Loading...</div>}
        </div>;
    }
}

const mapStateToProps = (state) => {
    return {
        userId: _.get(state.raasi, "currentUser.id"),
        transitDates: _.get(state.raasi, "transitDates", []),
        current: _.get(state.dasa, "current", {}),
        loading: _.get(state.raasi, "transitLoading", {}),
        jaadhagam: _.get(state.raasi, "sample.data", {}),
        showOnlyYearlyPlanets: _.get(state.raasi, "showOnlyYearlyPlanets", true),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(kattamAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TransitDatesContainer));
