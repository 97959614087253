import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Card, CardContent, CardHeader, Chip, colors, List, ListItemText, Typography } from "@material-ui/core";
import * as _ from 'lodash';
import Planets from "../../common/Planets";
import { getColor, getForeColor, getPlanetShortName } from "../../DasaBhukthi/DasaUtil";
import { some } from "lodash";

export default class KarahaVivaramKattam extends Component {
  state = {};

  styles = {
    container: {
      // maxWidth: "600px",
      // minWidth: "250px",
      padding: "5px",
      backgroundColor: "#FFF",
      border: '1px solid blue'
    }

  };

  filterByRaasiName = (raasiName) => {
    let filteredRaasi = this.props.raasiBand && this.props.raasiBand.length && this.props.raasiBand.filter((s) => s.raasi === raasiName);
    filteredRaasi = filteredRaasi ? filteredRaasi[0] : {};
    let influencerNature = this.getInfluencerNatureByHouseNo(filteredRaasi.houseNo);
    filteredRaasi = { ...filteredRaasi, desc: influencerNature };
    // console.log("filteredRaasi :", filteredRaasi);
    return filteredRaasi;
  };

  filterRaasi = () => {
    const raasiBand = this.props.data.raasiBand;
    raasiBand.forEach((r) => {
      console.log("Raasi: ", r.raasi);
    });
  };

  getGunam = () => {
    let filteredRaasi = this.props.data && this.props.data.length && this.props.data.filter((s) => s.lagnam);
    return filteredRaasi && filteredRaasi.length ? filteredRaasi[0].gunam : "";
  };

  getInfluencerNatureByHouseNo = houseNo => {
    let houseNatures = this.props.paavaVivarams.filter(p => p.influencer === houseNo + "" || (p.houses && p.houses.includes(houseNo + "")))
      ;
    // console.log("houseNatures :", houseNatures);
    return houseNatures;
  }

  getInfluencerNatureByPlanet = planet => {
    let planetNature = this.props.paavaVivarams.filter(p => p.influencer.toLowerCase() === planet.toLowerCase() || (p.planets && some(p.planets, p => p.toLowerCase() === planet.toLowerCase())));
    // console.log("planetNature :", planetNature);
    return planetNature;
  }

  setDescription = (d, houseNo) => {
    let f = this.getInfluencerNatureByHouseNo(houseNo);
    // console.log("f :", f);
    this.props.actions.setHouseDetails(f, houseNo);
  }

  categorizeAndOrder = array => {
    let result = array.reduce((r, a, i, arr) => {
      r[a.category] = r[a.category] || [];
      r[a.category].push(a);
      return r;
    }, Object.create(null));

    let chunkedResult = [];
    Object.keys(result).forEach(k => {
      _.chunk(result[k], 4).forEach(part => chunkedResult.push(k, part));
    });
    return _.chunk(chunkedResult, 2);
  }

  setHouseDesc = h => {
    let array = this.getInfluencerNatureByHouseNo(h);
    let ordered = this.categorizeAndOrder(array)

    this.setState({ "first": this.getFirstPart(this.getInfluencerNatureByHouseNo(h)) });
    // this.setState({ "last": this.getLastPart(this.getInfluencerNatureByHouseNo(h)) });
    this.setState({ "category": ordered });
    this.setState({ "planet": `பாவம்: ${h}` });
  }




  setPlanetDesc = p => {
    // console.log("p ", p);
    let array = this.getInfluencerNatureByPlanet(p);
    let ordered = this.categorizeAndOrder(array)
    this.setState({ "first": this.getFirstPart(this.getInfluencerNatureByPlanet(p)) });
    // this.setState({ "last": this.getLastPart(this.getInfluencerNatureByPlanet(p)) });
    this.setState({ "category": ordered });
    this.setState({ "planet": p });

  }

  getCatColor = c => {
    let catColors = {
      "Body": colors.teal[200],
      "Occupation": colors.amber[200],
      "Misc": colors.blue[200],
      "Skill": colors.brown[200],
      "Social Status": colors.deepPurple[200],
      "People": colors.indigo[200],
      "Actions": colors.lightBlue[200],
      "Relationship": colors.cyan[200],
      "Cosmetics": colors.green[200],
      "Lifetime": colors.lightGreen[200],
      "Jewels": colors.lime[200],
      "Food/Grains": colors.yellow[200],
      "Natural Calamity": colors.amber[200],
      "God": colors.orange[200],
      "Finance": colors.deepOrange[200],
      "NA": colors.grey[200],
      "Animal": colors.brown[200],
      "Fight": colors.blueGrey[200],
      "Color": colors.pink[200],
      "Bad Effects": colors.red[200],
      "Asset": colors.purple[200],
      "Five Elements": colors.teal[200],
      "Accident": colors.red[600],
      "Weather": colors.yellow[300]
    };

    return catColors[c] ? catColors[c] : colors.red[300];
  }
  getAllPart = ar => {
    return ar.map(a => a.ta);
  }

  getFirstPart = ar => {
    return ar.slice(0, 7).map(a => a.ta).join(", ");
  }

  getLastPart = ar => {
    return ar.slice(7, ar.length).map(a => a.ta).join(",");
  }

  render() {
    if (!this.props.raasiBand) {
      return;
    }

    return (
      <div style={this.styles.container}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            {Object.values(Planets).map(p =>
              <Chip
                label={<div><FormattedMessage id={getPlanetShortName(p)} /></div>}
                style={{ margin: '4px', width: '50px', backgroundColor: getColor(p), color: getForeColor(p), border: '1px solid grey' }}
                onClick={() => this.setPlanetDesc(p)}
              />
            )}
          </div>
          <div>
            {Object.values([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]).map(p =>
              <Chip
                label={<div><FormattedMessage id={p} /></div>}
                style={{ margin: '4px', width: '50px', backgroundColor: "white", color: getForeColor(p), border: '1px solid grey' }}
                onClick={() => this.setHouseDesc(p)}
              />
            )}
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {this.state.planet && <Typography style={{ margin: '10px', fontWeight: "bold" }}><FormattedMessage id={this.state.planet} /></Typography>}
            <Typography style={{ margin: '10px' }}>{this.state.first}</Typography>
          </div>
        </div>


        <div style={{ display: "flex", flexDirection: "row", margin: "10px", flexWrap: "wrap" }}>
          {
            this.state.category && this.state.category.map((e) =>
              <Box style={{ maxWidth: 300 }} >
                <Card variant="outlined" style={{ fontSize: "x-small", margin: '5px' }}  >
                  <CardHeader subheader={e[0]}
                    style={{ display: "flex", justifyContent: "center", alignContent: "center", fontSize: "small", fontWeight: '400', backgroundColor: this.getCatColor(e[0]), padding: '5px' }} />
                  <CardContent style={{ margin: '5px', marginTop: '0px', padding: '0px' }} >
                    <List disablePadding>
                      {this.getAllPart(e[1]).map((t, i) => <ListItemText typeof="span"><span style={{ marginRight: '2px' }}>{i + 1}.</span>{t}</ListItemText>)}
                    </List>
                  </CardContent>
                </Card>
              </Box>
            )}
        </div>

      </div>
    );
  }
}
