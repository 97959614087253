// eslint-disable-next-line import/no-anonymous-default-export
export default {
  SEARCH_BY_PLANETS_ON_HOUSE: "SEARCH_BY_PLANETS_ON_HOUSE",
  SEARCH_BY_PLANET_BOND_WITH_RAASI: "SEARCH_BY_PLANET_BOND_WITH_RAASI",
  SEARCH_BY_LORD_IS_PLANET: "SEARCH_BY_LORD_IS_PLANET",
  SEARCH_BY_LORD_ON_HOUSE: "SEARCH_BY_LORD_ON_HOUSE",
  SEARCH_BY_RAASI_HOUSENO: "SEARCH_BY_RAASI_HOUSENO",
  SEARCH_BY_LORD_WITH_BOND: "SEARCH_BY_LORD_WITH_BOND",
  SEARCH_BY_DASA_PUTTHI: "SEARCH_BY_DASA_PUTTHI"
  };
  