import { Accordion, AccordionDetails, AccordionSummary, colors, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import * as _ from 'lodash';
import { lte } from "lodash";
class DasaBhukthi extends Component {

  state = {
    dasas: []

  }
  formatDate = d => d && moment(d).format('DD-MMM-YYYY');
  formatDateSimple = d => d && moment(d).format('DD-MMM-YY');

  getDasa = (d) => {
    return d && <Accordion TransitionProps={{ unmountOnExit: true }} expanded={true} >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{ backgroundColor: "lightskyblue" }}
        // onClick={this.handleDasaClick(d)}
      >{this.getDasaContent(d)}
      </AccordionSummary>
      <AccordionDetails style={{ padding: "1px" }}>
        <Wrapper>{d.putthis && d.putthis.map(d => this.getPutthi(d))}</Wrapper>
      </AccordionDetails>
    </Accordion>
  }

  getPutthi = (d) => {
    return <Accordion >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        style={{ backgroundColor: "lightgreen" }}
        // onClick={this.handlePutthiClick(d)}
      >
        {this.getDasaContent(d)}
      </AccordionSummary>
      <AccordionDetails style={{ padding: "1px" }}>
      <Wrapper>{d.andharam && d.andharam.map(d => this.getAndharam(d))}</Wrapper>
      </AccordionDetails>
    </Accordion>
  }

  getAndharam = (d) => {
    return <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        style={{ backgroundColor: "lightpink" }}
        // onClick={this.handleAndharamClick(d)}
      >
        {this.getDasaContent(d)}
      </AccordionSummary>
      <AccordionDetails style={{ padding: "1px" }}>
        <Wrapper>{d.sootchamam && d.sootchamam.map(d => this.getSootchamam(d))}</Wrapper>
      </AccordionDetails>
    </Accordion>
  }

  getSootchamam = (d) => {
    return <Accordion>
      <AccordionSummary style={{ backgroundColor: "gold" }}>
        {this.getDasaContent(d)}
      </AccordionSummary>
    </Accordion>
  }

  getPlanetShortName = (name) => name + "_SHORT";
  getBond = d => {
    if (d && d.flow === "NORMAL") {
      return "=";
    }
    if (d && d.flow === "OPEN") {
      return "+";
    }
    if (d && d.flow === "CLOSED") {
      return "x";
    }
  }

  getAge = (dobStr, date) => {
    let dob = moment(dobStr, 'YYYY-MM-DD hh:mm A'); 
    let age = moment(date, 'DD-MMM-YYYY');
    return age.diff(dob, 'years');
  }

  getDasaContent = d => <div style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-evenly", alignItems: "center", width: "100%" }}>
    <div style={{fontSize: 'x-small'}}>{this.getAge(this.props.jaadhagam.dob,this.formatDate(d.startDate))}-{this.getAge(this.props.jaadhagam.dob,this.formatDate(d.endDate))}</div>
    <div>{this.formatDateSimple(d.startDate)}</div>
    <div>
      <FormattedMessage id={this.getPlanetShortName(d.planet)} /><span>{this.getBond(d)}</span>
    </div>
    <div>
      <FormattedMessage id={d.dasaType} />
    </div>
    <div>{this.formatDateSimple(d.endDate)}</div>
  </div>;

  isAlreadyLoaded = (d, fn) => {
    let filtered = this.state.dasas.filter(fn).find(s => s.startDate === d.startDate && s.endDate === d.endDate);
    return !_.isEmpty(filtered);
  }

  handleDasaClick = d => () => {
    this.setState({putthis: d.putthis});
  }
  handlePutthiClick = (p) => () => {
    if (!this.isAlreadyLoaded(p, this.isPutthi)) {
      this.props.actions.getAndharam(this.props.userId, this.props.current.dasa, p);
      this.setState({dasas: [...this.state.dasas, p]});
    }
  }
  handleAndharamClick = (a) => () => {
    if (!this.isAlreadyLoaded(a, this.isAndharam)) {
      this.props.actions.getSootchamam(this.props.userId, this.props.current.dasa, this.props.current.putthi, a);
      this.setState({dasas: [...this.state.dasas, a]});
    }

  }

  isDasa = (d) => d.dasaType === "DASA";
  isPutthi = (d) => d.dasaType === "PUTTHI";
  isAndharam = (d) => d.dasaType === "ANDHARAM";
  isSootchamam = (d) => d.dasaType === "SOOTCHAMAM";

  render() {
    return (
      <Wrapper>
        {this.props.dasas && this.props.dasas.filter(d => d !== undefined).map(d => this.getDasa(d))}
      </Wrapper>
    );
  }
}

const Wrapper = (props) =>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      width: "380px",
      justifyContent: "flex-start"
    }}
  >
    {props.children}
  </div>



export default DasaBhukthi;
