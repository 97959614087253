import React, { Component } from "react";
import * as _ from 'lodash';
import { Card, CardContent, CardHeader, colors, LinearProgress, Typography } from "@material-ui/core";
import { LinearProgressWithLabel } from "./LinearProgressWithLabel";
import { JsonEditor } from "../../Predictions/JsonEditor";
import { FormattedMessage } from "react-intl";
import { beneficWeightage } from "../../common/Planets";
export default class HouseStrength extends Component {
  state = {};

  styles = {
    container: {
      maxWidth: "650px",
      minWidth: "270px",
      maxHeight: '665px',
      padding: "5px"
    },
    firstLastRow: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      backgroundColor: "#FFFACD",
    },
    middleRow: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      backgroundColor: "#FFFACD",
    },
    raasiInfo: {
      width: "60%",
      margin: ".25px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgb(255,107,250)",
      flexWrap: "wrap",
      flexDirection: "column"
    },
    kattamStyle: {
      width: "80%",
      padding: 0,
      margin: 0,
      backgroundColor: "#FFDE00",
      color: "#333",

      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  getCount = (e) => Object.entries(e).filter(o => o[0] != "total").filter(o => o[1] > 0).length;
  getTotalScores = () => Object.entries(this.props.data).filter(o => o[0] != "total").length;

  getPoints = () => {
    let res = Object.entries(this.props.data).map(e => {
      let obj = {};
      obj.planet = e[0];
      obj.score = this.getCount(e[1]);
      obj.total = e[1].total
      return obj;
    });
    // console.log("resH :", res);

    return res;
  }

  render() {
    let ps = this.getPoints();
    let totalScore = this.getTotalScores();
    return (
      <div style={this.styles.container}>
        <Card sx={{ minWidth: 275}} style={{backgroundColor : '#ebfeeb'}} >
        <div style={{display: 'flex', fontWeight: 'bold', fontSize: '20px', justifyContent: 'center', alignItems: 'center',margin: '5px', marginTop:'20px'}}><FormattedMessage id={"HOUSE_STRENGTH"} /></div>
          {/* <CardHeader title={<FormattedMessage id={"PLANET_STRENGTH"} />} style={{fontSize: '10px'}} /> */}
          <CardContent>
            {
              Object.entries(ps).map((e, i) => <div>
                <div style={{ display: 'flex' }}>
                  <div style={{minWidth : "80px"}}><Typography>பாவம்: {e[1].planet}</Typography></div>
                  <span style={{color: "gray", fontSize: "small", display: "flex",alignItems: "center"}}>Score:</span><span style={{marginLeft: "5px", display: "flex", fontSize: "small", alignItems: "center"}}>{`${ e[1].score}/ ${totalScore }`}</span>
                  {/* <Typography color="textSecondary" style={{marginLeft: '5px'}}>{`${Math.round(e[1],)}%`}</Typography> */}
                </div>
                {<LinearProgressWithLabel value={e[1].total}  color='primary' />}
                </div>)
                
            }
          </CardContent>
        </Card>
      </div>
    );
  }
}
