/* eslint-disable import/no-anonymous-default-export */
import { onFinish, onStart, onError } from "../../../ApiMiddleware";
import Type from "./OAuthActionType";
import * as LocalService from '../../../LocalStoreService';
import * as _ from 'lodash';
import LoginActionType from '../../Login/LoginActionType';
import KattamActionType from '../../Kattam/KattamActionType';


export const initialState = () => {
  let user = LocalService.getGoogleUser();
  let email =_.get(user,"emailId", false);
  return {
    google: user,
    isLoggedIn: email? true: false,
    loginStarted: false
  };
};

export default (state = initialState(), action) => {
  switch (action.type) {
    case onStart(Type.LOGIN_WITH_GOOGLE):
      LocalService.set("google-oauth", action.data);
      return {
        ...state,
        google: {oauth: action.data},
        loginStarted: true
      };
      case onFinish(Type.LOGIN_WITH_GOOGLE):
      return {
        ...state,
        google: {resp: action.data},
      };
      case onError(Type.LOGIN_WITH_GOOGLE):
      return {
        ...state,
        google: {error: action.data},
      };

      case Type.TOKEN_FOR_GOOGLE:
      return {
        ...state,
        loginStarted: true
      };
      

      case onFinish(Type.TOKEN_FOR_GOOGLE):
        LocalService.setGoogleUser(action.data);
      return {
        ...state,
        google: {user: action.data},
        isLoggedIn: true,
      };
      case onError(Type.TOKEN_FOR_GOOGLE):
      return {
        ...state,
        google: {error: action.data},
        isLoggedIn: false,
      };
      case LoginActionType.RESET_AUTHENTICATED_USER:
        LocalService.deleteAll();
      return {
        ...state,
        isLoggedIn: false,
        google: {}
      };

      case onStart(KattamActionType.GET_USERS):
      return {
        ...state,
        loginStarted: false
      };
      
      

    default:
      return state;
  }
};
