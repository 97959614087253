import React from "react";
import { render } from "react-dom";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import { Button } from "@material-ui/core";
import { Close, Save } from "@material-ui/icons";

export class JsonEditor extends React.Component {
    state = {
        val: ""
    };

    onChange = v => {
        console.log("v :", v);;
        this.setState({ val: v });
    }

    saveRule = () => {
        // setOpen(true);
        console.log("Saving rule", JSON.parse(this.state.val));
        this.props.actions.setRuleDialogOpen(false);
        this.props.actions.saveAstroRule(JSON.parse(this.state.val));
    };

    handleClose = () => {
        this.props.actions.setRuleDialogOpen(false);
    };

    render() {
        return <div>
            <div style={{ display: 'flex', justifyContent: 'space-around', margin: "10px" }}>
                <Button variant="contained" startIcon={<Close />} onClick={this.handleClose} color="secondary">
                    Cancel
                </Button>
                <Button variant="contained" startIcon={<Save />} color="primary" size="medium" onClick={this.saveRule}>
                    Save
                </Button>
            </div>
            <div style={{ width: '95%', height: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center' , margin: "20px"}}>
            <AceEditor
                style={{ width: '100%', height: '600px', maxHeight: '800px', minHeight: '500px'}}
                mode="json"
                theme="github"
                fontSize={15}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                onChange={this.onChange}
                editorProps={{ $blockScrolling: true}}
                onLoad={() => this.setState({ val: JSON.stringify(this.props.rule, null, ' ') })}
                value={this.state.val}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 2,
                    enableEmmet: true

                }}
            />
            </div>
        </div>

    }
}