import { get, post } from "../../httpService";

export const searchByPlanetsOnHouse = criteria => post("/jaadhagans/search/planets/on-house", criteria);
export const searchByPlanetBondWithRaasi = criteria => post("/jaadhagans/search/planets/bond/with-raasi", criteria);

export const searchByLordIsPlanet = criteria => post("/jaadhagans/search/lord/is-planet", criteria);

export const searchByLordWithBond = criteria => post("/jaadhagans/search/lord/bond/with-raasi", criteria);
export const searchByLordOnHouse = criteria => post("/jaadhagans/search/lord/on-house", criteria);
export const searchByRaasiOnHouse = criteria => post("/jaadhagans/search/raasi/on-house", criteria);

export const searchByDasaPutthi = criteria => post("/jaadhagans/search/dasa/putthi", criteria);
