import SearchActionType from "./SearchActionType";

export const searchByPlanetsOnHouse = (criteria) => {
  return (dispatch) =>
    dispatch({
      type: SearchActionType.SEARCH_BY_PLANETS_ON_HOUSE,
      criteria: criteria
    });
};

export const searchByPlanetBondWithRaasi = (criteria) => {
  return (dispatch) =>
    dispatch({
      type: SearchActionType.SEARCH_BY_PLANET_BOND_WITH_RAASI,
      criteria: criteria
    });
};


export const searchByLordIsPlanet = (criteria) => {
  return (dispatch) =>
    dispatch({
      type: SearchActionType.SEARCH_BY_LORD_IS_PLANET,
      criteria: criteria
    });
};

export const searchByLordWithBond = (criteria) => {
  return (dispatch) =>
    dispatch({
      type: SearchActionType.SEARCH_BY_LORD_WITH_BOND,
      criteria: criteria
    });
};

export const searchByDasaPutthi = (criteria) => {
  return (dispatch) =>
    dispatch({
      type: SearchActionType.SEARCH_BY_DASA_PUTTHI,
      criteria: criteria
    });
};


export const searchByRaasiHouseNo = (criteria) => {
  return (dispatch) =>
    dispatch({
      type: SearchActionType.SEARCH_BY_RAASI_HOUSENO,
      criteria: criteria
    });
};



export const searchByLordOnHouse = (criteria) => {
  return (dispatch) =>
    dispatch({
      type: SearchActionType.SEARCH_BY_LORD_ON_HOUSE,
      criteria: criteria
    });
};




