/* eslint-disable import/no-anonymous-default-export */
import { onFinish, onStart } from "../../ApiMiddleware";
import LifeEventActionType from "./LifeEventActionType";

export const initialState = () => {
  return {
    emotions: [],
    events: [],
    loading: false,
    recentlyAdded: { message: "", type: "" },
  };
};

export default (state = initialState(), action) => {
  switch (action.type) {
    case onStart(LifeEventActionType.ADD_LIFE_EVENT) || LifeEventActionType.RESET_RECENTLY_ADDED || LifeEventActionType.DELETE_LIFE_EVENT:
      return {
        ...state,
        recentlyAdded: { message: "", type: "" },
      };
    case onFinish(LifeEventActionType.ADD_LIFE_EVENT):
      return {
        ...state,
        recentlyAdded: { message: "Added Successfully", type: "info" },
        events: [action.data, ...state.events]
      };

    case onFinish(LifeEventActionType.DELETE_LIFE_EVENT):
      return {
        ...state,
        recentlyAdded: { message: "Deleted Successfully", type: "info" },
        events: [...state.events, action.data]
      };

    case LifeEventActionType.RESET_RECENTLY_ADDED:
      return {
        ...state,
        recentlyAdded: { message: "", type: "" },
      };
    case onFinish(LifeEventActionType.GET_EMOTION_TYPES):
      return {
        ...state,
        emotions: action.data
      };
      case onFinish(LifeEventActionType.GET_EVENT_CATEGORIES):
        return {
          ...state,
          eventCategories: action.data
        };
      

    case onStart(LifeEventActionType.GET_LIFE_EVENTS):
      return {
        ...state,
        loading: true
      };
    case onFinish(LifeEventActionType.GET_LIFE_EVENTS):
      return {
        ...state,
        events: action.data,
        loading: false
      };
    default:
      return state;
  }
};
