import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./SearchAction";
import * as kattamActions from '../Kattam/KattamAction';
import Search from "./Search";
import SearchLordIsXPlanet from "./SearchLordIsXPlanet";
import SearchLordWithBond from "./SearchLordWithBond";
import SearchLagnam from './SearchLagnam';
import SearchLordOnNthHouse from "./SearchLordOnNthHouse";
import SearchDasaPutthi from './SearchDasaPutthi';
import { Divider, InputLabel } from "@material-ui/core";
import KattamContainer from "../Kattam/KattamContainer";
import SearchPlanetBondWithRaasi from "./SearchPlanetBondWithRaasi";

class SearchContainer extends React.Component {
  render() {
    return (
      <div>
        <Search actions={this.props.actions}  kattamActions={this.props.kattamActions}/>
        <SearchPlanetBondWithRaasi  actions={this.props.actions}  kattamActions={this.props.kattamActions}/>
        <SearchLagnam actions={this.props.actions}  kattamActions={this.props.kattamActions}/>
        <SearchLordIsXPlanet actions={this.props.actions} kattamActions={this.props.kattamActions}/>
        <SearchLordWithBond  actions={this.props.actions}  kattamActions={this.props.kattamActions}/>
        <SearchLordOnNthHouse actions={this.props.actions}  kattamActions={this.props.kattamActions}/>

        <SearchDasaPutthi actions={this.props.actions}  kattamActions={this.props.kattamActions}/>
        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
        <InputLabel style={{marginLeft: "15px", color: "limegreen"}}>No. of matches: {this.props.searchCount}</InputLabel>
        <KattamContainer />
      </div>
    );
  }
}

SearchContainer.propTypes = {};

function mapStateToProps(state, ownProps) {
  return {
      searchCount: state.search.jaadhagans && state.search.jaadhagans.length? state.search.jaadhagans.length: 0
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    kattamActions: bindActionCreators(kattamActions, dispatch),
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer);
