import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from "@material-ui/core";
import _ from 'lodash';

class PredictionPPContainer extends Component {
 
  getUser = () => {
    return this.props.user.name;
  };

  goBack = () => this.props.history.goBack();

  render() {
    console.log("predictions: ", this.props.predictions);
    // if (_.isEmpty(this.props.predictions)) {
    //   return <div></div>;
    // } else {
    //   console.log("Predictions: ", this.props.predictions[0].predictions);
    // }
    return (
      <div>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          about="Go Back"
          onClick={this.goBack}
        >
          <ArrowBackIcon />
        </IconButton>
        <h3 style={{ display: "flex", justifyContent: "center", marginTop: "-15px" }}>{this.getUser()}</h3>
        <div style={{ margin: "10px" }}>PP Predictions</div>
        <div
          style={{
            border: "1px solid green",
            display: "flex",
            flexDirection: "column",
            padding: "10px",
            margin: "10px",
          }}
        >
          {_.isEmpty(this.props.predictions) && <div>No Data available</div> }
          {this.props.predictions.map((p, index) => {
            let rule = {...p, predictions:[]};
            return (
              <div
                style={{
                  margin: "5px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                {p.positivity && <div style={{ color: "green", fontSize: "medium" }}>{p.reference}</div>}
                {!p.positivity && <div style={{ color: "red", fontSize: "medium" }}>{p.reference} / {p.tags} </div>}
                <div style={{ color: "grey", fontSize: "x-small" }}>{JSON.stringify(rule)}</div>
                <div style={{ color: "black", fontSize: "large", marginLeft: "30px", display:"flex",flexDirection:"column" }}>
                  {
                    p.predictions.map(pd => <div>{pd}</div>)
                  }
                  </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    predictions: _.get(state.raasi, "predictionsOfPP", []),
    user: state.raasi.currentUser ? state.raasi.currentUser : {}
  };
};

//   const mapDispatchToProps = (dispatch) => {
//     return {
//       actions: bindActionCreators(raasiAction, dispatch),
//     };
//   };

export default connect(mapStateToProps, null)(withRouter(PredictionPPContainer));
