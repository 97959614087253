/* eslint-disable import/no-anonymous-default-export */
import { onFinish, onError, onStart } from "../../ApiMiddleware";
import LoginActionType from "../Login/LoginActionType";
import UserActionType from "./UserActionType";

export const initialState = () => {
  return {
    recentlyAddedJaadhagamMessage: {message:"", type:""},
    // isLoggedIn: false
    addJaadhagamLabel: "Add User"
  };
};

export default (state = initialState(), action) => {
  switch (action.type) {
    case UserActionType.POST_USER_JAATHAGAN_RESET:
      return {
        ...state,
        recentlyAddedJaadhagamMessage: {message:"", type:""}
      };
      case UserActionType.POST_USER_JAATHAGAN_MESSAGE:
      return {
        ...state,
        recentlyAddedJaadhagamMessage: action.data
      };

      case onStart(UserActionType.POST_USER_JAATHAGAN):
      return {
        ...state,
        addJaadhagamLabel: "Creating.."
      };
    case onFinish(UserActionType.POST_USER_JAATHAGAN):
      return {
        ...state,
        // recentlyAddedJaadhagam: action.data,
        recentlyAddedJaadhagamMessage: {message: "Added successfully", type: "info"},
        addJaadhagamLabel: "Add User"
      };
    case onError(UserActionType.POST_USER_JAATHAGAN):
      return {
        ...state,
        recentlyAddedJaadhagamMessage: {...action.data, type: "error"},
        addJaadhagamLabel: "Add User"
      };
    

      
    // case LoginActionType.SET_AUTHENTICATED_USER:
    //   return {
    //     ...state,
    //     userInfo: action.data,
    //   };
    //   case onFinish(LoginActionType.SET_AUTHENTICATED_USER):
    //   return {
    //     ...state,
    //     userInfo: action.data,
    //     isLoggedIn: true
    //   };
    //   case onError(LoginActionType.SET_AUTHENTICATED_USER):
    //   return {
    //     ...state,
    //     userInfo: action.data,
    //     isLoggedIn: false
    //   };
    //   case LoginActionType.RESET_AUTHENTICATED_USER:
    //   return {
    //     ...state,
    //     isLoggedIn: false
    //   };
    default:
      return state;
  }
};
