import { createStore, combineReducers, applyMiddleware } from "redux";
import raasi from "./components/Kattam/KattamReducer";
import apiMiddleware from "./ApiMiddleware";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import user from "./components/Users/UserReducer";
import dasa from "./components/DasaBhukthi/DasaBhukthiReducer";
import events from './components/LifeEvent/LifeEventReducer';
import search from './components/Search/SearchReducer';
import oauth from './components/Authorization/OAuth/OAuthReducer';
import predictions from './components/Predictions/PredictionReducer';

const middlewares = [apiMiddleware, thunk];
const composeDevTools = composeWithDevTools(applyMiddleware(...middlewares));

const rootReducer = combineReducers({
  raasi,
  user,
  dasa,
  events,
  search,
  oauth,
  predictions
});

const initStore = (initialState) => {
  return createStore(rootReducer, initialState, composeDevTools);
};

export default initStore;
