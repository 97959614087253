import { serviceMap } from './ServiceMap';
import * as LocalService from "./LocalStoreService";
import LoginActionType from './components/Login/LoginActionType';

const hasError = resp => {
    // console.log("resp :", resp);
    return resp && !resp.ok;
}

const isUnauthorized = resp => {
    // console.log("resp isUnauthorize:", resp);
    return resp && resp.status === 401;
}

const toJson = text => text && text.length ? JSON.parse(text) : {}

export default  store => next => action => {
    next(action);
    let service = serviceMap.get(action.type);

    if (service) {
        let serviceOutput = service(action);
        store.dispatch({
            ...action,
            type: onStart(action.type)
        })
        return serviceOutput && serviceOutput.then(
            serviceResultData => {
                if(isUnauthorized(serviceResultData)){
                    serviceResultData.text().then(data => {
                        console.log("API isUnauthorized response :", data);
                        LocalService.deleteAll();
                        return store.dispatch({
                            ...action,
                            type: LoginActionType.RESET_AUTHENTICATED_USER,
                            data: {"message":"Unauthorized", type:"error"}
                        })
                    });
                }
                else if(hasError(serviceResultData)){
                    serviceResultData.text().then(data => {
                        console.log("API error response :", data);
                        return store.dispatch({
                            ...action,
                            type: onError(action.type),
                            data: toJson(data)
                        })
                    });
                }
                else if(serviceResultData && !serviceResultData.text && serviceResultData.url){
                    return store.dispatch({
                        ...action,
                        type: onFinish(action.type),
                        data: serviceResultData.url
                    });
                }
                else{
                    serviceResultData.text().then(data => {
                        // console.log("API response :", data);
                        return store.dispatch({
                            ...action,
                            type: onFinish(action.type),
                            data: toJson(data)
                        });
                    });
                }
                
                
            }
        ).catch(error => {
            console.log("Couldnt handle error :", error);
            store.dispatch({
                ...action,
                type: onError(action.type),
                data: error
            });
        });
    }
}
export const anyOnStart = type => type.substring(type.indexOf("_INITIATED"));
export const anyOnFinish = type => type.substring(type.indexOf("_FINISHED"));

export const INITIATED = "_INITIATED";
export const FINISHED = "_FINISHED";

export const onStart = type => type + "_INITIATED";
export const onFinish = type => type + "_FINISHED";
export const onError = type => type + "_ERROR";
