import { AppBar, Button, Dialog, DialogActions, Divider, IconButton, List, ListItem, ListItemText, Slide, Toolbar, Typography } from "@material-ui/core";
import { Close, Save } from "@material-ui/icons";
import React from "react";
import { JsonEditor } from "./JsonEditor";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  // const [open, setOpen] = React.useState(false);

  const handleRuleChange = r => props.actions.setRuleToEdit(r);

  return (
    <div>
      {/* <AppBar sx={{ position: 'relative' }} hidden={props.open} >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Edit AstroRule
          </Typography>
          <Button autoFocus color="inherit" onClick={saveRule}>
            Save
          </Button>
        </Toolbar>
      </AppBar> */}
      <Dialog
        open={props.open}
        fullScreen
        style={{margin: "50px"}}
        // onClose={handleClose}
        onEscapeKeyDown={() => props.actions.setRuleDialogOpen(false)}
        TransitionComponent={Transition}
      >
        <JsonEditor rule={props.rule} onChange={handleRuleChange} actions={props.actions}/>
       
      </Dialog>
    </div>
  );
}