import React, { Component } from "react";
import Raasi from "../Raasi/Raasi";
import RaasiName from "../KattamConstants";
import * as _ from 'lodash';
import moment from 'moment';
import { FormattedMessage } from "react-intl";
import { IconButton } from "@material-ui/core";
import { Event, FastForward, FastRewind, NavigateBefore, NavigateNext, SkipNext, SkipPrevious } from "@material-ui/icons";
import { datePickerDefaultProps } from "@material-ui/pickers/constants/prop-types";
import DatePicker from "../../common/DatePicker";
import { doBToMoment, formatDatePickerToDate, formatToKotcharam } from "../../common/DateUtil";
export default class Kotcharam extends Component {
  state = {};

  styles = {
    container: {
      maxWidth: "650px",
      minWidth: "350px",
      padding: "5px",
      backgroundColor: "#FFF",
    },
    firstLastRow: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      backgroundColor: "#FFFACD",
    },
    middleRow: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      backgroundColor: "#FFFACD",
    },
    raasiInfo: {
      width: "60%",
      margin: ".25px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgb(255,107,250)",
      flexWrap: "wrap",
      flexDirection: "column"
    },
    kattamStyle: {
      width: "80%",
      padding: 0,
      margin: 0,
      backgroundColor: "#FFDE00",
      color: "#333",

      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  filterByRaasiName = (raasiName) => {
    let filteredRaasi =
      this.props.raasiBand &&
      this.props.raasiBand.length &&
      this.props.raasiBand.filter((s) => s.raasi === raasiName);
    return filteredRaasi ? filteredRaasi[0] : {};
  };

  filterRaasi = () => {
    const raasiBand = this.props.data.raasiBand;
    raasiBand.forEach((r) => {
      console.log("Raasi: ", r.raasi);
    });
  };

  formatDt = dt => {
    // console.log("dt :", dt); // 2022-01-27 05:30 AM
    try {
        let formatted = moment(dt, "YYYY-MM-DD hh:mm a").format("dddd, MMM Do YYYY");
        // console.log("formatted :", formatted);
        return formatted === "Invalid date" ? this.props.data.dobStr : formatted;
    }
    catch (err) {
        return _.get(this.props, "data.dobStr", moment().format("dddd, MMM Do YYYY"));
    }
}

  getGunam = () => {
    let filteredRaasi =
      this.props.data &&
      this.props.data.length &&
      this.props.data.filter((s) => s.lagnam);
    return filteredRaasi && filteredRaasi.length ? filteredRaasi[0].gunam : "";
  };

  handlePreviousDay = () => {
    let date = doBToMoment(this.props.data.dob).subtract(1, "days");
    const reqDate = formatToKotcharam(date);
    console.log("handlePreviousDay : this.props.data.dob ;" + this.props.data.dob + " Formatted :" + moment(date, "YYYY-MM-DD hh:mm a").format('DD-MMM-YYYY hh:mm a'));
    this.props.actions.retrieveKotcharam(reqDate);
    this.props.actions.retrieveKotcharamPlanetStrength(reqDate);
    this.props.actions.retrieveKotcharamHouseStrength(reqDate);

    this.props.actions.retrieveOrai(reqDate);
    if(this.props.profile) this.props.actions.getDasaByDate(this.props.profile.id, reqDate);
  }

  handleNextDay = () => {
    let date = moment(this.props.data.dob, "YYYY-MM-DD hh:mm a").add(1, "days")
    const reqDate = formatToKotcharam(date);
    this.props.actions.retrieveKotcharam(reqDate);
    this.props.actions.retrieveKotcharamPlanetStrength(reqDate);
    this.props.actions.retrieveKotcharamHouseStrength(reqDate);
    this.props.actions.retrieveOrai(reqDate);
    if(this.props.profile) this.props.actions.getDasaByDate(this.props.profile.id, reqDate);

  }

  handleNextMonth = () => {
    let date = moment(this.props.data.dob, "YYYY-MM-DD hh:mm a").add(1, "months")
    const reqDate = formatToKotcharam(date);
    this.props.actions.retrieveKotcharam(reqDate);
    this.props.actions.retrieveKotcharamPlanetStrength(reqDate);
    this.props.actions.retrieveKotcharamHouseStrength(reqDate);
    this.props.actions.retrieveOrai(reqDate);
    if(this.props.profile) this.props.actions.getDasaByDate(this.props.profile.id, reqDate);
  }
  handlePreviousMonth = () => {
    let date = moment(this.props.data.dob, "YYYY-MM-DD hh:mm a").subtract(1, "months");
    const reqDate = formatToKotcharam(date);
    this.props.actions.retrieveKotcharam(reqDate);
    this.props.actions.retrieveKotcharamPlanetStrength(reqDate);
    this.props.actions.retrieveKotcharamHouseStrength(reqDate);
    this.props.actions.retrieveOrai(reqDate);
    if(this.props.profile) this.props.actions.getDasaByDate(this.props.profile.id, reqDate);
  }

  handleNextYear = () => {
    let date = moment(this.props.data.dob, "YYYY-MM-DD hh:mm a").add(1, "years");
    const reqDate = formatToKotcharam(date);
    this.props.actions.retrieveKotcharam(reqDate);
    this.props.actions.retrieveKotcharamPlanetStrength(reqDate);
    this.props.actions.retrieveKotcharamHouseStrength(reqDate);
    this.props.actions.retrieveOrai(reqDate);
    if(this.props.profile) this.props.actions.getDasaByDate(this.props.profile.id, reqDate);
  }
  handlePreviousYear = () => {
    let date = moment(this.props.data.dob, "YYYY-MM-DD hh:mm a").subtract(1, "years");
    const reqDate = formatToKotcharam(date);
    this.props.actions.retrieveKotcharam(reqDate);
    this.props.actions.retrieveKotcharamPlanetStrength(reqDate);
    this.props.actions.retrieveKotcharamHouseStrength(reqDate);
    this.props.actions.retrieveOrai(reqDate);
    if(this.props.profile) this.props.actions.getDasaByDate(this.props.profile.id, reqDate);
  }
  handleToday = () => {
    const reqDate = formatToKotcharam(moment());
    this.props.actions.retrieveKotcharam(reqDate);
    this.props.actions.retrieveKotcharamPlanetStrength(reqDate);
    this.props.actions.retrieveKotcharamHouseStrength(reqDate);
    this.props.actions.retrieveOrai(reqDate);
    if(this.props.profile) this.props.actions.getDasaByDate(this.props.profile.id, reqDate);
  }
  handledDateChange = (date) => {
    const reqDate = formatDatePickerToDate(date);
      this.props.actions.retrieveKotcharam(reqDate);
      this.props.actions.retrieveKotcharamPlanetStrength(reqDate);
      this.props.actions.retrieveKotcharamHouseStrength(reqDate);
      this.props.actions.retrieveOrai(reqDate);
      if(this.props.profile) this.props.actions.getDasaByDate(this.props.profile.id, reqDate);
  }

  getAddress = () => {
    // console.log("_.get(this.props,data.birthLocation) ;", _.get(this.props,"data.birthLocation"));
    return _.get(this.props, "data.birthLocation.address", "");
  };
  render() {
    if (_.isEmpty(this.props.raasiBand) || _.isEmpty(this.props.data)) {
      return <div></div>;
    }
    return (
      <div style={this.styles.container}>
        <div name="first-row" style={this.styles.firstLastRow}>
          <Raasi
            data={this.filterByRaasiName(RaasiName.MEENAM)}
            width="20%"
            height="80px"
            hideLagnam={false}
            hideHouseNo={false}
            hideSplHouse={true}
          />
          <Raasi
            key={RaasiName.MESAM}
            data={this.filterByRaasiName(RaasiName.MESAM)}
            width="30%"
            height="80px"
            hideLagnam={false}
            hideHouseNo={false}
            hideSplHouse={true}
          />
          <Raasi
            key={RaasiName.RISABAM}
            data={this.filterByRaasiName(RaasiName.RISABAM)}
            width="30%"
            height="80px"
            hideLagnam={false}
            hideHouseNo={false}
            hideSplHouse={true}
          />
          <Raasi
            key={RaasiName.MITHUNAM}
            data={this.filterByRaasiName(RaasiName.MITHUNAM)}
            width="20%"
            height="80px"
            hideLagnam={false}
            hideHouseNo={false}
            hideSplHouse={true}
          />
        </div>
        <div name="middle-1" style={this.styles.middleRow}>
          <Raasi
            key={RaasiName.KUMBAM}
            data={this.filterByRaasiName(RaasiName.KUMBAM)}
            width="20%"
            height="70px"
            hideLagnam={false}
            hideHouseNo={false}
            hideSplHouse={true}
          />
          <div style={this.styles.raasiInfo}><div><FormattedMessage id="KOTCHARAM" />  </div>
            {/* <div style={{ fontSize: "smaller" }}><DatePicker /></div> */}
            <div style={{margin: "-6px", display: "flex", flexFlow: "row", flexDirection: "row"}}>
            <IconButton onClick={this.handlePreviousDay}>
              <NavigateBefore />
            </IconButton>
            <div style={{ fontSize: "smaller", marginTop: "6%"}}><DatePicker onChange={this.handledDateChange}/></div>
            {/* <IconButton onClick={this.handleToday}>
              <Event />
            </IconButton> */}

            <IconButton  onClick={this.handleNextDay}>
              <NavigateNext />
            </IconButton>
            </div>
          </div>
          <Raasi
            key={RaasiName.KADAGAM}
            data={this.filterByRaasiName(RaasiName.KADAGAM)}
            width="20%"
            height="70px"
            hideLagnam={false}
            hideHouseNo={false}
            hideSplHouse={true}
          />
        </div>
        <div name="middle-12" style={this.styles.middleRow}>
          <Raasi
            key={RaasiName.MAKARAM}
            data={this.filterByRaasiName(RaasiName.MAKARAM)}
            width="20%"
            height="70px"
            hideLagnam={false}
            hideHouseNo={false}
            hideSplHouse={true}
          />
          <div style={{
            width: "60%",
            margin: ".15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgb(255,107,250)",
            flexWrap: "wrap",
            flexShrink: "true",
            flexDirection: "row"
          }}>
            {/* <div>Loading..</div> */}
            <IconButton  onClick={this.handlePreviousYear} style={{marginLeft: '-2px'}}>
              <SkipPrevious fontSize="small" />
            </IconButton>
            <IconButton  onClick={this.handlePreviousMonth} style={{marginLeft: '-2px'}}>
              <FastRewind fontSize="small" />
            </IconButton>
            {/* <IconButton onClick={this.handleToday} style={{marginLeft: '-2px'}}>
              <Event fontSize="small" />
            </IconButton> */}
            <IconButton  onClick={this.handleNextMonth} style={{marginLeft: '-2px'}}>
              <FastForward fontSize="small" />
            </IconButton>

            <IconButton  onClick={this.handleNextYear} >
              <SkipNext fontSize="small" style={{marginLeft: '-2px'}}/>
            </IconButton>
          </div>
          <Raasi
            key={RaasiName.SIMMAM}
            data={this.filterByRaasiName(RaasiName.SIMMAM)}
            width="20%"
            height="70px"
            hideLagnam={false}
            hideHouseNo={false}
            hideSplHouse={true}
          />
        </div>
        <div name="last-row" style={this.styles.firstLastRow}>
          <Raasi
            key={RaasiName.DHANUSU}
            data={this.filterByRaasiName(RaasiName.DHANUSU)}
            width="20%"
            height="80px"
            hideLagnam={false}
            hideHouseNo={false}
            hideSplHouse={true}
          />
          <Raasi
            key={RaasiName.VIRUCHAGAM}
            data={this.filterByRaasiName(RaasiName.VIRUCHAGAM)}
            width="30%"
            height="80px"
            hideLagnam={false}
            hideHouseNo={false}
            hideSplHouse={true}
          />
          <Raasi
            key={RaasiName.THULAM}
            data={this.filterByRaasiName(RaasiName.THULAM)}
            width="30%"
            height="80px"
            hideLagnam={false}
            hideHouseNo={false}
            hideSplHouse={true}
          />
          <Raasi
            data={this.filterByRaasiName(RaasiName.KANNI)}
            width="20%"
            height="80px"
            hideLagnam={false}
            hideHouseNo={false}
            hideSplHouse={true}
          />
        </div>
      </div>
    );
  }
}
