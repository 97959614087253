import Raasi from "./components/Kattam/KattamConstants";

export const ta = {
  PLANET_STRENGTH: "கிரகங்களின் பலம்",
  HOUSE_STRENGTH:"பாவ பலம்",
  NA: "NA",
  PAADHAM:"பாதம்",
  RAASI:"ராசி",
  KOTCHARAM: "கோட்சாரம்",
  RETRIEVE: "எடுக்கவும் ",
  MESAM: "மேஷம்",
  RISABAM: "ரிஷபம்",
  MITHUNAM: "மிதுனம்",
  KADAGAM: "கடகம்",
  SIMMAM: "சிம்மம்",
  KANNI: "கன்னி",
  THULAM: "துலாம்",
  VIRUCHAGAM: "விருச்சகம்",
  DHANUSU: "தனுசு",
  MAKARAM: "மகரம்",
  KUMBAM: "கும்பம்",
  MEENAM: "மீனம்",
  SOORIYAN: "சூரியன்",
  CHANDRAN: "சந்திரன்",
  PUDHAN: "புதன்",
  CHEVVAI: "செவ்வாய்",
  GURU: "வியாழன்",
  GURU_1: "குரு",
  SUKKIRAN: "சுக்கிரன்",
  SANI: "சனி",
  RAAGU: "ராகு",
  KEDHU: "கேது",
  MAANDHI: "மாந்தி",
  LAGNAM: "லக்கினம்",
  LAGNAM_ATHIPATH:"லக்கினாதிபதி",
	BHATHAKATHIPATHY:"பாதகாதிபதி",
	MARAKATHIPATHY:"மாரகாதிபதி",
  NATCHATHIRAM: "நட்சத்திரம்",
  DASA: "தசா",
  PUTTHI:"புத்தி",
  ANTHARAM:"அந்தரம்",
  ANDHARAM:"அந்தரம்",
  SOOTCHAMAM:"சூட்சமம்",
	RETROGRADE: "(வ)",
  SOORIYAN_SHORT: "சூ",
  CHANDRAN_SHORT: "சந்",
  PUDHAN_SHORT: "பு",
  CHEVVAI_SHORT: "செ",
  GURU_SHORT: "வி",
  GURU_1_SHORT: "குரு",
  SUKKIRAN_SHORT: "சு",
  SANI_SHORT: "சனி",
  RAAGU_SHORT: "ரா",
  KEDHU_SHORT: "கே",
  MAANDHI_SHORT: "மா",
  LAGNAM_SHORT: "ல",
  ASC_SHORT: " ",

  UCCHAM: "உச்சம்",
  NEESAM: "நீசம்",
  AATCHI: "ஆட்சி",
  NATPU: "நட்பு",
  PAGAI: "பகை",
  MOOLATHIRIKONAM: "மூலதிரிகோணம்",
  SAMAM: "சமம்",
  ENEMY: "பகை",
  NEECHAM: "நீச்சம்",
  PLANETS_BOND:"கூட்டுகிரக பந்தம்",

  ASC: " ",
  Aswini: "அசுவினி",
  Barani: "பரணி",
  Kaarthigai: "கார்த்திகை",
  Rohini: "ரோகினி",
  Mirugasireesam: "மிருகசிரீசம்",
  Thiruvaathirai: "திருவாதிரை",
  Punarpoosam: "புனர்பூசம்",
  Poosam: "பூசம்",
  Aayilyam: "ஆயில்யம்",
  Maham: "மகம்",
  Pooram: "பூரம்",
  Utthiram: "உத்திரம்",
  Astham: "அட்டம்",
  Chitthirai: "சித்திரை",
  Swathi: "சுவாதி",
  Visaakam: "விசாகம்",
  Anusam: "அனுசம்",
  Kettai: "கேட்டை",
  Moolam: "மூலம்",
  Pooraadam: "பூராடம்",
  Uthiraadam: "உத்திராடம்",
  Thiruvonam: "திருவோணம்",
  Avittam: "அவிட்டம்",
  Sadhayam: "சதயம்",
  Poorattathi: "பூரட்டாதி",
  Uthirattathi: "உத்திரட்டாதி",
  Revathi: "ரேவதி",

  Aswini_SHORT: "அசு",
  Barani_SHORT: "பர",
  Kaarthigai_SHORT: "கார்",
  Rohini_SHORT: "ரோ",
  Mirugasireesam_SHORT: "மிரு",
  Thiruvaathirai_SHORT: "திரு",
  Punarpoosam_SHORT: "புனர்",
  Poosam_SHORT: "பூசம்",
  Aayilyam_SHORT: "ஆ",
  Maham_SHORT: "மகம்",
  Pooram_SHORT: "பூரம்",
  Utthiram_SHORT: "உத்தி",
  Astham_SHORT: "அட்",
  Chitthirai_SHORT: "சித்",
  Swathi_SHORT: "சுவா",
  Visaakam_SHORT: "விசா",
  Anusam_SHORT: "அனு",
  Kettai_SHORT: "கே",
  Moolam_SHORT: "மூ",
  Pooraadam_SHORT: "பூம்",
  Uthiraadam_SHORT: "உம்",
  Thiruvonam_SHORT: "தி",
  Avittam_SHORT: "அவி",
  Sadhayam_SHORT: "சத",
  Poorattathi_SHORT: "பூர",
  Uthirattathi_SHORT: "உ",
  Revathi_SHORT: "ரே",
  1: "1",
  2 : "2",
  3: "3",
  4: "4",
  5: "5",
  6 : "6",
  7 : "7",
  8:"8",
  9:"9",
  10: "10",
  11: "11",
  12: "12"
};
export const en = {
  PLANET_STRENGTH: "Planet Strength",
  HOUSE_STRENGTH:"House Strength",
  NA: "NA",
  KOTCHARAM: "Kotcharam",
  RETRIEVE: "Get Data",
  MESAM: "MESAM",
  RISABAM: "RISABAM",
  MITHUNAM: "MITHUNAM",
  KADAGAM: "KADAGAM",
  SIMMAM: "SIMMAM",
  KANNI: "KANNI",
  THULAM: "THULAM",
  VIRUCHAGAM: "VIRUCHAGAM",
  DHANUSU: "DHANUSU",
  MAKARAM: "MAKARAM",
  KUMBAM: "KUMBAM",
  MEENAM: "MEENAM",
  SOORIYAN: "SOORIYAN",
  CHANDRAN: "CHANDRAN",
  PUDHAN: "PUTHAN",
  CHEVVAI: "CHEVVAI",
  GURU_NAME_2: "GURU_NAME_2",
  GURU: "GURU",
  SUKKIRAN: "SUKKIRAN",
  SANI: "SANI",
  RAAGU: "RAAGU",
  KEDHU: "KEDHU",
  MAANDHI: "MAANDHI",
  LAGNAM: "LAGNAM",
  LAGNAM_ATHIPATH:"LAGNAM_ATHIPATH",
  UCCHAM: "UCCHAM",
  NEESAM: "NEESAM",
  AATCHI: "AATCHI",
  NATPU: "NATPU",
  PAGAI: "PAGAI",
  MOOLATHIRIKONAM: "MOOLATHIRIKONAM",
  SAMAM: "SAMAM",
  ENEMY: "ENEMY",
  NEECHAM: "NEECHAM",
  PLANETS_BOND:"Multi-Planet Bond",

  ASC: " ",
  ASC_SHORT: " ",
  NATCHATHIRAM: "Stars",
  DASA: "Dasa",
  PUTTHI:"Bhukthi",
  ANTHARAM:"Andharam",
  ANDHARAM:"Andharam",
  SOOTCHAMAM:"Sootchamam",
	RETROGRADE: "(வ)",
  Aswini_SHORT: "அசு",
  Barani_SHORT: "பரணி",
  Kaarthigai_SHORT: "கார்",
  Rohini_SHORT: "ரோ",
  Mirugasireesam_SHORT: "மிரு",
  Thiruvaathirai_SHORT: "திருவா",
  Punarpoosam_SHORT: "புனர்பூ",
  Poosam_SHORT: "பூசம்",
  Aayilyam_SHORT: "ஆ",
  Maham_SHORT: "மகம்",
  Pooram_SHORT: "பூரம்",
  Utthiram_SHORT: "உத்தி",
  Astham_SHORT: "அத்தம்",
  Chitthirai_SHORT: "சித்தி",
  Swathi_SHORT: "சுவா",
  Visaakam_SHORT: "விசா",
  Anusam_SHORT: "அனு",
  Kettai_SHORT: "கே",
  Moolam_SHORT: "மூ",
  Pooraadam_SHORT: "பூடம்",
  Uthiraadam_SHORT: "உடம்",
  Thiruvonam_SHORT: "தி",
  Avittam_SHORT: "அவி",
  Sadhayam_SHORT: "சத",
  Poorattathi_SHORT: "பூரட்",
  Uthirattathi_SHORT: "உடாதி",
  Revathi_SHORT: "ரே",
  1: "1",
  2 : "2",
  3: "3",
  4: "4",
  5: "5",
  6 : "6",
  7 : "7",
  8:"8",
  9:"9",
  10: "10",
  11: "11",
  12: "12"
};
