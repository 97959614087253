import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as _ from "lodash";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import UserList from "./UserList";
import * as raasiAction from "../../Kattam/KattamAction";

class UserListContainer extends React.Component {
    componentDidMount() {
        // if (!this.props.users || this.props.users.length < 1) {
        //     this.props.actions.retrieveUsers();
        // }
        this.props.actions.retrieveUsers();
    }
    goBack = () => this.props.history.goBack();
    render() {
        return (
            <div>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                    about="Go Back"
                    onClick={this.goBack}
                >
                    <ArrowBackIcon />
                </IconButton>
                <UserList users={this.props.users} actions={this.props.actions} isDeleted={this.props.isDeleted}/>
            </div>
        );

    }
}

const mapStateToProps = (state) => ({
    users: _.get(state.raasi, "users", []),
    isDeleted: _.get(state.raasi, "isDeleted"),
    //message: "User is deleted....",
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(raasiAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserListContainer));

//export default withRouter(UserListContainer);