import moment from "moment";
import { del, get } from "../../httpService";
import Planets from "../common/Planets";

export const retrieveJaadhagans = () => get("/jaadhagans");
export const getRaasiCalculation = (jaadhagamId) => get(`/jaadhagams/${jaadhagamId}`);
export const getUserPredictions = (jaadhagamId) =>
  get(`/astro-rule/apply/jaadhagams/${jaadhagamId}`);

  export const getUserPredictionsOfPP = (jaadhagamId) =>
  get(`/astro-rule/apply/pp/jaadhagams/${jaadhagamId}`);

	export const getUserPredictionsOfPPByReferences = (jaadhagamId,referenceIds) =>
  get(`/astro-rule/apply/pp/jaadhagams/${jaadhagamId}/references?referenceIds=${referenceIds}`);

  export const getUserKotcharamPredictionsOfPP = (jaadhagamId) =>
  get(`/astro-rule/apply/pp/kotcharam/${jaadhagamId}`);

  export const getUserKotcharamPredictionsOfPPForPast = (jaadhagamId) =>
  get(`/astro-rule/apply/pp/kotcharam/past/${jaadhagamId}`);

  export const getUserKotcharamPredictionsOfPPForFuture = (jaadhagamId) =>
  get(`/astro-rule/apply/pp/kotcharam/future/${jaadhagamId}`);


export const getTransitDates = (planets) => get(`/planets/transit/planets?excludes=${planets}`);

export const deleteUserJaathagan = (jaadhagamId) => del(`/jaadhagams/${jaadhagamId}`);
export const getKotcharam = date => get(`/kotcharam?date=${date}`);
export const getKotcharamPlanetStrength  = (date, userId) => get(`/metering/planets/users/${userId}/kotcharam?date=${date}`);
export const getKotcharamHouseStrength  = (date, userId) => get(`/metering/houses/users/${userId}/kotcharam?date=${date}`);


export const getDasaByDate = (userId,date) => get(`/jaadhagams/${userId}/dasa/${date}`);

export const getPaavaVivaram = (name) => get(`/influencer-nature/influencers/${name}`);
export const retrievePaavaVivaram = () => get(`/influencer-nature/influencers`);
export const retrievePlanetStrength = userId => get(`/metering/planets/users/${userId}`);
export const retrieveHouseStrength = userId => get(`/metering/houses/users/${userId}`);

const getZone = () => {
  console.log("moment.tz.guess() :", moment.tz());
  return encodeURIComponent(moment.tz.guess());
}
export const getOrai = (date,zoneId) => get(`/orai?date=${date}&zoneId=${zoneId}`);
