import { Button, FormControl, Input, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { Component } from "react";
import Planets from "../common/Planets";
import Raasi from "../common/Raasi";

export default class SearchLagnam extends Component {
  styles = {
    container: {
      margin: "10px",
      minWidth: "300px",
    },
  };

  state = {
    planets: [],
    planetCombination: "OR",
    lordHouseNo: "",
    house:"",
    splHouse: null,
    raasi:""
  };

  handleLordHouseChange = (e) => {
    
    this.setState({ lordHouseNo: e.target.value });
  }
  handleHouseChange = (e) => {
    
    this.setState({ house: e.target.value });
  }
  handlePlanetChange = (event) => this.setState({ raasi: event.target.value });
  
  createRequest = () => {
    let req = {
      raasi: this.state.raasi,
      planetCombination: this.state.planetCombination,
      lordHouseNo: this.state.lordHouseNo,
      splHouse: this.state.splHouse,
      house: this.state.house,
    };
    return req;
  }
  search = () => {this.props.actions.searchByRaasiHouseNo(this.createRequest());this.props.kattamActions.resetCurrentUser();}
  reset = () => this.setState({ raasi: "", house: "", splHouse: null,lordHouseNo: "" });


  render() {
    let raasis = Object.values(Raasi);
    return (
      <div style={{ padding:'10px',  margin: "10px" ,display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", flexWrap:'wrap', border: "1px solid black" }}>
        <FormControl style={this.styles.container}>
          <InputLabel id="planet2-simple-select-label">Raasi</InputLabel>
          <Select labelId="planet2-simple-select-label" id="planet2-simple-select"  onChange={this.handlePlanetChange} value={this.state.raasis} input={<Input />}>
            {raasis.map((p) => (
              <MenuItem value={p.name}>{p.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <InputLabel style={{ marginTop: "24px", marginLeft: "10px", marginRight: "10px" }}>Is</InputLabel>
        <FormControl style={{width: "160px"}} >
          <InputLabel id="lord-simple-select-label">HouseNo</InputLabel>
          <Select labelId="lord-simple-select-label" id="lord-simple-select" onChange={this.handleHouseChange} value={this.state.house} input={<Input />}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((p) => (
              <MenuItem value={p}>{p}</MenuItem>
            ))}
          </Select>
        </FormControl>
        
        

        <Button color="secondary" style={{ marginLeft: "20px", marginTop: "10px", width: "100px" }} variant="outlined" onClick={this.search}>
          Search
        </Button>
        <Button color="secondary" style={{ marginLeft: "5px", marginTop: "10px", width: "100px" }} variant="outlined" onClick={this.reset}>
          RESET
        </Button>
      </div>
    );
  }
}