import { Button, Card, CardContent, Checkbox, Chip, FormControlLabel } from "@material-ui/core";
import moment from "moment";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Planets from "../../common/Planets";
import { formatDate, getColor, getForeColor, getPlanetShortName } from "../../DasaBhukthi/DasaUtil";

export default class TransitDates extends Component {

  getChip = d => <Chip label={<div><FormattedMessage id={getPlanetShortName(d)} /></div>}
    style={{ margin: '4px', width: '50px', backgroundColor: getColor(d), color: getForeColor(d), border: '1px solid grey' }} />;

  // getChip = d => d;

  handleChange = () => {
    let newState = !this.props.showOnlyYearlyPlanets;
    this.props.actions.setShowOnlyYearlyPlanets();

    if (newState) {
      this.props.actions.getTransitDates("SUKKIRAN,PUDHAN,CHEVVAI");
    } else {
      this.props.actions.getTransitDates("");
    }

  };

  dateClicked = e => {
    let date = moment(e.target.innerText, "DD-MMM-YYYY");
    this.props.actions.retrieveKotcharam(moment(date, "DD-MMM-YYYY").format('DD-MMM-YYYY hh:mm a'));
    this.props.actions.retrieveOrai(moment(date, "DD-MMM-YYYY").format('DD-MMM-YYYY hh:mm a'));
    if (this.props.userId) this.props.actions.getDasaByDate(this.props.userId, moment(date, "DD-MMM-YYYY").format('DD-MMM-YYYY hh:mm a'));

  };

  render() {
    return <Card style={{  backgroundColor: '#ffe7d5' }} >
      <CardContent>
        {this.props.transitDates.length > 0
          && <div>
            <div style={{ display: 'flex', fontWeight: 'bold', fontSize: '20px', justifyContent: 'center', alignItems: 'center', margin: '5px', border: '1px solid #f2eaea' }}> கிரக பெயர்ச்சி நாட்கள் </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.props.showOnlyYearlyPlanets}
                  onChange={this.handleChange}
                  name="checkedB"
                  color="primary"
                />}
              style={{ marginLeft: '5px' }}
              label="வருட கோள்கள் மட்டும்"
            />
            <div style={{maxHeight: "360px", overflow: "scroll"}}>
            {this.props.transitDates.filter(t => !(t.planets.length === 1 && t.planets.includes(Planets.SOORIYAN))).map(t =>
              <div style={{ display: 'flex', marginLeft: '10px', marginRight: '10px', justifyContent: 'space-around', alignItems: 'center', border: '1px solid #f2eaea' }}>
                <div><Button onClick={this.dateClicked}>{formatDate(t.date)}</Button></div>
                {t.planets.filter(p => p !== Planets.SOORIYAN && p !== Planets.CHANDRAN).map(p => this.getChip(p))}</div>)}
            </div>
          </div>}
        {this.props.loading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} class="animate-charcter">Loading Important Dates..</div>}
      </CardContent>
    </Card>;
  }
}

const PlanetOptions = () => {

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });


  const handleChange = () => {
    this.props.actions.setShowOnlyYearlyPlanets();
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={state.checkedB}
          onChange={handleChange}
          name="checkedB"
          color="primary"
        />}
      style={{ margin: '5px' }}
      label="வருட கோள்கள் மட்டும்"
    />
  );
}