import { Component } from "react"
import cheese from '../img/logo192.png';
import { loadBase } from './ellipse';
import { isEmpty, isEqual } from 'lodash'
import Planets from "../components/common/Planets";
class Canvas extends Component {

  state = [];
  canvasRef = null;
  imageRef = null;
  earthRef = null;

  setCanvasRef = element => this.canvasRef = element;
  setEarthRef = element => this.earthRef = element;
  setImage = element => this.imageRef = element;
  ctx = null;

  componentDidMount() {
    this.ctx = this.canvasRef.getContext("2d")
    // this.imageRef.onload = () => {
    //   ctx.font = "40px Courier"
    //   ctx.fillText(this.props.text, 210, 75)
    // }

    // const earthCtx = this.earthRef.getContext("2d");
    if (!isEmpty(this.canvasRef)) {
      this.setState({ ctx: this.ctx });
      this.setState({ canvasRef: this.canvasRef });
      loadBase(this.canvasRef, this.props.planetLngMap);
    }

  }

  componentDidUpdate(prevProps) {
    // Manipulate this._ctx here
    // console.log("DEBUG UPDATING :" + prevProps.date + ":  this.props.dob:" + this.props.date + " : " + !isEqual(prevProps.date, this.props.date));
    if (!isEqual(prevProps.date, this.props.date)) {
      // console.log("DEBUG this.ctx :", this.state.ctx);
      if (this.state.ctx) {
        console.log("DEBUG Clearing..")
        // this.state.cxt.clearRect(0, 0  ,640, 425);
        loadBase(this.state.canvasRef, this.props.planetLngMap);
      }
    }

  }



  render() {
    // console.log("DEBUG this.props.degrees :", this.props.degrees);
    return (
      <div style={{marginTop: "-50px"}} >
        <canvas ref="canvas" width={640} height={425} ref={this.setCanvasRef} />
        {/* <canvas ref="earth" width={640} height={425} ref={this.setEarthRef} style={{position:"absolute", left: 0, top: 0, zIndex: 0}}/> */}
        {/* <img ref="image" src={cheese} className="hidden" alt="logo" ref={this.setImage}/> */}
      </div>
    )
  }
}
export default Canvas;
