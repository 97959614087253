import actionType from "./PredictionActionType";

export const saveCorrectPrediction = (ruleId, userId) => {
  return (dispatch) =>
    dispatch({
      type: actionType.POST_CORRECT_PREDICTION,
      ruleId: ruleId,
      userId: userId
    });
};


export const saveInCorrectPrediction = (ruleId, userId) => {
  return (dispatch) =>
    dispatch({
      type: actionType.POST_INCORRECT_PREDICTION,
      ruleId: ruleId,
      userId: userId
    });
};

export const saveMayBePrediction = (ruleId, userId) => {
  return (dispatch) =>
    dispatch({
      type: actionType.POST_MAYBE_PREDICTION,
      ruleId: ruleId,
      userId: userId
    });
};

export const getAllPredictions = () => {
  return (dispatch) =>
    dispatch({
      type: actionType.GET_ALL_PREDICTION
    });
};


export const setRuleToEdit = (rule) => {
  return (dispatch) =>
    dispatch({
      type: actionType.SET_RULE_TO_EDIT,
      data: rule
    });
};

export const saveAstroRule = (rule) => {
  return (dispatch) =>
    dispatch({
      type: actionType.SAVE_ASTRO_RULE,
      data: rule
    });
};

export const setRuleDialogOpen = (open) => {
  return (dispatch) =>
    dispatch({
      type: actionType.SET_RULE_DIALOG_OPEN,
      data: open
    });
};

