import { Button, FormControl, Input, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { Component } from "react";
import Planets, {bonds} from "../common/Planets";

export default class SearchLordIsXPlanet extends Component {
  styles = {
    container: {
      margin: "10px",
      minWidth: "300px",
    },
  };

  state = {
    planets: [],
    planetCombination: "OR",
    lordHouseNo: "",
    house:"",
    splHouse: null,
    bondTypes: []
  };

  handleLordHouseChange = (e) => {
    
    this.setState({ lordHouseNo: e.target.value });
  }
  // handlePlanetChange = (event) => this.setState({ planets: [...this.state.planets, ...event.target.value] });
  handleBondChange = (event) => this.setState({ bondTypes: [...this.state.bondTypes, ...event.target.value] });
  
  createRequest = () => {
    let req = {
      planets: this.state.planets,
      planetCombination: this.state.planetCombination,
      lordHouseNo: this.state.lordHouseNo,
      splHouse: this.state.splHouse,
      house: this.state.house,
      bondTypes: this.state.bondTypes
    };
    return req;
  }
  search = () => {this.props.actions.searchByLordWithBond(this.createRequest()); this.props.kattamActions.resetCurrentUser();}
  reset = () => this.setState({ bondTypes: [], house: "", splHouse: null,lordHouseNo: "" });


  render() {
    return (
      <div style={{ padding:'10px',  margin: "10px" ,display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", flexWrap:'wrap', border: "1px solid black" }}>
        <FormControl style={{width: "160px"}} >
          <InputLabel id="lord-simple-select-label">Lord Of House</InputLabel>
          <Select labelId="lord-simple-select-label" id="lord-simple-select" onChange={this.handleLordHouseChange} value={this.state.lordHouseNo} input={<Input />}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((p) => (
              <MenuItem value={p}>{p}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <InputLabel style={{ marginTop: "24px", marginLeft: "10px", marginRight: "10px" }}>AND</InputLabel>
        <FormControl style={this.styles.container}>
          <InputLabel id="planet2-simple-select-label">Bond(Or)</InputLabel>
          <Select labelId="planet2-simple-select-label" id="planet2-simple-select" multiple onChange={this.handleBondChange} value={this.state.bondTypes} input={<Input />}>
            {bonds.map((p) => (
              <MenuItem value={p}>{p}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button color="secondary" style={{ marginLeft: "20px", marginTop: "10px", width: "100px" }} variant="outlined" onClick={this.search}>
          Search
        </Button>
        <Button color="secondary" style={{ marginLeft: "5px", marginTop: "10px", width: "100px" }} variant="outlined" onClick={this.reset}>
          RESET
        </Button>
      </div>
    );
  }
}