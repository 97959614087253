import { Button } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import * as OAuthActions from './OAuthAction';
import {generateCode, googleOidcParam} from '../AuthorizeConfig';
import _ from 'lodash';
import { GoogleLoginButton } from "react-social-login-buttons";

class OAuth extends Component {
    loginWithGoogle = () => {
        let codes = generateCode();
        this.props.actions.loginWithGoogle(codes, googleOidcParam(codes));
    }
    render() {
        return (
            <div>
                {/* <Button onClick={this.loginWithGoogle}>Login with Google</Button> */}
                <GoogleLoginButton onClick={this.loginWithGoogle}></GoogleLoginButton>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        data: _.get(state.raasi, "sample.data", {}),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(OAuthActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OAuth));
