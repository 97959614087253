import "date-fns";
import React, {useRef} from "react";
import { DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

export default function MaterialUIPickers(props) {
  // The first commit of Material-UI
  const curDateTime = Date().toLocaleString();

  const [selectedDate, setSelectedDate] = React.useState();

  const handleDateChange = (date) => {
    props.onChange(date);
    setSelectedDate(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        margin="normal"
        toolbarTitle = "Date Of Birth"
        label={props.label? props.label : "Birth Datetime"}
        variant="inline"
        value={selectedDate}
        onChange={handleDateChange}
        format="dd-MMM-yyyy hh:mm a"
        openTo="year"
        style={{marginRight: "20px", width: "200px"}}
      />
    </MuiPickersUtilsProvider>
  );
}
