/* eslint-disable import/no-anonymous-default-export */
import _ from "lodash";
import { onFinish, onStart } from "../../ApiMiddleware";
import PredictionActionType from "./PredictionActionType";

export const initialState = () => {
  return {
    genericAstroRules: {rules: []},
    isRuleDialogOpen: false,
    ruleToEdit: {},
    loading: false
  };
};

export default (state = initialState(), action) => {
  switch (action.type) {
    case onStart(PredictionActionType.GET_ALL_PREDICTION):
      return {
        ...state,
        loading: true
      };

    case onFinish(PredictionActionType.GET_ALL_PREDICTION):
      return {
        ...state,
        genericAstroRules: {rules: action.data},
        loading: false
        
      };

      case onStart(PredictionActionType.SAVE_ASTRO_RULE):
      return {
        ...state,
        genericAstroRules: {rules: [...state.genericAstroRules.rules, action.data]},
        loading: true
      };
    case onFinish(PredictionActionType.SAVE_ASTRO_RULE):
      return {
        ...state,
        genericAstroRules: {rules: [...state.genericAstroRules.rules, action.data]},
        loading: false
      };



    case PredictionActionType.SET_RULE_DIALOG_OPEN:
      return {
        ...state,
        isRuleDialogOpen: action.data
      };

    case PredictionActionType.SET_RULE_TO_EDIT:
      return {
        ...state,
        ruleToEdit: action.data
      };

    default:
      return state;
  }
};
