import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as lifeEventActions from "./LifeEventAction";
import LifeEvent from "./LifeEvent";
import * as _ from "lodash";
import LifeEvents from "./LifeEvents";
import { IconButton, Typography } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { createIntlCache, createIntl, injectIntl } from "react-intl";
import * as raasiAction from "../Kattam/KattamAction";


export class LifeEventContainer extends Component {
  componentDidMount() {
    this.props.actions && this.props.actions.getEmotionTypes();
    this.props.actions && this.props.actions.getEventCategories();
    this.props.user && this.props.actions && this.props.actions.getLifeEvent(this.props.user.id);
  }

  // static getDerivedStateFromProps(prevProps, state) {
  //   if (prevProps && prevProps.loading) {
  //     prevProps.history.push("/kattam");
  //   }
  // }

  goBack = () => this.props.history.push("/kattam");
  render() {
    return (
      <div style={{ padding: "15px" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
            about="Go Back"
            onClick={this.goBack}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" style={{ textAlign: "center", flexGrow: "2" }}>Daily Life Events</Typography>
        </div>
        {!this.props.loading && <LifeEvents events={this.props.events} emotionTypes={this.props.emotionTypes}
          raasiActions={this.props.raasiActions}
          actions={this.props.actions} user={this.props.user} intl={this.props.intl} message={this.props.message} eventCategories={this.props.eventCategories} />}
        {this.props.loading && <div>Loading events..</div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    emotionTypes: state.events.emotions,
    eventCategories: state.events.eventCategories,
    events: state.events.events,
    loading: state.events.loading,
    user: _.get(state.raasi, "currentUser"),
    message: _.get(state.events, "recentlyAdded"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(lifeEventActions, dispatch),
    raasiActions: bindActionCreators(raasiAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(LifeEventContainer)));
