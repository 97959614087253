export default {
    template: {
        "reference" : "book-123",
        "positivity" : "false",
        "tags": [
            "yogam", "IfPlanets - ResidesIn, Aspects, AspectBy",
            "LordOfHouses - ResidesIn, Aspects, Strength, ResidesWith",
			"ifLagnam - is any of a raasi"
        ],
        "type":"kotcharam",
        "countFrom": { "and": [ "lagnam"]},
        "and": [
			{
                "ifLagnam": {"and": []},
                "isRaasi": { "or": ["mesham", "kanni"]}
            },
            {
                "lordOfHouses": {"and": [1]},
                "residesIn": { "or": [1, "anywhere", "exchange", "eachother"]}
            },
            {
                "lordOfHouses": {"and": [1]},
                "residesWith": { "or": ["malefics","benefics", "guru", "anywhere"]}
            },
            {
                "lordOfHouses": {"and": [1]},
                "aspects": { "or": ["sooriyan", "eachother"]}
            },
            {
                "lordOfHouses": {"and": [1]},
                "strength": { "or": ["natpu","uccham","moolathirikonam", "aatchi","samam", "enemy", "neecham"]}
            },



            {
                "ifPlanets": {"and": ["SUKKIRAN"]},
                "residesIn": { "or": [1]}
            },
            {
                "ifPlanets": {"and": ["SUKKIRAN"]},
                "aspects": { "or": ["sooriyan", "eachother"]}
            },
            {
                "ifPlanets": {"and": ["SUKKIRAN"]},
                "aspectBy": { "or": ["benefics", "10"]}
            }
        ],
        "predictions": [
            "The native will "
        ]
    }
}
