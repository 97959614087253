import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import AppBar from './AppBar';
import * as _ from "lodash";
import * as LocalService from "./LocalStoreService";
import * as actions from './components/Login/LoginAction';
import { bindActionCreators } from 'redux';

class HomePage extends Component {


  componentDidMount() {
    if (!this.props.isLoggedIn) {
      this.props.history.push("/");
    }
  }

  componentDidUpdate(prevProps) {
    // console.log("prevProps.isLoggedIn :" +prevProps.isLoggedIn);
    if (prevProps.isLoggedIn !== this.props.isLoggedIn && this.props.isLoggedIn === false) {
        this.props.history.push("/");
    }
}

  state = {
    user: LocalService.getGoogleUser()
  }
  render() {
    return (
      <div style={{ width: "100%" }}>
        <AppBar user={this.state.user} loginActions={this.props.loginActions} />
        {this.props.children}
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    isLoggedIn: _.get(state.oauth, "isLoggedIn")
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginActions: bindActionCreators(actions, dispatch),
  };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HomePage));