import React from "react";
import SocialButton from "./SocialButton";
import { FacebookLoginButton } from "react-social-login-buttons";

import { Button, Typography } from "@material-ui/core";
import * as LocalService from "../../LocalStoreService";
import _ from "lodash";
import raasiHome from '../../img/raasi-home.png'
import raasiLogo from '../../img/raasi-logo.png'
import OAuth from "../Authorization/OAuth/OAuth";

export default class Login extends React.Component {
  styles = {
    button: {
    },
  };

  hasAccessToken = () => {
    let login = LocalService.get("user");
    let accessToken = _.get(login, "_token.accessToken", "");
    return accessToken !== "";
  };

  componentDidMount() {
    if (this.hasAccessToken()) {
      let login = LocalService.get("user");
      this.props.actions.setAuthenticatedUser(login);
      // this.props.history.push("/kattam");
    }
  }


  handleSocialLogin = (e) => {
    console.log("handleSocialLogin Succes: ", e);
    this.props.actions.setAuthenticatedUser(e);
    LocalService.setFbUser(e);
    // this.props.history.push("/kattam");
  };

  handleSocialLoginFailure = (e) => {
    console.log("handleSocialLoginFailure:", e);
  };

  // getAppId = () => _.get(process, "env.FB_CLIENT_ID", "929132867838298"); // dev
  getAppId = () => _.get(process, "env.FB_CLIENT_ID", "268674868148109"); // prod


  render() {
    return (
      <div style={{ margin: "40px", minWidth: "300px", display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
        <Typography gutterBottom variant="h5" >Raasi.app </Typography>
        <Typography gutterBottom variant="h6" >Astroscience tool for Astrologers - Thirukanidham</Typography>
        <Typography gutterBottom variant="h5" >சோதிட கணிதம்</Typography>
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <img src={raasiHome} width="80px" height="80px"></img>
          <img src={raasiLogo} width="80px" height="80px"></img>
          {/* <canvas id="canvas" width="1600" height="1200" style="position: absolute; display: block; background-color: rgb(255, 255, 255); width: 800px; height: 600px;"></canvas> */}
        </div>

        <OAuth></OAuth>

        {/* <SocialButton
          provider="facebook"
          // appId="268674868148109" //prod
          //  appId="929132867838298" // dev
          appId={this.getAppId()}
          onLoginSuccess={this.handleSocialLogin}
          onLoginFailure={this.handleSocialLoginFailure}
          style={{
            border: "none",
            backgroundColor: "transparent",
            color: "white"
          }}
        >
          <FacebookLoginButton ></FacebookLoginButton>
        </SocialButton> */}
        {/* <GoogleLoginButton style={this.styles.button}>
          <SocialButton
            provider="google"
            appId="204001772896-bsj2eplh24816gpbcv98vga4maftu8qq.apps.googleusercontent.com"
            onLoginSuccess={this.handleSocialLogin}
            onLoginFailure={this.handleSocialLoginFailure}
            style={{ border: "none", backgroundColor: "transparent" , color:"black"}}
          >
            Login with Google
          </SocialButton>
        </GoogleLoginButton> */}


      </div>
    );
  }
}
