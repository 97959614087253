import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputLabel, Snackbar, TextField, Tooltip, Typography } from "@material-ui/core";
import DateTimePicker from "../common/DateTimePicker";
import Geocode from "react-geocode";
import Paper from "@material-ui/core/Paper";
import LocationMapper from '../common/LocationMapper';
import MuiAlert from '@material-ui/lab/Alert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as _ from 'lodash';
import moment from 'moment';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class AddUser extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }
  style = {
    width: "400px", marginTop: "10px"
  };
  componentDidMount() {
    this.loadGoogleMaps(() => {
      this.setState({ googleMapsReady: true });
    });
  }

  componentWillUnmount() {
    this.unloadGoogleMaps();
  }

  state = this.initialState;
  loadGoogleMaps = callback => {
    const existingScript = document.getElementById("googlePlacesScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyCYOkCu5PvaSVzT_4xoRbZuB5WO78ke0WY&libraries=places";
      script.id = "googleMaps";
      document.body.appendChild(script);
      //action to do after a script is loaded in our case setState
      script.onload = () => {
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  };

  unloadGoogleMaps = () => {
    let googlePlacesScript = document.getElementById("googlePlacesScript");
    if (googlePlacesScript) {
      googlePlacesScript.remove();
    }
  };

  handleClose = () => {
    // if (this.props.message.type === "info") {
    //   this.props.history.push("/kattam");
    // }
    this.props.actions.resetPostUserJathganMessage();
  };



  initialState = {
    userName: _.get(this.props, "user.firstName", "") + " " + _.get(this.props, "user.lastName", ""),
    birthLocation: {
      latitude: 0,
      longitude: 0,
      address: ""
    },
    userEmail: _.get(this.props, "user.emailId", ""),
    address: ""
  };



  handleGeoLocation = async (location) => {
    Geocode.setApiKey("AIzaSyCYOkCu5PvaSVzT_4xoRbZuB5WO78ke0WY");
    Geocode.setLanguage("en");
    Geocode.setRegion("us");
    Geocode.enableDebug();
    try {
      console.log("location :", location);
      let response = await Geocode.fromAddress(location);
      const { lat, lng } = response.results[0].geometry.location;
      this.setState({ birthLocation: { latitude: lat, longitude: lng } });
      // const timezoneResponse = await Geocode.getTimeZone({ lat, lng });
      // console.log("timezoneResponse :", timezoneResponse);
    }
    catch (e) {
      console.error("Error reading address :", e);
    }

  };

  handleUserChange = e => {
    this.setState({ userName: e.target.value });
  }
  handleUserEmailChange = e => this.setState({ userEmail: e.target.value });
  setLocation = e => this.setState({ address: e.target.value });
  dateChanged = d => {
    this.setState({ dob: d });
    this.setState({ dobStr: d });
  }
  goBack = () => this.props.history.goBack();
  birthPlaceSelected = obj => {
    console.log("obj :", obj);
    this.setState({ birthLocation: obj })
  };

  formatDate = dt => {
    let dStr = this.state.dobStr + "";
    let justDateTime = dStr.substring(0, dStr.indexOf(" GMT"));
    let t = moment.tz(justDateTime, _.get(this.state, "birthLocation.timezone.timeZoneId"));
    const utcDate = t.utc();
    return utcDate.format('YYYY-MM-DD hh:mm a');
  }

  isAllValid = () => {
    if (!this.state.birthLocation || !this.state.birthLocation.address) {
      this.props.actions.setMessage({ message: "(Re)select Birth Place", type: "error" });
      return false;
    }
    // console.log("this.state.birthLocation :", this.state.birthLocation);
    if (!this.state.dob) {
      this.props.actions.setMessage({ message: "Select Birth Datetime", type: "error" });
      return false;
    }
    return true;
  };

  reset = () => {
    // console.log("state :", this.state);
    this.setState(this.initialState);
  };

  addUser = () => {
    if (!this.isAllValid()) {
      return;
    }
    // this.formatDate(this.state.dob);

    this.handleGeoLocation(_.get(this.state, "birthLocation.address"));
    let req = {
      name: this.state.userName,
      emailId: this.state.userEmail,
      dob: this.formatDate(this.state.dob),
      dobStr: this.formatDate(this.state.dob),
      timeZoneId: _.get(this.state, "birthLocation.timezone.timeZoneId"),
      timeZoneName: _.get(this.state, "birthLocation.timezone.timeZoneName"),
      birthLocation: {
        address: this.state.birthLocation.address,
        latitude: this.state.birthLocation.latLng ? this.state.birthLocation.latLng.lat : 0.0,
        longitude: this.state.birthLocation.latLng ? this.state.birthLocation.latLng.lng : 0.0,
      },
    };
    this.props.actions.postUserJathgan(req);
    if (this.props.message.type === "info") {
      this.reset();
    }
  };



  render() {

    let disableMe = this.props.addJaadhagamLabel === "Add User" ? false : true ;
    if (!this.state.googleMapsReady) {
      return <p>Loading</p>;
    }
    return (
      <div>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          about="Go Back"
          onClick={this.goBack}
        >
          <ArrowBackIcon />
        </IconButton>
        <Paper elevation={2} style={{ padding: "10px", margin: "20px 10px 10px", marginRight: "10px" }}>
          <h3>New Jaadhagam</h3>
          <div>
            <TextField
              label="Name"
              variant="standard"
              value={this.state.userName}
              onChange={this.handleUserChange}
              style={{ minWidth: "200px", marginBottom: "10px" }}
            />
            <LocationMapper label={"Birth Place"} style={{ minWidth: "350px", marginBottom: "10px" }} onSelect={this.birthPlaceSelected} />
            <div style={{ width: "350px", display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "10px" }}>
              <DateTimePicker onChange={this.dateChanged} label="Date Of Birth" />
              <Typography style={{ fontWeight: "bold", fontSize: "small", marginTop: "15px" }}>{_.get(this.state, "birthLocation.timezone.timeZoneName")}</Typography>
            </div>
            <TextField
              label="Email (to send predictions)"
              variant="standard"
              value={this.state.userEmail}
              onChange={this.handleUserEmailChange}
              style={{ width: "300px", marginBottom: "20px" }}
            />

          </div>

          <Button
            style={{
              marginTop: "30px",
              marginLeft: "20px",
              marginRight: "2px",
            }}
            variant="contained"
            onClick={this.reset}
          >
            RESET
          </Button>
          {!disableMe && <Button
            variant="contained"
            color="primary"
            style={{
              marginTop: "30px",
              marginLeft: "20px",
            }}
            onClick={this.addUser}
          >
            {this.props.addJaadhagamLabel}
          </Button>}
          {disableMe && <Button
            variant="text"
            color="secondary"
            style={{
              marginTop: "30px",
              marginLeft: "20px",
            }}
          >
            {this.props.addJaadhagamLabel}
          </Button>
          }
          <Snackbar open={this.props.message.type !== ""} autoHideDuration={6000} onClose={this.handleClose}>
            <Alert onClose={this.handleClose} severity={this.props.message.type}>
              {this.props.message.message}
            </Alert>
          </Snackbar>

          {/* <Dialog
            open={this.props.message && this.props.message.type !== ""}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"

          >
            <DialogTitle id="alert-dialog-title" color="primary">{"New Jaadhagam - " + this.props.message.type}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.props.message.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary" autoFocus>
                OK
          </Button>
            </DialogActions>
          </Dialog> */}
        </Paper>
      </div>
    );
  }
}
