/* eslint-disable import/no-anonymous-default-export */
import { onFinish, onStart } from "../../ApiMiddleware";
import SearchActionType from "./SearchActionType";

export const initialState = () => {
  return {
    jaadhagans: [],
    criteria: { searchByPlanetOnHouse: {} },
  };
};

export default (state = initialState(), action) => {
  switch (action.type) {
    case onStart(SearchActionType.SEARCH_BY_PLANETS_ON_HOUSE):
      return {
        ...state,
        criteria: { searchByPlanetOnHouse: action.data },
      };
    case onStart(SearchActionType.SEARCH_BY_LORD_ON_HOUSE):
      return {
        ...state,
        criteria: { searchByLordOnNthHouse: action.data },
      };
    case onStart(SearchActionType.SEARCH_BY_LORD_IS_PLANET):
      return {
        ...state,
        criteria: { searchByLordIsXPlanet: action.data },
      };
      case onStart(SearchActionType.SEARCH_BY_LORD_WITH_BOND):
      return {
        ...state,
        criteria: { searchByLordWithBond: action.data },
      };
      // case onStart(SearchActionType.SEARCH_BY_RAASI_HOUSENO):
      // return {
      //   ...state,
      //   criteria: { searchByLordIsXPlanet: action.data },
      // };
    case onFinish(SearchActionType.SEARCH_BY_PLANETS_ON_HOUSE):
    case onFinish(SearchActionType.SEARCH_BY_LORD_ON_HOUSE):
    case onFinish(SearchActionType.SEARCH_BY_RAASI_HOUSENO):  
    case onFinish(SearchActionType.SEARCH_BY_LORD_IS_PLANET):
    case onFinish(SearchActionType.SEARCH_BY_LORD_WITH_BOND):
    case onFinish(SearchActionType.SEARCH_BY_DASA_PUTTHI):
    case onFinish(SearchActionType.SEARCH_BY_PLANET_BOND_WITH_RAASI):    
      return {
        ...state,
        jaadhagans: action.data,
      };
    default:
      return state;
  }
};
