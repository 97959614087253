import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import * as commonActions from '../common/actions';
import DateFnsUtils from "@date-io/date-fns";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { doBToMoment, formatToKotcharam } from "./DateUtil";

class DatePickerCS extends React.Component {

    handleDateChange = (date) => {
        this.props.onChange(date);
        this.props.actions.setSelectedDate(date);
      };

    render() {

       return  <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                variant="dialog"
                value={this.props.selectedDate}
                onChange={this.handleDateChange}
                format="EEE, dd-MMM-yyyy"
                style={{ width: "112px", marginTop: "5px" }}
                // autoOk={true}
                orientation="left"
                showTodayButton
                draggable={true}
                DialogProps={{ anchorEl: '100px' }}
                inputProps={{ style: { fontSize: "small", padding: '0px', paddingBottom: '2px' } }}
            >

            </DatePicker>
        </MuiPickersUtilsProvider>
    }
}

const mapStateToProps = (state) => {
  let dt = _.get(state.raasi, "kotcharam.dob", moment());
    return {
      //BUG: workaround in moment
      selectedDate: formatToKotcharam(doBToMoment(dt)),
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      actions: bindActionCreators(commonActions, dispatch),
    };
  };

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(DatePickerCS);

