import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as raasiAction from "../KattamAction";
import * as _ from "lodash";
import { withRouter } from "react-router-dom";
import PlanetStrength from "./PlanetStrength";
import { ThreeSixtyOutlined } from "@material-ui/icons";

export class PlanetStrengthContainer extends React.Component {

  render() {
    if (_.isEmpty(this.props.planetStrength)) {
      return <div>EMPTY</div>
    }
    return (
      <PlanetStrength
        data={this.props.planetStrength}
        // profile={this.props.user}
        // raasiBand={this.props.raasiBand}
        // actions={this.props.actions}
      />
    );
  }
}

const mapStateToProps = (state) => {
  let isRaasi = !_.get(state.raasi, "switcher.checked")
  return {
    user: _.get(state.raasi, "currentUser"),
    planetStrength:isRaasi? _.get(state.raasi, "planetStrength", {}) :  _.get(state.raasi, "kotcharamPlanetStrength", {})
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(raasiAction, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PlanetStrengthContainer));
