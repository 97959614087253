import { Accordion, AccordionDetails, AccordionSummary, ClickAwayListener, colors, Tooltip, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import * as _ from 'lodash';
import { lte } from "lodash";
import TreeView from '@material-ui/lab/TreeView';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import Chip from '@material-ui/core/Chip';
import {planetColors, planetForeColors, kaaragans} from '../common/Planets'

export const formatDate = d => d && moment.utc(d).format('DD-MMM-YYYY');
export const formatDateSimple = d => d && moment.utc(d).format('DD-MMM-YY');

export const parseEventDate = d => {
  return moment(d, 'DD-MMM-YY hh:mm a', false); //  08-Jun-21 21:18 pm
}//2022-01-19 05:30 AM
export const parseDobDate = d => {
  return moment("2022-01-19 05:30 AM", 'YYYY-MM-DD hh:mm a'); //  08-Jun-21 21:18 pm
}


export const  getColor = p => planetColors[p];
export const getForeColor = p => planetForeColors[p];
export const getPlanetShortName = (name) => name + "_SHORT";

export const getBond = d => {
    if (d && d.flow === "NORMAL") {
      return "=";
    }
    if (d && d.flow === "OPEN") {
      return "+";
    }
    if (d && d.flow === "CLOSED") {
      return "x";
    }
  }

  export const getBondFromLagnam = d => {
    if (d && d.fromLagnam === "NORMAL") {
      return "=";
    }
    if (d && d.fromLagnam === "OPEN") {
      return "+";
    }
    if (d && d.fromLagnam === "CLOSED") {
      return "x";
    }
  }

  export const getBondToLagnam = d => {
    if (d && d.toLagnam === "NORMAL") {
      return "=";
    }
    if (d && d.toLagnam === "OPEN") {
      return "+";
    }
    if (d && d.toLagnam === "CLOSED") {
      return "x";
    }
  }

  export const getReverseBond = d => {
    if (d && d.reverseFlow === "NORMAL") {
      return "=";
    }
    if (d && d.reverseFlow === "OPEN") {
      return "+";
    }
    if (d && d.reverseFlow === "CLOSED") {
      return "x";
    }
  }

  export const getAge = (dobStr, date) => {
    let dob = moment(dobStr, 'YYYY-MM-DD hh:mm A');
    let age = moment(date, 'DD-MMM-YYYY');
    return age.diff(dob, 'years');
  }