/* eslint-disable import/no-anonymous-default-export */
import { onFinish, onError, onStart } from "../../ApiMiddleware";
import DasaBhukthiActionConstants from "./DasaBhukthiActionConstants";

export const initialState = () => {
  return {
    dasaBhukthi: [],
    dasas: [],
    putthis: [],
    andharams: [],
    sootchamams: [],
    current: {},
    loading: false
  };
};


export default (state = initialState(), action) => {
  switch (action.type) {
    case onFinish(DasaBhukthiActionConstants.DASA_BHUKTHI):
      return {
        ...state,
        dasaBhukthi: action.data,
      };

    case onStart(DasaBhukthiActionConstants.GET_DASA):
      return {
        ...state,
        loading: true
      };
    case onFinish(DasaBhukthiActionConstants.GET_DASA):
      return {
        ...state,
        dasas: action.data,
        loading: false
      };

    case onStart(DasaBhukthiActionConstants.GET_PUTTHI):
      return {
        ...state,
        current: { ...state.current, dasa: action.data.dasa },
      };
    case onFinish(DasaBhukthiActionConstants.GET_PUTTHI):
      return {
        ...state,
        putthis: action.data,
      };

    case onStart(DasaBhukthiActionConstants.GET_ANDHARAM):
      return {
        ...state,
        current: { ...state.current, putthi: action.data.putthi },
      };
    case onFinish(DasaBhukthiActionConstants.GET_ANDHARAM):
      return {
        ...state,
        andharams: action.data,
      };

    case onStart(DasaBhukthiActionConstants.GET_SOOTCHAMAM):
      return {
        ...state,
        current: { ...state.current, andharam: action.data.andharam },
      };
    case onFinish(DasaBhukthiActionConstants.GET_SOOTCHAMAM):
      return {
        ...state,
        sootchamams: action.data,
      };
    default:
      return state;
  }
};

