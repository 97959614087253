import React, { Component } from 'react';
import { Button, TextareaAutosize, Typography } from '@material-ui/core';
import * as userAction from './UserActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

class ContactUs extends Component {

    state = {};

    handleContactUs = () => {
        this.props.action.contactUs(this.state.info);
    }

    handleOnChange = (e) => {
        this.setState({ info: e.target.value });
    }

    render() {
        return (
            <div style={{ display: "flex", justifyContent: "center", margin: "30px", flexDirection: "column", width: "80%" }}>
                <Typography gutterBottom variant="h4" >Contact us</Typography>
                <TextareaAutosize aria-label="empty textarea" rowsMin={10} placeholder="Enter service request here" onChange={this.handleOnChange}>
                </TextareaAutosize>
                <Button style={{ marginTop: "20px", width: "300px" }} variant="contained" color="primary" onclick={this.handleContactUs}>Submit Request</Button>
            </div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(userAction, dispatch),
    };
};

export default connect(null, mapDispatchToProps)(withRouter(ContactUs));