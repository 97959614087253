import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import People from "@material-ui/icons/People";
import Home from "@material-ui/icons/Home";
import Search from "@material-ui/icons/Search";
import DesignServices from "@material-ui/icons/WorkSharp";
import ContactSupportSharpIcon from '@material-ui/icons/ContactSupportSharp';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';
import SpaSharpIcon from '@material-ui/icons/SpaSharp';
import Face from '@material-ui/icons/Face';
import { useHistory } from "react-router-dom";
import * as localService from './LocalStoreService';
import { blue, deepOrange, green, lime, red, yellow } from "@material-ui/core/colors";


const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function TemporaryDrawer(props) {
  // let state = {isOpen:true};
  // this.setState({isOpen: true});
  // state.isOpen;
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const history = useHistory();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const goToHome = () => history.push("/");
  const logout = () => {
    localService.deleteAll();
    props.loginActions.resetAuthenticatedUser();
    goToHome();
  };

  const isAdmin = () => {
    return props.user.role === "ADMIN";
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["Home"].map((text, index) => (
          <ListItem button key={text}  onClick={() => history.push("/raasi")} >
            <ListItemIcon>
              <Home style={{ color: deepOrange[500] }} />
            </ListItemIcon >
            <ListItemText primary={text}/>
          </ListItem>
        ))}
      </List>
      <List>
        {["New Jaadhagam"].map((text, index) => (
          <ListItem button key={text} onClick={() => history.push("/add-user")}>
            <ListItemIcon>
              <SpaSharpIcon style={{ color: lime[800]}}/>
            </ListItemIcon>
            <ListItemText primary={text}  />
          </ListItem>
        ))}
      </List>
      <List>
        {["Users"].map((text, index) => (
          <ListItem button key={text}  onClick={() => history.push("/users")}>
            <ListItemIcon>
              <People style={{ color: blue[500] }}/>
            </ListItemIcon>
            <ListItemText primary={text}/>
          </ListItem>
        ))}
      </List>
      {/* <List>
        {["Life Events"].map((text, index) => (
          <ListItem button key={text} onClick={() => history.push("/life-events")}>
            <ListItemIcon>
              <Face style={{ color: red[800]}}/>
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
      
      {/* <List>
        {["Predictions"].map((text, index) => (
          <ListItem button key={text} >
            <ListItemIcon>
              {index % 5 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} onClick={() => history.push("/predictions")}/>
          </ListItem>
        ))}
      </List> */}
      <Divider />
      <List>
        {isAdmin() && ["Search"].map((text, index) => (
          <ListItem button key={text} onClick={() => history.push("/search")} >
            <ListItemIcon>
              <Search style={{transition:"all 0.3s ease"}}/>
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> 
      <List>
        {isAdmin() && ["AstroRules"].map((text, index) => (
          <ListItem button key={text} onClick={() => history.push("/generic-astro-rules")} >
            <ListItemIcon>
              <DesignServices style={{transition:"all 0.3s ease", color: "#6eb53f"}}/>
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> 
      {/* <List>
        {["ContactUs"].map((text, index) => (
          <ListItem button key={text}  onClick={() => history.push("/contactus")} >
            <ListItemIcon>
              <ContactSupportSharpIcon style={{color: "purple"}} />
            </ListItemIcon>
            <ListItemText primary={text}/>
          </ListItem>
        ))}
      </List>*/}
      <List>
        {["Log out"].map((text, index) => (
          <ListItem button key={text}  onClick={logout} >
            <ListItemIcon>
              <ExitToAppSharpIcon style={{color: "deeppink"}}/>
            </ListItemIcon>
            <ListItemText primary={text}/>
          </ListItem>
        ))}
      </List>
    </div>
  );
  let anchor = "left";
  let isOpen = props.open === undefined ? state[anchor] : props.open;
  return (
    <div>
      <React.Fragment key={anchor}>
        <Drawer
          anchor={anchor}
          open={isOpen}
          onClose={toggleDrawer(anchor, false)}
          onClick={toggleDrawer(anchor, false)}
        >
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
