import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import RaasiName from "../KattamConstants";
import PaavaVivaram from "./PaavaVivaram";
import { Accordion, AccordionDetails, AccordionSummary, Button, ClickAwayListener, colors, List, ListItemText, makeStyles, Switch, Tooltip, Typography } from "@material-ui/core";
import Switcher from "../../common/Switcher";
import * as _ from 'lodash';

export default class PaavaVivaramKattam extends Component {
  state = {};

  styles = {
    container: {
      maxWidth: "1000px",
      minWidth: "250px",
      padding: "5px",
      backgroundColor: "#FFF",
    },
    firstLastRow: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      backgroundColor: "#FFFACD",
    },
    middleRow: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      backgroundColor: "#FFFACD",
    },
    middleRowBelow: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      backgroundColor: "#FFFACD",
    },
    raasiInfo: {
      width: "60%",
      margin: ".25px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgb(203 182 255)",
      fontSize: "small",
      flexWrap: "wrap",
    },
    selectedRaasiInfo: {
      fontWeight: "bold"
    },

    kattam: {
      topHeight: "150px",
      middleHeight: "150px"
    }
  };

  filterByRaasiName = (raasiName) => {
    let filteredRaasi = this.props.raasiBand && this.props.raasiBand.length && this.props.raasiBand.filter((s) => s.raasi === raasiName);
    filteredRaasi =  filteredRaasi ? filteredRaasi[0] : {};
    let influencerNature = this.getInfluencerNatureByHouseNo(filteredRaasi.houseNo);
    filteredRaasi = {...filteredRaasi, desc: influencerNature};
    // console.log("filteredRaasi :", filteredRaasi);
    return filteredRaasi;
  };

  filterRaasi = () => {
    const raasiBand = this.props.data.raasiBand;
    raasiBand.forEach((r) => {
      console.log("Raasi: ", r.raasi);
    });
  };

  getGunam = () => {
    let filteredRaasi = this.props.data && this.props.data.length && this.props.data.filter((s) => s.lagnam);
    return filteredRaasi && filteredRaasi.length ? filteredRaasi[0].gunam : "";
  };

  getInfluencerNatureByHouseNo = houseNo => {
    let houseNatures = this.props.paavaVivarams.filter(p => p.influencer === houseNo+"" || (p.houses && p.houses.includes(houseNo+"")))
    .map(p=> p.ta);
    // console.log("houseNatures :", houseNatures);
    return houseNatures;
  }

  setDescription = (d, houseNo) => {
    let f = this.getInfluencerNatureByHouseNo(houseNo);
    // console.log("f :", f);
    this.props.actions.setHouseDetails(f, houseNo);
  }

  getFirstPart = ar => {
    // let ar = p.split(",");
    // console.log("ar :", ar);
    return ar.slice(0, ar.length / 2).join(",");
  }

  getLastPart = ar => {
    // let ar = p.split(",");
    return ar.slice(ar.length / 2, ar.length).join(",");
  }

  render() {
    if (!this.props.raasiBand) {
      return;
    }
    
    return (
      <div style={this.styles.container}>
        <div name="first-row" style={this.styles.firstLastRow}>
          <PaavaVivaram data={this.filterByRaasiName(RaasiName.MEENAM)} onClick={this.setDescription} width="20%" height={this.styles.kattam.topHeight} selectedHouseNo={_.get(this.props.selectedHouse, "houseNo", "")} desc={this.getInfluencerNatureByHouseNo}/>
          <PaavaVivaram key={RaasiName.MESAM} data={this.filterByRaasiName(RaasiName.MESAM)} onClick={this.setDescription} width="30%" height={this.styles.kattam.topHeight} selectedHouseNo={_.get(this.props.selectedHouse, "houseNo", "")}/>
          <PaavaVivaram key={RaasiName.RISABAM} data={this.filterByRaasiName(RaasiName.RISABAM)} onClick={this.setDescription} width="30%" height={this.styles.kattam.topHeight} selectedHouseNo={_.get(this.props.selectedHouse, "houseNo", "")}/>
          <PaavaVivaram key={RaasiName.MITHUNAM} data={this.filterByRaasiName(RaasiName.MITHUNAM)} onClick={this.setDescription} width="20%" height={this.styles.kattam.topHeight} selectedHouseNo={_.get(this.props.selectedHouse, "houseNo", "")}/>
        </div>
        <div name="middle-1" style={this.styles.middleRow}>
          <PaavaVivaram key={RaasiName.KUMBAM} data={this.filterByRaasiName(RaasiName.KUMBAM)} onClick={this.setDescription} width="20%" height={this.styles.kattam.middleHeight} selectedHouseNo={_.get(this.props.selectedHouse, "houseNo", "")}/>
          <div style={{ ...this.styles.raasiInfo, flexDirection: 'column' }}> <Typography>{this.getFirstPart(_.get(this.props.selectedHouse, "houseDetail", []))}</Typography>
          </div>
          <PaavaVivaram key={RaasiName.KADAGAM} data={this.filterByRaasiName(RaasiName.KADAGAM)} onClick={this.setDescription} width="20%" height={this.styles.kattam.middleHeight} selectedHouseNo={_.get(this.props.selectedHouse, "houseNo", "")}/>
        </div>
        <div name="middle-12" style={this.styles.middleRowBelow}>
          <PaavaVivaram key={RaasiName.MAKARAM} data={this.filterByRaasiName(RaasiName.MAKARAM)} onClick={this.setDescription} width="20%" height={this.styles.kattam.middleHeight} selectedHouseNo={_.get(this.props.selectedHouse, "houseNo", "")}/>
          <div style={{ ...this.styles.raasiInfo, flexDirection: 'column' }}>
            <Typography> {this.getLastPart(_.get(this.props.selectedHouse, "houseDetail", []))}</Typography>
          </div>
          <PaavaVivaram key={RaasiName.SIMMAM} data={this.filterByRaasiName(RaasiName.SIMMAM)} onClick={this.setDescription} width="20%" height={this.styles.kattam.middleHeight} selectedHouseNo={_.get(this.props.selectedHouse, "houseNo", "")}/>
        </div>
        <div name="last-row" style={this.styles.firstLastRow}>
          <PaavaVivaram key={RaasiName.DHANUSU} data={this.filterByRaasiName(RaasiName.DHANUSU)} onClick={this.setDescription} width="20%" height={this.styles.kattam.topHeight} selectedHouseNo={_.get(this.props.selectedHouse, "houseNo", "")}/>
          <PaavaVivaram key={RaasiName.VIRUCHAGAM} data={this.filterByRaasiName(RaasiName.VIRUCHAGAM)} onClick={this.setDescription} width="30%" height={this.styles.kattam.topHeight} selectedHouseNo={_.get(this.props.selectedHouse, "houseNo", "")}/>
          <PaavaVivaram key={RaasiName.THULAM} data={this.filterByRaasiName(RaasiName.THULAM)} onClick={this.setDescription} width="30%" height={this.styles.kattam.topHeight} selectedHouseNo={_.get(this.props.selectedHouse, "houseNo", "")}/>
          <PaavaVivaram data={this.filterByRaasiName(RaasiName.KANNI)} onClick={this.setDescription} width="20%" height={this.styles.kattam.topHeight} selectedHouseNo={_.get(this.props.selectedHouse, "houseNo", "")}/>
          <div>
            <span>{this.filterRaasi}</span>
          </div>
        </div>
      </div>
    );
  }
}
