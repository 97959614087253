import { Button, FormControl, Input, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { Component } from "react";

export default class SearchLordOnNthHouse extends Component {
  state = {
    planets: [],
    planetCombination: "OR",
    house:"",
    splHouse: null,
    lordHouseNo:""
  };

  styles = {
    container: {
      margin: "10px",
      minWidth: "300px",
    },
  };

  handleLordHouseChange = (e) => this.setState({ lordHouseNo: e.target.value });
  handleHouseChange = (e) => this.setState({ house: e.target.value });
  handleSplHouseChange = (e) => this.setState({ splHouse: e.target.value });
  search = () => {this.props.actions.searchByLordOnHouse(this.createRequest());this.props.kattamActions.resetCurrentUser();}
  reset = () => this.setState({ planets: [], house: "", splHouse: null,lordHouseNo:"" });

  createRequest = () => {
    let req = {
      planets: this.state.planets,
      planetCombination: this.state.planetCombination,
      lordHouseNo: this.state.lordHouseNo,
      splHouse: this.state.splHouse,
      house: this.state.house,
    };
    return req;
  }


  render() {
    return (
      <div style={{ padding:'10px',  margin: "10px" ,display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", flexWrap:'wrap', border: "1px solid blue" }}>
        <FormControl style={{width: "160px"}} >
          <InputLabel id="lord-simple-select-label">Lord Of House</InputLabel>
          <Select labelId="lord-simple-select-label" id="lord-simple-select" onChange={this.handleLordHouseChange} value={this.state.lordHouseNo} input={<Input />}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((p) => (
              <MenuItem value={p}>{p}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <InputLabel style={{ marginTop: "24px", marginLeft: "10px", marginRight: "10px" }}>Is</InputLabel>
        <FormControl style={{width: "160px"}} >
          <InputLabel id="on-house-simple-select-label">On Houses</InputLabel>
          <Select labelId="on-house-simple-select-label" id="on-house-select" onChange={this.handleHouseChange} value={this.state.house} input={<Input />}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((p) => (
              <MenuItem value={p}>{p}</MenuItem>
            ))}
          </Select>
          <FormControl style={{width: "160px"}} >
            <InputLabel id="demo-spl-house-label">On Spl.Houses</InputLabel>
            <Select labelId="demo-spl-house-label" id="demo-spl-select" onChange={this.handleSplHouseChange} value={this.state.splHouse} input={<Input />}>
              {["LAGNAM", "KENDIRAM", "TRIKONAM", "MAARAKAM", "PAATHAKAM"].map((p) => (
                <MenuItem value={p}>{p}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormControl>

        <Button color="secondary" style={{ marginLeft: "20px", marginTop: "10px", width: "100px" }} variant="outlined" onClick={this.search}>
          Search
        </Button>
        <Button color="secondary" style={{ marginLeft: "5px", marginTop: "10px", width: "100px" }} variant="outlined" onClick={this.reset}>
          RESET
        </Button>
      </div>
    );
  }
}
