import { Button, FormControl, Input, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Planets, { bonds } from "../common/Planets";

class SearchPlanetBondWithRaasi extends Component {


  styles = {
    container: {
      margin: "10px",
      minWidth: "300px",
    },
  };

  state = {
    planets: [],
    planetCombination: "AND",
    lordHouseNo: "",
    splHouse: null,
    house: "",
    bondTypes: []
  };

  handlePlanetsChange = (event) => this.setState({ planets: [...event.target.value] });
  handleHouseChange = (e) => this.setState({ house: e.target.value });
  handlePlanetCombination = (e) => this.setState({ planetCombination: e.target.value });
  handleSplHouseChange = (e) => this.setState({ splHouse: e.target.value });
  reset = () => this.setState({ bondTypes: [], planets: [], house: "", splHouse: null });
  search = () => { this.props.actions.searchByPlanetBondWithRaasi(this.createRequest()); this.props.kattamActions.resetCurrentUser(); }

  handleBondChange = (event) => this.setState({ bondTypes: [...this.state.bondTypes, ...event.target.value] });

  createRequest = () => {
    let req = {
      planets: this.state.planets,
      planetCombination: this.state.planetCombination,
      lordHouseNo: this.state.lordHouseNo,
      splHouse: this.state.splHouse,
      house: this.state.house,
      bondTypes: this.state.bondTypes
    };
    return req;
  };

  render() {
    let { classes } = this.props;
    let planets = Object.values(Planets);
    return (
      <div style={{ padding: '10px', margin: "10px", display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", flexWrap: 'wrap', border: "1px solid black" }}>
        <div style={{ padding: "10px", display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", flexWrap: 'wrap' }}>
          <FormControl className={classes.container}>
            <InputLabel id="planets-simple-select-label">Planets</InputLabel>
            <Select labelId="planets-simple-select-label" id="planets-select" multiple onChange={this.handlePlanetsChange} value={this.state.planets} input={<Input />}>
              {planets.map((p) => (
                <MenuItem value={p}>{p}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ width: "150px" }}>
            <InputLabel id="planet-comb-simple-select-label">Planet Combination</InputLabel>
            <Select labelId="planet-comb-simple-select-label" id="planet-comb-select" onChange={this.handlePlanetCombination} value={this.state.planetCombination} input={<Input />}>
              {["AND", "OR"].map((p) => (
                <MenuItem value={p}>{p}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <InputLabel style={{ marginTop: "24px", marginLeft: "10px", marginRight: "10px" }}>AND</InputLabel>
          <FormControl style={this.styles.container}>
            <InputLabel id="planet2-simple-select-label">Bond(Or)</InputLabel>
            <Select labelId="planet2-simple-select-label" id="planet2-simple-select" multiple onChange={this.handleBondChange} value={this.state.bondTypes} input={<Input />}>
              {bonds.map((p) => (
                <MenuItem value={p}>{p}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button color="secondary" style={{ marginLeft: "20px", marginTop: "10px", width: "100px" }} variant="outlined" onClick={this.search}>
            Search
          </Button>
          <Button color="secondary" style={{ marginLeft: "5px", marginTop: "10px", width: "100px" }} variant="outlined" onClick={this.reset}>
            RESET
          </Button>
        </div>
      </div>
    );
  }
}
const styles = () => ({
  root: {
    backgroundColor: "red",
  },
  container: {
    margin: "10px",
    width: "auto",
    minWidth: "300px",
  },
});
export default withStyles(styles, { withTheme: true })(SearchPlanetBondWithRaasi);
