import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import RaasiName from "../KattamConstants";
import PlanetsBond from "./PlanetsBond";
import { Accordion, AccordionDetails, AccordionSummary, Button, ClickAwayListener, colors, makeStyles, Switch, Tooltip, Typography } from "@material-ui/core";
import Switcher from "../../common/Switcher";

export default class PlanetsBondKattam extends Component {
  state = {};

  styles = {
    container: {
      maxWidth: "650px",
      minWidth: "350px",
      padding: "5px",
      backgroundColor: "#FFF",
    },
    firstLastRow: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      backgroundColor: "#FFFACD",
    },
    middleRow: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      backgroundColor: "#FFFACD",
    },
    raasiInfo: {
      width: "60%",
      margin: ".25px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgb(203 182 255)",
    },
  };

  filterByRaasiName = (raasiName) => {
    let filteredRaasi = this.props.raasiBand && this.props.raasiBand.length && this.props.raasiBand.filter((s) => s.raasi === raasiName);
    return filteredRaasi ? filteredRaasi[0] : {};
  };

  filterRaasi = () => {
    const raasiBand = this.props.data.raasiBand;
    raasiBand.forEach((r) => {
      console.log("Raasi: ", r.raasi);
    });
  };

  getGunam = () => {
    let filteredRaasi = this.props.data && this.props.data.length && this.props.data.filter((s) => s.lagnam);
    return filteredRaasi && filteredRaasi.length ? filteredRaasi[0].gunam : "";
  };

  render() {
    if (!this.props.raasiBand) {
      return;
    }
    return (
      <div style={this.styles.container}>
        <div name="first-row" style={this.styles.firstLastRow}>
          <PlanetsBond data={this.filterByRaasiName(RaasiName.MEENAM)} width="20%" height="80px" />
          <PlanetsBond key={RaasiName.MESAM} data={this.filterByRaasiName(RaasiName.MESAM)} width="30%" height="80px" />
          <PlanetsBond key={RaasiName.RISABAM} data={this.filterByRaasiName(RaasiName.RISABAM)} width="30%" height="80px" />
          <PlanetsBond key={RaasiName.MITHUNAM} data={this.filterByRaasiName(RaasiName.MITHUNAM)} width="20%" height="80px" />
        </div>
        <div name="middle-1" style={this.styles.middleRow}>
          <PlanetsBond key={RaasiName.KUMBAM} data={this.filterByRaasiName(RaasiName.KUMBAM)} width="20%" height="70px" />
          <div style={{...this.styles.raasiInfo, flexDirection: 'column'}}><FormattedMessage id={"PLANETS_BOND"} />
            <div style={{fontSize:'small'}}>
            <span>x <FormattedMessage id={"ENEMY"} /></span>
            <span style={{ marginLeft: "10px" }}>= <FormattedMessage id={"SAMAM"} /></span>
            <span style={{ marginLeft: "10px" }}>+ <FormattedMessage id={"NATPU"} /></span>
            </div>
          </div>
          <PlanetsBond key={RaasiName.KADAGAM} data={this.filterByRaasiName(RaasiName.KADAGAM)} width="20%" height="70px" />
        </div>
        <div name="middle-12" style={this.styles.middleRow}>
          <PlanetsBond key={RaasiName.MAKARAM} data={this.filterByRaasiName(RaasiName.MAKARAM)} width="20%" height="70px" />
          <div style={this.styles.raasiInfo}>
            <Switcher />
          </div>
          <PlanetsBond key={RaasiName.SIMMAM} data={this.filterByRaasiName(RaasiName.SIMMAM)} width="20%" height="70px" />
        </div>
        <div name="last-row" style={this.styles.firstLastRow}>
          <PlanetsBond key={RaasiName.DHANUSU} data={this.filterByRaasiName(RaasiName.DHANUSU)} width="20%" height="80px" />
          <PlanetsBond key={RaasiName.VIRUCHAGAM} data={this.filterByRaasiName(RaasiName.VIRUCHAGAM)} width="30%" height="80px" />
          <PlanetsBond key={RaasiName.THULAM} data={this.filterByRaasiName(RaasiName.THULAM)} width="30%" height="80px" />
          <PlanetsBond data={this.filterByRaasiName(RaasiName.KANNI)} width="20%" height="80px" />
          <div>
            <span>{this.filterRaasi}</span>
          </div>
        </div>
      </div>
    );
  }
}
