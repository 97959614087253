import * as LocalService from "./LocalStoreService";
import _ from "lodash";

const getAPIUrl = () => {
	let domainUrl = new URL(window.location.href).origin;
	domainUrl = domainUrl.replace("www.","");
	domainUrl = domainUrl.replace("https://","https://api.");
	domainUrl = domainUrl + "/api";
	domainUrl = domainUrl.replace("localhost:3000","localhost:8080");
	console.log("domainUrl :" + domainUrl);
	return domainUrl;
}



// const SERVER_URL= _.get(process, "env.API_URL", "http://localhost:8080/api");
 const SERVER_URL = getAPIUrl(); //_.get(process, "env.API_URL", "https://api.raasi.app:8443/api");


export const get = (url, isExternal = false, needHeader = true) => {
  let reqUrl = isExternal ? url : SERVER_URL + url;
  let body = needHeader ? { method: "GET", headers: getHeaders() } : { method: "GET" };
  return fetch(reqUrl, body)
    .then(res => (res), onFailed)
    // .then((text) => {
    //   return text && text.length ? JSON.parse(text) : {};
    // })
    .catch((err) => console.log("errr :", err))
};

export const post = (url, data, isExternal = false, needHeader = true) => {
  let reqUrl = isExternal ? url : SERVER_URL + url;
  let body = needHeader ? { method: "POST", headers: getHeaders(), body: JSON.stringify(data) } : { method: "POST", body: JSON.stringify(data) };

  return fetch(reqUrl, body)
    .then((res) => (res), onFailed)
    .catch((err) => console.log("errr :", err));
}

export const del = (url, data) =>
  fetch(SERVER_URL + url, {
    method: "DELETE",
    headers: getHeaders(),
  })
    .then((res) => (res), onFailed)
    // .then((text) => {
    //   return text.length ? JSON.parse(text) : {};
    // })
    .catch((err) => console.log("errr :", err));

const onFailed = (err) => console.log("Error :", err);

const checkSuccess = (response) => {
  if (response && response.ok) {
    return response.text();
  } else if (response && (response.status === 400 || response.status === 409)) {
    console.error("Bad Request :", response);
    return response.text();
  } else if (response && response.status === 401) {
    console.error("Unauthorized");
    return response.text();
  }
};

const getToken = () => {
  let user = LocalService.getGoogleUser();
  // let token = _.get(user, "_token.accessToken", "");//FB
  let token = _.get(user, "accessToken", "");
  return token;
};

const getHeaders = () => {
  return { authorization: "Bearer " + getToken(), "content-type": "application/json" };
};
