import LifeEventActionType from "./LifeEventActionType";

export const addLifeEvent = (userId,event) => {
  return (dispatch) =>
    dispatch({
      type: LifeEventActionType.ADD_LIFE_EVENT,
      userId: userId,
      event: event,
    });
};

export const deleteLifeEvent = (userId) => {
  return (dispatch) =>
    dispatch({
      type: LifeEventActionType.DELETE_LIFE_EVENT,
      userId: userId
    });
};

export const getLifeEvent = (userId) => {
  return (dispatch) =>
    dispatch({
      type: LifeEventActionType.GET_LIFE_EVENTS,
      userId: userId
    });
};

export const resetRecentlyAddedMsg = () => {
  return (dispatch) =>
    dispatch({
      type: LifeEventActionType.RESET_RECENTLY_ADDED
    });
};


export const getEmotionTypes = () => {
    return (dispatch) =>
      dispatch({
        type: LifeEventActionType.GET_EMOTION_TYPES
      });
  };


  export const getEventCategories = () => {
    return (dispatch) =>
      dispatch({
        type: LifeEventActionType.GET_EVENT_CATEGORIES
      });
  };
  