import React, { Component } from "react";
import Raasi from "./Raasi";
import RaasiName from "../KattamConstants";
import * as _ from 'lodash';
import moment from 'moment';
export default class RaasiKattam extends Component {
  state = {};

  styles = {
    container: {
      maxWidth: "650px",
      minWidth: "350px",
      padding: "5px",
      backgroundColor: "#FFF",
    },
    firstLastRow: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      backgroundColor: "#FFFACD",
    },
    middleRow: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      backgroundColor: "#FFFACD",
    },
    raasiInfo: {
      width: "60%",
      margin: ".25px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#b2ebf2",
      flexWrap: "wrap",
      flexDirection: "column"
    },
    kattamStyle: {
      width: "80%",
      padding: 0,
      margin: 0,
      backgroundColor: "#FFDE00",
      color: "#333",

      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  filterByRaasiName = (raasiName) => {
    let filteredRaasi =
      this.props.raasiBand &&
      this.props.raasiBand.length &&
      this.props.raasiBand.filter((s) => s.raasi === raasiName);
    return filteredRaasi ? filteredRaasi[0] : {};
  };

  filterRaasi = () => {
    const raasiBand = this.props.data.raasiBand;
    raasiBand.forEach((r) => {
      console.log("Raasi: ", r.raasi);
    });
  };

  formatDt = (dt, zoneId) => {
    // try{
    //   let formatted = moment(dt).format("ddd, MMM Do YYYY, h:mm a");
    //   return formatted === "Invalid date" ? this.props.data.dobStr : formatted ;
    // }
    // catch(err){
    //   return this.props.data.dobStr;
    // }
    let newDate = moment(this.props.data.dobStr); //.format("ddd, MMM Do YYYY, h:mm a");
    // console.log("newDate :" +  newDate + " this.props.data.dobStr:" + this.props.data.dobStr);
    return newDate.isValid()? newDate.format("ddd, MMM Do YYYY, h:mm a") : this.props.data.dobStr ;
    
  };

  getGunam = () => {
    let filteredRaasi =
      this.props.data &&
      this.props.data.length &&
      this.props.data.filter((s) => s.lagnam);
    return filteredRaasi && filteredRaasi.length ? filteredRaasi[0].gunam : "";
  };

  getAddress = () => {
    // console.log("_.get(this.props,data.birthLocation) ;", _.get(this.props,"data.birthLocation"));
    return _.get(this.props, "data.birthLocation.address", "");
  };
  render() {
    if (!this.props.raasiBand) {
      return;
    }
    return (
      <div style={this.styles.container}>
        <div name="first-row" style={this.styles.firstLastRow}>
          <Raasi
            data={this.filterByRaasiName(RaasiName.MEENAM)}
            width="20%"
            height="80px"
          />
          <Raasi
            key={RaasiName.MESAM}
            data={this.filterByRaasiName(RaasiName.MESAM)}
            width="30%"
            height="80px"
          />
          <Raasi
            key={RaasiName.RISABAM}
            data={this.filterByRaasiName(RaasiName.RISABAM)}
            width="30%"
            height="80px"
          />
          <Raasi
            key={RaasiName.MITHUNAM}
            data={this.filterByRaasiName(RaasiName.MITHUNAM)}
            width="20%"
            height="80px"
          />
        </div>
        <div name="middle-1" style={this.styles.middleRow}>
          <Raasi
            key={RaasiName.KUMBAM}
            data={this.filterByRaasiName(RaasiName.KUMBAM)}
            width="20%"
            height="70px"
          />
          <div style={this.styles.raasiInfo}><div>{this.props.profile.name}</div>
            <div style={{ fontSize: "smaller" }}>{this.formatDt(this.props.data.dob, _.get(this.props.data, "timeZoneId"))}</div>
            <div style={{ fontSize: "x-small", textAlign: "center" }}>{this.getAddress()}</div>

          </div>
          <Raasi
            key={RaasiName.KADAGAM}
            data={this.filterByRaasiName(RaasiName.KADAGAM)}
            width="20%"
            height="70px"
          />
        </div>
        <div name="middle-12" style={this.styles.middleRow}>
          <Raasi
            key={RaasiName.MAKARAM}
            data={this.filterByRaasiName(RaasiName.MAKARAM)}
            width="20%"
            height="70px"
          />
          <div style={this.styles.raasiInfo}>{this.getGunam()}</div>
          <Raasi
            key={RaasiName.SIMMAM}
            data={this.filterByRaasiName(RaasiName.SIMMAM)}
            width="20%"
            height="70px"
          />
        </div>
        <div name="last-row" style={this.styles.firstLastRow}>
          <Raasi
            key={RaasiName.DHANUSU}
            data={this.filterByRaasiName(RaasiName.DHANUSU)}
            width="20%"
            height="80px"
          />
          <Raasi
            key={RaasiName.VIRUCHAGAM}
            data={this.filterByRaasiName(RaasiName.VIRUCHAGAM)}
            width="30%"
            height="80px"
          />
          <Raasi
            key={RaasiName.THULAM}
            data={this.filterByRaasiName(RaasiName.THULAM)}
            width="30%"
            height="80px"
          />
          <Raasi
            data={this.filterByRaasiName(RaasiName.KANNI)}
            width="20%"
            height="80px"
          />
          <div>
            <span>{this.filterRaasi}</span>
          </div>
        </div>
      </div>
    );
  }
}
