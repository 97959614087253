import React, { Component, forwardRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _, { multiply } from 'lodash';
import * as LocalStore from '../../../LocalStoreService';
import * as PredictionActions from "../PredictionAction";
import MaterialTable from "material-table";
import { AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, AddCircleOutline, SaveAlt, Search, ViewColumn } from "@material-ui/icons";
import { planetColors, planetForeColors } from "../../common/Planets";
import { Button, Chip, TextField, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { JsonEditor } from "../JsonEditor";
import FullScreenDialog from "../FullScreenDialog";
import RuleTemplate from "./RuleTemplate";

class GenericAstroRulesContainer extends Component {

    componentDidMount() {
        this.props.actions.getAllPredictions();
    }
    state = {
        tense: 0,
        expanded: false
    }
    getUser = () => {
        return this.props.user.name;
    };

    isAdmin = () => {
        let user = LocalStore.getGoogleUser();
        return user.role === "ADMIN";
    }

    tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    getMicroRule = r => {
        if (r.and) return r.and.map(rr => JSON.stringify(rr));
        if (r.or) return r.or.map(rr => JSON.stringify(rr));
        if (r.xand) return r.xand.map(rr => JSON.stringify(rr));
        if (r.xor) return r.xor.map(rr => JSON.stringify(rr));
    }

    getMicroRulee = r => {
        if (r.and) return JSON.stringify(r.and)
        if (r.or) return JSON.stringify(r.or)
        if (r.xand) return JSON.stringify(r.xand)
        if (r.xor) return JSON.stringify(r.xor)
    }

    getMicroRulesCondition = r => {
        if (r.and) return 'AND';
        if (r.or) return "OR";
        if (r.xand) return 'XAND';
        if (r.xor) return 'XOR';
    }

    getMicroRules = r => {
        // return <TextField>{this.getMicroRule(r)}</TextField>
        return <div>
            <div style={{ fontWeight: "bold" }}>{this.getMicroRulesCondition(r)}</div>
            <div>{this.getMicroRule(r)}</div>
        </div>
    }
    editRules = (r) => () => {
        this.props.actions.setRuleToEdit(r);
        this.props.actions.setRuleDialogOpen(true);
    }

    newRule = () => {
        this.props.actions.setRuleToEdit(RuleTemplate.template);
        this.props.actions.setRuleDialogOpen(true);
    }

    getRawRules = r => {
        return <Button onClick={this.editRules(r)}> Edit Rule</Button>
        // return <JsonEditor rule={r}/>
    }


    getColor = p => planetColors[p];
    getForeColor = p => planetForeColors[p];
    getPlanetShortName = (name) => name + "_SHORT";
    getChipPlanet = d => <Chip label={<div><FormattedMessage id={this.getPlanetShortName(d.toUpperCase())} /></div>}
        style={{ margin: '4px', width: '50px', backgroundColor: this.getColor(d.toUpperCase()), color: this.getForeColor(d.toUpperCase()), border: '1px solid grey' }} />;
    getChipHouse = d => <Chip label={<div><FormattedMessage id={d} /></div>}
        style={{ margin: '4px', width: '50px', backgroundColor: this.getColor(d), color: this.getForeColor(d), border: '1px solid grey' }} />;


    getInfluencers = i => i && <div>{i.map(ii => this.getChipPlanet(ii.planet))}</div>;
    setData = (newData, all) => {
        console.log("UPDATED :", JSON.stringify(newData));
        // this.setState({ data: all });
    }
    getAppliesTo = u => {
        // if (r.users)
        //     console.log("r.users :" + r.users.length);
        return u && u.map(uu => <div>{uu + ", "}</div>)
    };

    getAppliesToCount = r => _.get(r.users, "length", 0);

    


    render() {
        return <div style={{margin: '10px'}}>
            <Typography variant="h6" style={{ textAlign: "center", flexGrow: "2" }}>Manage AstroRule</Typography>
            <Button style={{marginLeft: '20px'}} variant="contained" startIcon={<AddCircleOutline />} color="default" size="large" onClick={this.newRule}>
                    New Rule
                </Button>
            <FullScreenDialog open={this.props.isRuleDialogOpen} rule={this.props.ruleToEdit} actions={this.props.actions} />
            {this.props.loading && <div>Loading..</div>}
            {!this.props.loading && <div>
                <MaterialTable
                    icons={this.tableIcons}
                    title={` `}
                    // editable={{
                    //     onRowAdd: newData =>
                    //         new Promise((resolve, reject) => {
                    //             setTimeout(() => {
                    //                 this.setState({ data: [...this.state.data, newData] });

                    //                 resolve();
                    //             }, 1000)
                    //         }),
                    //     onRowUpdate: (newData, oldData) =>
                    //         new Promise((resolve, reject) => {
                    //             setTimeout(() => {
                    //                 const dataUpdate = [...this.state.data];
                    //                 const index = oldData.tableData.id;
                    //                 dataUpdate[index] = newData;
                    //                 this.setData(newData, [...dataUpdate]);

                    //                 resolve();
                    //             }, 1000)
                    //         })
                    // }}
                    columns={[
                        { title: 'Reference', field: 'reference', render: row => row.reference, searchable: true, type: "string" },
                        { title: 'Positivity', field: 'positivity', render: row => row.positivity ? 'positive' : 'negative', searchable: false },
                        { title: 'Type', field: 'type', render: row => row.type ? row.type : 'AllTime', searchable: false },
                        { title: 'Count', field: 'count', render: row => row.count, searchable: false },
                        { title: 'Names', field: 'names', render: row => row.names, searchable: true, type: "string" },

                        { title: 'Tags', field: 'tags', render: row => JSON.stringify(row.tags), searchable: true },
                        { title: 'Predictions', field: 'predictions', render: row => JSON.stringify(row.predictions), searchable: true, type: "string" },
                        // { title: 'CountFrom', field: 'countFrom', render: row => JSON.stringify(row.countFrom) , searchable: false, type: "string"},
                        // { title: 'MicroRules', field: 'microRules', render: row => this.getMicroRules(row) , searchable: true, type: "string"},
                        { title: 'Raw', field: 'raw', render: row => this.getRawRules(row), searchable: false, type: "string" }

                    ]}

                    data={_.get(this.props.genericAstroRules, "rules")}

                    options={{
                        search: true,
                        detailPanelType: "multiple",
                        padding: "default",
                        pageSize: 100,
                        paging: false,
                        headerStyle: {
                            backgroundColor: '#6eb53f',
                            color: '#FFF'
                        },
                        addRowPosition: "first"
                    }}
                    style={{ fontSize: "small" }}
                />
            </div>}
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        genericAstroRules: _.get(state.predictions, "genericAstroRules", []),
        ruleToEdit: _.get(state.predictions, "ruleToEdit", {}),
        isRuleDialogOpen: _.get(state.predictions, "isRuleDialogOpen", []),
        loading: _.get(state.predictions, "loading", []),
        user: _.get(state.raasi, "currentUser", {})
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(PredictionActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GenericAstroRulesContainer));
