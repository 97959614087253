import { Accordion, AccordionDetails, AccordionSummary, Button, ClickAwayListener, colors, makeStyles, Switch, Tooltip, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Info from '@material-ui/icons/Info';

import moment from 'moment';
import * as _ from 'lodash';
import { lte } from "lodash";
import TreeView from '@material-ui/lab/TreeView';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import Chip from '@material-ui/core/Chip';
import { planetColors, planetForeColors, kaaragans } from '../common/Planets'
import { formatDate, formatDateSimple, getBond, getBondFromLagnam, getBondToLagnam, getAge, getColor, getForeColor, getPlanetShortName, getReverseBond } from './DasaUtil';
import { withStyles } from "@material-ui/styles";
import * as LocalService from '../../LocalStoreService';





class DasaBhukthiTree extends Component {

  state = { loading: false, checked: false };
  componentDidMount() {
    this.comps = this.props.dasas && this.props.dasas.map((d, i) => this.getDasa(d, i));
  }

  state = {
    dasas: [],
    dasaNodeIds: [],
    putthiNodeIds: [],
    andharamNodeIds: [],
    sootchamamNodeIds: []

  }

  getDasa = (d, di) => {
    this.setState({ dasaNodeIds: [...this.state.dasaNodeIds, di.toString()] });
    return d &&
      <TreeItem
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{ backgroundColor: "#c6e2ff" }}
        label={this.getDasaContent(d, di)}
        nodeId={di.toString()}
        selected={true}
        key={d + di}
        name={di}
      >
        {d.putthis && d.putthis.map((d, i) => this.getPutthi(d, (i + 100)))}
      </TreeItem>
  }

  getPutthi = (d, pi) => {
    this.setState({ putthiNodeIds: [...this.state.putthiNodeIds, pi] });
    return d && <TreeItem
      style={{ backgroundColor: "#c3ffc3" }}
      label={this.getDasaContent(d, pi)}
      nodeId={pi.toString()}
      selected={true}
    >
      {d.andharam && d.andharam.map((d, i) => this.getAndharam(d, (i + 200)))}
    </TreeItem>
  }

  getAndharam = (d, ai) => {
    this.setState({ andharamNodeIds: [...this.state.andharamNodeIds, ai] });
    return <TreeItem
      expandIcon={<ExpandMoreIcon />}
      style={{ backgroundColor: "#fdd7fc" }}
      label={this.getDasaContent(d, ai)}
      nodeId={ai.toString()}
    >{d.sootchamam && d.sootchamam.map((d, i) => this.getSootchamam(d, (i + 300)))}
    </TreeItem>;
  }

  getSootchamam = (d, si) => {
    this.setState({ sootchamamNodeIds: [...this.state.sootchamamNodeIds, si] });
    return <TreeItem style={{ backgroundColor: "#feffba" }} label={this.getDasaContent(d, si)} nodeId={si.toString()}>
    </TreeItem>;
  }



  // getTooltip = p => {
  //   let kaaragan = kaaragans[p.planet];
  //   return kaaragan ? `வேதகன்:${this.props.intl.formatMessage({ id: kaaragan.vedhagan })} / பாசகன்:${this.props.intl.formatMessage({ id: kaaragan.paasagan })} / போதகன்:${this.props.intl.formatMessage({ id: kaaragan.podhagan })} / காரகன்:${this.props.intl.formatMessage({ id: kaaragan.kaaragan })}` : 'N/A';
  // }

  getTooltip = p => {
    let kaaragan = kaaragans[p.planet];
    return kaaragan ? <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}><div style={{ color: "red" }}>{this.getHeader(`வேதகன் : `)}</div>{this.props.intl.formatMessage({ id: kaaragan.vedhagan })}</div>
      <div style={{ display: 'flex' }}><div style={{ color: "#c908c2" }}>{this.getHeader(`பாசகன் : `)}</div><div>{this.props.intl.formatMessage({ id: kaaragan.paasagan })}</div></div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}><div style={{ color: "#5329bb" }}>{this.getHeader(`காரகன் : `)}</div><div>{this.props.intl.formatMessage({ id: kaaragan.podhagan })}</div></div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}><div style={{ color: "#048191" }}>{this.getHeader(`போதகன் : `)}</div><div>{this.props.intl.formatMessage({ id: kaaragan.kaaragan })}</div></div>
    </div> : "n/a";
  }

  getHeader = t => <div style={{ fontWeight: 'bold', marginRight: '5px' }}>{t}</div>;
  getHelp = () => {
    return <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}><div style={{ color: "red" }}>{this.getHeader(`வேதகன் : `)}</div><div> வேதனை தருபவன்</div></div>
      <div style={{ display: 'flex' }}><div style={{ color: "#c908c2" }}>{this.getHeader(`பாசகன் : `)}</div><div> நன்மையை மட்டும் தரும் பாசக்காரன்</div></div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}><div style={{ color: "#5329bb" }}>{this.getHeader(`காரகன் : `)}</div><div> கர்ம வினையின் பயன் தருபவன்</div><div>நல்லது செய்திருந்தால் நன்மை விளையும் காலம்</div></div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}><div style={{ color: "#048191" }}>{this.getHeader(`போதகன் : `)}</div><div style={{ display: 'flex', flexWrap: 'wrap' }}> நன்மை செய்பவன்</div><div>தசாபோதகன் புத்தியிலும் , புத்திபோதகன் அந்தரத்திலும், அந்தரபோதகன் சூட்சமத்திலும்</div></div>
    </div>;
  }

  openTooltip = (p) => {
    let o = {};
    o['tip' + p] = true;
    // console.log(" o ", o);
    this.setState(o);
    // console.log("state :", this.state);
  }
  handleTooltipClose = (p) => {
    let o = {};
    o['tip' + p] = false;
    this.setState(o);
  }

  getDasaSnippet = (d, i) => {
    let color = getAge(this.props.jaadhagam.dob, formatDate(d.endDate)) < 0 ? 'grey' : 'black';
    return <div style={{ display: "flex", fontSize: 'small', flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-evenly", alignItems: "center", width: "100%", color: color }}>
      <div style={{ fontSize: 'x-small', textAlign: "left", minWidth: '40px' }}>{getAge(this.props.jaadhagam.dob, formatDate(d.startDate))}-{getAge(this.props.jaadhagam.dob, formatDate(d.endDate))}</div>
      <div style={{ fontSize: 'small' }}>{formatDateSimple(d.startDate)}</div>
      <div>
        <InputWrapper>

          <HtmlTooltip title={this.getTooltip(d)} PopperProps={{
            disablePortal: true,
            placement: "right",
            color: "blue"
          }}>
            <span>{getBondFromLagnam(d)}</span>
          </HtmlTooltip>


          <HtmlTooltip title={this.getTooltip(d)} PopperProps={{
            disablePortal: true,
            placement: "right",
            color: "blue"
          }}
            open={this.state['tip' + i]}>

            <Chip label={<div><FormattedMessage id={getPlanetShortName(d.planet)} />
              {!this.state.checked && <span>{getBond(d)}</span>}
              {!!this.state.checked && <span>{getReverseBond(d)}</span>}

            </div>}
              onClick={() => this.openTooltip(i)}
              style={{ margin: '4px', width: '50px', backgroundColor: getColor(d.planet), color: getForeColor(d.planet), border: '1px solid grey' }}
            />
          </HtmlTooltip>

          <HtmlTooltip title={this.getTooltip(d)} PopperProps={{
            disablePortal: true,
            placement: "right",
            color: "blue"
          }}
          >

            <span>{getBondToLagnam(d)}</span>

          </HtmlTooltip>




        </InputWrapper>
      </div>
      <div style={{ fontSize: 'small' }}>
        <FormattedMessage id={d.dasaType} />
      </div>
      <div style={{ fontSize: 'small' }}>{formatDateSimple(d.endDate)}</div>
    </div>
  }

  getDasaContent = (d, i) => this.getDasaSnippet(d, i);

  // c = <ClickAwayListener onClickAway={() => this.handleTooltipClose(i)}>
  //   <Tooltip title={this.getTooltip(d)} PopperProps={{
  //     disablePortal: true,
  //   }}
  //     open={_.get(this.state, 'tip' + i, false) === true} key={i}>
  //     {this.getDasaSnippet(d, i)}
  //   </Tooltip>
  // </ClickAwayListener>;

  isAlreadyLoaded = (d, fn) => {
    let filtered = this.state.dasas.filter(fn).find(s => s.startDate === d.startDate && s.endDate === d.endDate);
    return !_.isEmpty(filtered);
  }

  handleDasaClick = d => () => {
    this.setState({ putthis: d.putthis });
  }
  handlePutthiClick = (p) => () => {
    if (!this.isAlreadyLoaded(p, this.isPutthi)) {
      this.props.actions.getAndharam(this.props.userId, this.props.current.dasa, p);
      this.setState({ dasas: [...this.state.dasas, p] });
    }
  }
  handleAndharamClick = (a) => () => {
    if (!this.isAlreadyLoaded(a, this.isAndharam)) {
      this.props.actions.getSootchamam(this.props.userId, this.props.current.dasa, this.props.current.putthi, a);
      this.setState({ dasas: [...this.state.dasas, a] });
    }

  }



  isDasa = (d) => d.dasaType === "DASA";
  isPutthi = (d) => d.dasaType === "PUTTHI";
  isAndharam = (d) => d.dasaType === "ANDHARAM";
  isSootchamam = (d) => d.dasaType === "SOOTCHAMAM";

  handleSwitchChange = () => this.setState({ 'checked': !this.state.checked });

  isAdmin = () => {
    let user = LocalService.getGoogleUser();
    return user.role === "ADMIN";
  }

  render() {
    return (
      <div style={{ border: '1px solid grey',backgroundColor: "#c6e2ff" }}>
        {this.state.loading && <div style={{ minWidth: '250px', minHeight: '250px' }}>Loading Dasa Putthi..</div>}
        {this.isAdmin() && <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', margin: '5px' }}>
          <Typography>தசா-லக்கினம்</Typography><Switch
            checked={this.state.checked}
            onChange={this.handleSwitchChange}
            color="primary"
            name="checked"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          /> <Typography>லக்கினம்-தசா</Typography>
        </div>}
        {<div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', margin: '15px',backgroundColor: "#c6e2ff" }}>
          <Typography style={{ display: 'flex', fontWeight: 'bold', fontSize: '20px', justifyContent: 'center', alignItems: 'center', flexGrow: 2 }} >தசா, புத்தி, அந்தரம், சூட்சமம்</Typography>

          <InfoTooltip title={this.getHelp()} PopperProps={{
            disablePortal: true,
            placement: "top",
            color: "blue"
          }}>
            <Info fontSize="small" style={{ color: "teal", flex: 'none' }} />
          </InfoTooltip>
        </div>}
        {!this.state.loading &&
          <Wrapper >
            <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}
              defaultExpanded={this.state.dasaNodeIds} >
              {this.comps}
            </TreeView>
          </Wrapper>}
      </div>
    );
  }
}

const Wrapper = (props) =>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      width: "400px",
      justifyContent: "flex-start"
    }}
  >
    {props.children}
  </div>

const stopPropagation = (e) => e.stopPropagation();
const InputWrapper = ({ children }) =>
  <div onClick={stopPropagation}>
    {children}
  </div>


const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 11,
  },
}));

export function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: 12,
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export const InfoTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 330,
    fontSize: 15,
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default injectIntl(DasaBhukthiTree);

