import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as raasiAction from "./KattamAction";
import * as _ from "lodash";
import * as dasaActions from "../DasaBhukthi/DasaBhukthiActions";
import * as LocalService from '../../LocalStoreService';
import { Button, Tooltip, TextField } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import RaasiKattamContainer from "./Raasi/RaasiKattamContainer";
import NatchathiramContainer from "./Natchathiram/NatchathiramContainer";
import AspectKattamContainer from "./Aspect/AspectKattamContainer";
import PlanetsBondKattamContainer from "./PlanetsBond/PlanetsBondKattamContainer";
import { PersonAdd, TrackChanges } from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import DasaBhukthiContainer from "../DasaBhukthi/DasaBhukthiContainer";
import JaadhagamInfo from "./JaadhagamInfo";
import KotcharamContainer from "./Kotcharam/KotcharamContainer";
import moment from 'moment';
import { Autocomplete } from "@material-ui/lab";
import * as lifeEventActions from "../LifeEvent/LifeEventAction";
import TransitDatesContainer from "./TransitDates/TransitDatesContainer";
import PaavaVivaramKattamContainer from "./PaavaKattam/PaavaVivaramKattamContainer";
import PlanetStrengthContainer from "./PlanetStrength/PlanetStrengthContainer";
import KarahaVivaramKattamContainer from "./KarahamKattam/KarahaVivaramKattamContainer";
import HouseStrengthContainer from "./HouseStrength/HouseStrengthContainer";

export class KattamContainer extends React.Component {
  componentDidMount() {
    this.props.actions.retrieveUsers();
  }

  // propTypes = {
  //   data: PropTypes.data,
  // };

  state = {
    showDasa: false,
    showTransitDates: false
  };

  handleClick = (e, obj) => {
    //TODO: Development testing
    if (!obj) return;
    this.props.actions.getRaasiCalculation(obj.id);
    this.props.actions.retrievePlanetStrength(obj.id);
    this.props.actions.retrieveHouseStrength(obj.id);
    this.props.actions.getDasaByDate(obj.id, moment().format('DD-MMM-YYYY'));
    this.setState({ user: obj, showDasa: false });
    this.props.actions.setCurrentUser(obj);
    this.props.actions.retrieveKotcharam(moment().format('DD-MMM-YYYY hh:mm a'));
    this.props.actions.retrieveKotcharamPlanetStrength(moment().format('DD-MMM-YYYY hh:mm a'),obj.id);
    this.props.actions.retrieveKotcharamHouseStrength(moment().format('DD-MMM-YYYY hh:mm a'),obj.id);

    this.props.actions.retrieveOrai(moment().format('DD-MMM-YYYY hh:mm a'));
    this.props.lifeEventActions.getLifeEvent(obj.id);

  };

  // handleFilter = (e) => {
  //   console.log("Filter :", e.target);
  // };
  // handleDelete = (e) => console.log("Handle Delete :", e.target);

  goToLifeEvents = () => this.props.history.push("/life-events");

	getBhathakathipathyEffect = () => this.props.actions.getUserPredictionsOfPPReferences(this.props.currentUser.id, ["slokam-52",
		"slokam-52.1","slokam-53","slokam-53.1","slokam-54","slokam-54.1"
	]);//23-33 - Bhathakathipathi-Lagnam


  getPredictions = (e) => {
    this.props.actions.getUserPredictions(this.props.currentUser.id);
    this.props.actions.getUserPredictionsOfPP(this.props.currentUser.id);

    this.props.actions.getUserKotcharamPredictionsOfPP(this.props.currentUser.id);
    this.props.actions.getUserKotcharamPredictionsOfPPForPast(this.props.currentUser.id);
    this.props.actions.getUserKotcharamPredictionsOfPPForFuture(this.props.currentUser.id);

    this.props.actions.setCurrentUser(this.state.user);
    this.props.history.push("/predictions");
  };
  getPredictionsOfPP = (e) => {
    this.props.actions.getUserPredictionsOfPP(this.props.currentUser.id);

    this.props.actions.getUserKotcharamPredictionsOfPP(this.props.currentUser.id);
    this.props.actions.getUserKotcharamPredictionsOfPPForPast(this.props.currentUser.id);
    this.props.actions.getUserKotcharamPredictionsOfPPForFuture(this.props.currentUser.id);

    this.props.actions.setCurrentUser(this.state.user);
    this.props.history.push("/predictions-pp");
  };

  isAdmin = () => {
    let user = LocalService.getGoogleUser();
    return user.role === "ADMIN";
  }

  isPowerUser = () => {
    let user = LocalService.getGoogleUser();
    return user.role === "POWER_USER" || user.role === "ADMIN";
  }

  addUser = () => {
    this.props.history.push("/add-user");
  };

  handleDasaClick = () => {
    this.setState({ showDasa: true });
    this.props.dasaActions.getDasa(this.props.currentUser.id);
  }

  handleTransitDates = () => {
    this.setState({ showTransitDates: true });
    if(this.props.showOnlyYearlyPlanets){
      this.props.actions.getTransitDates("SUKKIRAN,PUDHAN,CHEVVAI,CHANDRAN");
    }else{
      this.props.actions.getTransitDates("");
    }
  }

  isShowDasa = () => !_.isEmpty(this.props.dasas) && this.props.currentUser;
  isShowTransit = () => !_.isEmpty(this.props.transitDates);

  getDasaBhukthiContainerWrapper = () => {
    if (!this.props.currentUser) return;
    return <Button color='primary' style={{ minWidth: '318px', minHeight: '250px', margin: '5px', border: '1px solid green' }} onClick={this.handleDasaClick}>
      Click here to load DasaPuthi</Button>
  }

  getTransitDatesContainerWrapper = () => {
    return <Button color='primary' style={{ minWidth: '318px', margin: '5px', border: '1px solid green' }} onClick={this.handleTransitDates}>
      Click here to load Planet Transit Dates</Button>
  }

  getDropDownLabel = (params) => {
    if (this.props.currentUser) {
      return this.props.currentUser.name;
    } else {
      return "Select Jaadhagam";
    }
  }

  render() {
    // console.log("this.props.users :", this.props.users);
    // console.log("this.props.recentlyAddedJaadhagam :", this.props.recentlyAddedJaadhagam);

    return (
      <div>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "0%",
          flexWrap: "wrap",
          marginTop: "20px",
          color: "#959191",
          fontSize: "small"
        }}>இது திருக்கணித முறையில் வடிவமைக்கபட்டுள்ளது.</div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            marginLeft: "0%",
            marginTop: "10px"
          }}
        >

          <div style={{ display: "flex", flexWrap: "wrap" }}>

            <Autocomplete
              options={this.props.users}
              onChange={this.handleClick}
              size={"medium"}
              clearOnEscape
              getOptionLabel={(option) => option.name}
              style={{ width: 300, margin: '5px' }}
              renderInput={(params) =>
                <div><TextField {...params} label={this.getDropDownLabel()} variant="outlined" />
                </div>}
              renderOption={(props) => {
                return <div>{this.isAdmin() && <div style={{ display: 'flex', flexDirection: "column" }}><div>{props.name}</div><div style={{ fontSize: "x-small", color: "grey" }}>{props.emailId}</div></div>}
                  {!this.isAdmin() && <div style={{ display: 'flex', flexDirection: "column" }}><div>{props.name}</div></div>}
                </div>;
              }}

            />

            <div style={{ margin: '5px', display: 'flex' }}>
              <Tooltip title="New Jaadhagam">
                <Button color="primary" onClick={this.addUser}>
                  <PersonAdd style={{ color: green[500] }} />
                </Button>
              </Tooltip>

              {this.props.currentUser && <Tooltip title="Daily events">
                <Button color="primary" onClick={this.goToLifeEvents} >
                  <TrackChanges style={{ color: green[500] }} />
                </Button>
              </Tooltip>}
            </div>

            {this.props.currentUser &&
              <Button color="secondary" variant="outlined" onClick={this.getPredictions}>
                Predict
              </Button>
            }
          </div>

        </div>

        {!this.props.raasiBand || this.props.raasiBand.length < 1 || !this.props.jaadhagam && <div style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          justifyContent: "flex-start",
          margin: "5px"

        }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignContent: "flex-start",
              margin: "5px",
              border: "1px solid grey"
            }}
          >
            <div>
              <JaadhagamInfo raasiBand={this.props.raasiBand} dasa={this.props.dasa} jaadhagam={this.props.data} generalOrai={this.props.generalOrai}
                kotcharam={this.props.kotcharam}
                actions={this.props.actions} />
            </div>
            <div style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}>
              <RaasiKattamContainer />
              <KotcharamContainer />
              {/* <NatchathiramContainer /> */}
              <AspectKattamContainer />
              <PlanetsBondKattamContainer />
              {this.isPowerUser() && <PlanetStrengthContainer />}
              {this.isPowerUser() && <HouseStrengthContainer />}

              {!this.state.showTransitDates && this.getTransitDatesContainerWrapper()}
              {this.state.showTransitDates && <TransitDatesContainer />}

              {!this.state.showDasa && this.getDasaBhukthiContainerWrapper()}
              {this.state.showDasa && <DasaBhukthiContainer />}
              {this.isPowerUser() && <PaavaVivaramKattamContainer />}

              {this.isPowerUser() && <KarahaVivaramKattamContainer/>}

              {/* {this.isAdmin() && <CanvasContainer text='Planets' />} */}
            </div>

          </div>

          {/* <div style={{ border: "1px solid grey" }}>
            <LifeEventSimplifiedContainer />
          </div> */}
        </div>}

      </div>

    );

  }
}

const mapStateToProps = (state) => {
  let users = _.get(state.raasi, "users", []);
  let currentUser = _.get(state.raasi, "currentUser");
  // let recent = _.get(state.user, "recentlyAddedJaadhagam");
  let allUsers = users; // recent? [...users,recent]: users;
  return {
    data: _.get(state.raasi, "sample.data", {}),
    dasa: _.get(state.raasi, "dasa"),
    generalOrai: _.get(state.raasi, "generalOrai"),
    raasiBand: _.get(state.raasi, "sample.data.raasiBand"),
    users: allUsers,
    dasas: _.get(state.dasa, "dasas", []),
    authorized: _.get(state.user, "authorized"),
    predictions: state.raasi && state.raasi.predictions ? state.raasi.predictions : {},
    currentUser: currentUser,
    kotcharam: _.get(state.raasi, "kotcharam", {}),
    showOnlyYearlyPlanets: _.get(state.raasi, "showOnlyYearlyPlanets", false)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(raasiAction, dispatch),
    lifeEventActions: bindActionCreators(lifeEventActions, dispatch),
    dasaActions: bindActionCreators(dasaActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(KattamContainer));
