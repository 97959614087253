import UserActionConstants from "./components/Users/UserActionType";
import KattamActionType from "./components/Kattam/KattamActionType";
import { getKotcharam, getDasaByDate, getRaasiCalculation, retrieveJaadhagans, getUserPredictions, deleteUserJaathagan,getUserKotcharamPredictionsOfPP, getUserPredictionsOfPPByReferences, getUserPredictionsOfPP, getOrai, getTransitDates, getUserKotcharamPredictionsOfPPForFuture, getUserKotcharamPredictionsOfPPForPast, getPaavaVivaram, retrievePaavaVivaram, retrievePlanetStrength, retrieveHouseStrength, getKotcharamPlanetStrength, getKotcharamHouseStrength } from "./components/Kattam/KattamService";
import DasaBhukthiActionConstants from "./components/DasaBhukthi/DasaBhukthiActionConstants";
import { getDasaBhukthi, retrieveAndharams, retrieveDasas, retrievePutthis, retrieveSootchamams } from "./components/DasaBhukthi/DasaBhukthiService";
import { postUserJathgan, contactUs } from "./components/Users/UserService";
import LifeEventActionType from "./components/LifeEvent/LifeEventActionType";
import { addLifeEvent, retrieveEmotionTypes, retrieveEventCategories, retrieveEvents } from "./components/LifeEvent/LifeEventService";

import { searchByPlanetsOnHouse , searchByPlanetBondWithRaasi, searchByLordIsPlanet,searchByLordOnHouse, searchByRaasiOnHouse, searchByLordWithBond, searchByDasaPutthi} from "./components/Search/SearchService";
import SearchActionType from "./components/Search/SearchActionType";
import LoginActionType from "./components/Login/LoginActionType";
import PredictionActionType from "./components/Predictions/PredictionActionType";
import {setAuthenticatedUser} from './components/Login/LoginService';
import { loginWithGoogle, getGoogleToken} from './components/Authorization/OAuth/OAuthService';
import OAuthActionType from './components/Authorization/OAuth/OAuthActionType'
import { getAndharam, getDasa, getPutthi, getSootchamam } from "./components/DasaBhukthi/DasaBhukthiActions";
import { getAllRules, saveCorrectPrediction, saveInCorrectPrediction, saveMayBePrediction, saveAstroRule } from "./components/Predictions/PredictionService";

export const serviceMap = new Map();

serviceMap.set(KattamActionType.GET_RAASI_CALCULATION, (action) => getRaasiCalculation(action.userId));
serviceMap.set(KattamActionType.GET_KOTCHARAM, (action) => getKotcharam(action.date));
serviceMap.set(KattamActionType.GET_KOTCHARAM_PLANET_STRENGTH, (action) => getKotcharamPlanetStrength(action.date, action.userId));
serviceMap.set(KattamActionType.GET_KOTCHARAM_HOUSE_STRENGTH, (action) => getKotcharamHouseStrength(action.date, action.userId));


serviceMap.set(KattamActionType.GET_DASA_BY_DATE, action => getDasaByDate(action.userId, action.date));

serviceMap.set(KattamActionType.GET_ORAI, (action) => getOrai(action.date, action.zoneId));

serviceMap.set(SearchActionType.SEARCH_BY_PLANETS_ON_HOUSE, (action) => searchByPlanetsOnHouse(action.criteria));
serviceMap.set(SearchActionType.SEARCH_BY_PLANET_BOND_WITH_RAASI, (action) => searchByPlanetBondWithRaasi(action.criteria));

serviceMap.set(SearchActionType.SEARCH_BY_LORD_IS_PLANET, (action) => searchByLordIsPlanet(action.criteria));
serviceMap.set(SearchActionType.SEARCH_BY_LORD_WITH_BOND, (action) => searchByLordWithBond(action.criteria));
serviceMap.set(SearchActionType.SEARCH_BY_DASA_PUTTHI, (action) => searchByDasaPutthi(action.criteria));

serviceMap.set(SearchActionType.SEARCH_BY_LORD_ON_HOUSE, (action) => searchByLordOnHouse(action.criteria));
serviceMap.set(SearchActionType.SEARCH_BY_RAASI_HOUSENO, (action) => searchByRaasiOnHouse(action.criteria));


serviceMap.set(LifeEventActionType.ADD_LIFE_EVENT, (action) => addLifeEvent(action.userId, action.event));
serviceMap.set(LifeEventActionType.GET_LIFE_EVENTS, (action) => retrieveEvents(action.userId));

serviceMap.set(LifeEventActionType.GET_EMOTION_TYPES, () => retrieveEmotionTypes());
serviceMap.set(LifeEventActionType.GET_EVENT_CATEGORIES, () => retrieveEventCategories());

serviceMap.set(KattamActionType.GET_USERS, () => retrieveJaadhagans());
serviceMap.set(KattamActionType.DELETE_USER_JAATHAGAN, (action) => deleteUserJaathagan(action.userId));

serviceMap.set(KattamActionType.GET_USER_PREDICTIONS, (action) => getUserPredictions(action.userId));
serviceMap.set(KattamActionType.GET_USER_PREDICTIONS_OF_PP, (action) => getUserPredictionsOfPP(action.userId));
serviceMap.set(KattamActionType.GET_USER_PREDICTIONS_OF_PP_REFERENCES, (action) => getUserPredictionsOfPPByReferences(action.userId, action.referenceIds));

serviceMap.set(KattamActionType.GET_USER_KOTCHARAM_PREDICTIONS_OF_PP, (action) => getUserKotcharamPredictionsOfPP(action.userId));

serviceMap.set(KattamActionType.GET_USER_KOTCHARAM_PREDICTIONS_OF_PP_FOR_PAST, (action) => getUserKotcharamPredictionsOfPPForPast(action.userId));
serviceMap.set(KattamActionType.GET_USER_KOTCHARAM_PREDICTIONS_OF_PP_FOR_FUTURE, (action) => getUserKotcharamPredictionsOfPPForFuture(action.userId));



serviceMap.set(PredictionActionType.POST_CORRECT_PREDICTION, (action) => saveCorrectPrediction(action));
serviceMap.set(PredictionActionType.POST_INCORRECT_PREDICTION, (action) => saveInCorrectPrediction(action));
serviceMap.set(PredictionActionType.POST_MAYBE_PREDICTION, (action) => saveMayBePrediction(action));
serviceMap.set(PredictionActionType.GET_ALL_PREDICTION, () => getAllRules());
serviceMap.set(PredictionActionType.SAVE_ASTRO_RULE, (action) => saveAstroRule(action.data));



serviceMap.set(KattamActionType.GET_TRANSIT_DATES, (action) => getTransitDates(action.planets));
serviceMap.set(KattamActionType.GET_PAAVA_VIVARAM, (action) => getPaavaVivaram(action.planets));
serviceMap.set(KattamActionType.RETRIEVE_PAAVA_VIVARAM, () => retrievePaavaVivaram());
serviceMap.set(KattamActionType.RETRIEVE_PLANET_STRENGTH, (action) => retrievePlanetStrength(action.userId));
serviceMap.set(KattamActionType.RETRIEVE_HOUSE_STRENGTH, (action) => retrieveHouseStrength(action.userId));




serviceMap.set(UserActionConstants.POST_USER_JAATHAGAN, (action) => postUserJathgan(action.user));
serviceMap.set(UserActionConstants.CONTACT_US, (action) => contactUs(action.data));

serviceMap.set(DasaBhukthiActionConstants.DASA_BHUKTHI, (action) => getDasaBhukthi(action.userId));
serviceMap.set(DasaBhukthiActionConstants.GET_DASA, (action) => retrieveDasas(action.data));
serviceMap.set(DasaBhukthiActionConstants.GET_PUTTHI, (action) => retrievePutthis(action.data));
serviceMap.set(DasaBhukthiActionConstants.GET_ANDHARAM, (action) => retrieveAndharams(action.data));
serviceMap.set(DasaBhukthiActionConstants.GET_SOOTCHAMAM, (action) => retrieveSootchamams(action.data));

serviceMap.set(LoginActionType.SET_AUTHENTICATED_USER, (action) => setAuthenticatedUser(action.data));

serviceMap.set(OAuthActionType.LOGIN_WITH_GOOGLE, (action) => loginWithGoogle(action.data));

serviceMap.set(OAuthActionType.TOKEN_FOR_GOOGLE, (action) => getGoogleToken(action.data));
