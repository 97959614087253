import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import raasiNames from "../KattamConstants";
import FilterListIcon from "@material-ui/icons/FilterList";
import SpecialHouse from "./SpecialHouse";
import { injectIntl } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import { ClickAwayListener } from "@material-ui/core";
import * as _ from 'lodash';

class Raasi extends Component {
  state = {
    name: this.props.name,
    planets: ["p1", "p2", "p3"],
    isLagnam: "false",
    open: false,
  };
  styles = {
    name: {
      margin: "4px",
      color: "darkgrey",
    },
    raasi: {
      border: "1px solid green",
      margin: "0.5px",
      width: this.props.width,
      height: this.props.height,
      display: "flex",
      flexDirection: "column",
      fontSize: "small",
      justifyContent: "space-around",
    },

    planets: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
      fontSize: "small",
      margin: "4px",
      fontWeight: "bold",
      flexFlow: "wrap"
    },
  };
  getPlanets = (data) =>
    data.planets && data.planets.length > 0 ? data.planets : [];

  getPlanetShortName = (name) => name + "_SHORT";

  getHouseNumber = (houseNumber) =>
    houseNumber === 1 && houseNumber !== 0 ? 12 : houseNumber - 1;

  getStyles = (name) => {
    if (
      name === raasiNames.MESAM ||
      name === raasiNames.RISABAM ||
      name === raasiNames.VIRUCHAGAM ||
      name === raasiNames.THULAM
    ) {
    }
  };

  getSpecialHouseText = () => {
    let info = [];
    let data = this.props.data;
    if (this.props.hideSplHouse || !data.specials) {
      return;
    }
    if (data.specials.includes(SpecialHouse.MAARAKAM)) {
      info.push("M");
    }
    if (data.specials.includes(SpecialHouse.PAATHAKAM)) {
      info.push("P");
    }
    if (data.specials.includes(SpecialHouse.KENDIRAM)) {
      info.push("K");
    }
    if (data.specials.includes(SpecialHouse.TRIKONAM)) {
      info.push("T");
    }
    return info.join("/");
  };

  // getTooltip = p => p.naturalBondType ? `${this.props.intl.formatMessage({ id: p.planet })}/${this.props.intl.formatMessage({ id: p.naturalBondType })}` : "";
  getTooltip = p => p.naturalBondType ?`${this.props.intl.formatMessage({ id: p.planet })}/${this.props.intl.formatMessage({ id: p.naturalBondType })}/${this.props.intl.formatMessage({ id: p.natchathiram })}-${p.paadham} ${this.props.intl.formatMessage({ id: p.natchathiraAthipathi })} / ${_.round(p.location.longitude, 2)}`: "";


  openTooltip = (p) => {
    this.setState({[p] : true});
  }
  handleTooltipClose = (p) => this.setState({[p] : false});

  isLagnam = (raasi) =>
    raasi.specials && raasi.specials.includes(SpecialHouse.LAGNAM);

  render() {
    if (!this.props.data) {
      return;
    }
    let name =
      this.props.data && this.props.data.raasi ? this.props.data.raasi : "NA";
    let planets = this.getPlanets(this.props.data);
    let lagnam = this.isLagnam(this.props.data);
    let specialHouseText = this.getSpecialHouseText();
    return (
      <div name={name} style={this.styles.raasi}>
        {/* {isLagnam && <Lagnam size={this.styles.raasi.width} />} */}
        {!this.props.hideLagnam && lagnam && (
          <FilterListIcon
            style={{ transform: "rotate(135deg)", margin: "-8px" }}
          />
        )}
        <span style={this.styles.name}>
          {!this.props.hideHouseNo && <span>{this.props.data.houseNo} </span>}
          <FormattedMessage id={name} />
        </span>

        <span style={{ marginLeft: "3px", color: "grey", fontSize: "x-small" }}>{specialHouseText}</span>


          <div style={this.styles.planets}>
            {planets.map((p) => {
              let c = this.state[p.planet];
              return (
                <ClickAwayListener onClickAway={() => this.handleTooltipClose(p.planet)}>
                <Tooltip title={this.getTooltip(p)} PopperProps={{
                  disablePortal: true,
                }}
                  open={_.get(this.state,p.planet,false) === true} key={p.planet}>
                  <div name={p.planet} onClick={() => this.openTooltip(p.planet)}>
											<FormattedMessage id={this.getPlanetShortName(p.planet)} />
											{p.retrograde && <FormattedMessage id="RETROGRADE" />}
                  </div>
                </Tooltip>
                </ClickAwayListener>
              );
            })}
          </div>
      </div>
    );
  }
}

export default injectIntl(Raasi);
