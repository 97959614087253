import { Button, FormControl, Input, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { Component } from "react";
import Planets, {bonds} from "../common/Planets";

export default class SearchDasaPutthi extends Component {
  styles = {
    container: {
      margin: "10px",
      minWidth: "300px",
    },
  };

  state = {
    planets: [],
    planetCombination: "OR",
    lordHouseNo: "",
    house:"",
    splHouse: null,
    bondTypes: []
  };

  handleDasaChange = (event) => this.setState({ dasa: event.target.value });
  handlePutthiChange = (event) => this.setState({ putthi: event.target.value });
  
  createRequest = () => {
    let req = {
      planets: this.state.planets,
      planetCombination: this.state.planetCombination,
      lordHouseNo: this.state.lordHouseNo,
      splHouse: this.state.splHouse,
      house: this.state.house,
      bondTypes: this.state.bondTypes,
      dasa: this.state.dasa,
      putthi: this.state.putthi
    };
    return req;
  }
  search = () => {this.props.actions.searchByDasaPutthi(this.createRequest()); this.props.kattamActions.resetCurrentUser();}
  reset = () => this.setState({ bondTypes: [], house: "", splHouse: null,lordHouseNo: "" , dasa: undefined,putthi:undefined });


  render() {
    let planets = Object.values(Planets);
    return (
      <div style={{  padding:'10px', margin: "10px" ,display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", flexWrap:'wrap', border: "1px solid black" }}>
        <FormControl style={this.styles.container}>
          <InputLabel id="planet2-simple-select-label">Dasa</InputLabel>
          <Select labelId="planet2-simple-select-label" id="planet2-simple-select" onChange={this.handleDasaChange} value={this.state.dasa} input={<Input />}>
            {planets.map((p) => (
              <MenuItem value={p}>{p}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={this.styles.container}>
          <InputLabel id="planet2-simple-select-label">Putthi</InputLabel>
          <Select labelId="planet2-simple-select-label" id="planet2-simple-select" onChange={this.handlePutthiChange} value={this.state.putthi} input={<Input />}>
            {planets.map((p) => (
              <MenuItem value={p}>{p}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button color="secondary" style={{ marginLeft: "20px", marginTop: "10px", width: "100px" }} variant="outlined" onClick={this.search}>
          Search
        </Button>
        <Button color="secondary" style={{ marginLeft: "5px", marginTop: "10px", width: "100px" }} variant="outlined" onClick={this.reset}>
          RESET
        </Button>
      </div>
    );
  }
}