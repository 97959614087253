const raasiNames = {
  MESAM: "MESAM",
  RISABAM: "RISABAM",
  MITHUNAM: "MITHUNAM",
  KADAGAM: "KADAGAM",
  SIMMAM: "SIMMAM",
  KANNI: "KANNI",
  THULAM: "THULAM",
  VIRUCHAGAM: "VIRUCHAGAM",
  DHANUSU: "DHANUSU",
  MAKARAM: "MAKARAM",
  KUMBAM: "KUMBAM",
  MEENAM: "MEENAM",
};

export default raasiNames;
